import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import { forwardRef, MouseEvent, MouseEventHandler } from "react";
import { PULSE_ORB, PULSE_RING } from "../../AppTheme";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { QuestStepType } from "./quests.types";

const useStyles = makeStyles(
  (theme) => ({
    ...PULSE_ORB.keyframes,
    ...PULSE_RING.keyframes,
    root: {
      position: "absolute",
      width: 12,
      height: 12,
      "&:before": {
        content: "''",
        position: "relative",
        display: "block",
        width: "400%",
        height: "400%",
        boxSizing: "border-box",
        marginLeft: "-150%",
        marginTop: "-150%",
        borderRadius: 45,
        opacity: 0.9,
        backgroundColor: theme.colors.logo.blurple,
        ...PULSE_RING.elementStyles,
      },
      "&:after": {
        content: "''",
        position: "absolute",
        left: 0,
        top: 0,
        display: "block",
        width: "100%",
        height: "100%",
        backgroundColor: theme.colors.logo.blurple,
        borderRadius: 15,
        boxShadow: "0 0 8px rgba(255,197,80,.3)",
        ...PULSE_ORB.elementStyles,
      },
    },
    middle: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: "auto",
    },
    top: {
      top: 0,
      right: 0,
      left: 0,
      margin: "0 auto",
    },
    topLeft: {
      top: 0,
      left: 0,
    },
    right: {
      top: 0,
      right: 0,
      bottom: 0,
      margin: "auto 0",
    },
    bottom: {
      right: 0,
      bottom: 0,
      left: 0,
      margin: "0 auto",
    },
    left: {
      top: 0,
      bottom: 0,
      left: 0,
      margin: "auto 0",
    },
    noEvents: {
      pointerEvents: "none",
    },
  }),
  {
    classNamePrefix: "QuestOrb",
  }
);

export type QuestOrbJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestOrbProps = {
  classes?: Partial<ClassNameMap<QuestOrbJSSClassKey>>;
  className?: string;
  type?: QuestStepType;
  disabled?: boolean;
  placement?: "middle" | "top" | "top-left" | "right" | "bottom" | "left";
  offsetX?: number;
  offsetY?: number;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
};

export const QuestOrb = forwardRef<HTMLDivElement, QuestOrbProps>(
  ({ className, classes: extClasses, type, disabled, placement = "middle", offsetX, offsetY, onMouseEnter }, ref) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const handleMouseOver = useCallbackSafeRef((e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    });

    return (
      <div
        ref={ref}
        className={clsx(classes.root, className, {
          [classes.noEvents]: disabled || type === "action",
          [classes.middle]: placement === "middle",
          [classes.top]: placement === "top",
          [classes.topLeft]: placement === "top-left",
          [classes.right]: placement === "right",
          [classes.bottom]: placement === "bottom",
          [classes.left]: placement === "left",
        })}
        style={{
          transform: `translate(${offsetX === undefined ? 0 : offsetX}px, ${offsetY === undefined ? 0 : offsetY}px)`,
        }}
        onMouseEnter={onMouseEnter}
        onMouseOver={handleMouseOver}
      />
    );
  }
);
