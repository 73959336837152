import { useCallbackSafeRef } from "@hooks/useCallbackSafeRef";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Button, MenuProps, Popover, PopoverProps } from "@mui/material";
import { Override } from "@typeDefs/index";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import { clsx } from "clsx";
import { atom, useAtom } from "jotai";
import { ComponentType, createElement, MouseEvent, useEffect, useMemo, useState, VFC } from "react";
import moduleClasses from "./ActionMenuPopper.module.scss";

export type ActionMenuPopperCSSClassKey = keyof typeof moduleClasses;

export type ActionMenuPopperChildrenProps = { onClose?(): void };

export type ActionMenuPopperBaseProps = Override<Omit<PopoverProps, "open">, { onClose?(): void }>;

type ActionMenuPopperAtomProps<P extends ActionMenuPopperChildrenProps> = {
  component: ComponentType<P>;
  props: P;
  PopoverProps?: ActionMenuPopperBaseProps;
};

const actionMenuPopperAtom = atom<ActionMenuPopperAtomProps<ActionMenuPopperChildrenProps> | undefined>(undefined);

export type ActionMenuPopperProps = {
  classes?: SCSSClasses<ActionMenuPopperCSSClassKey>;
  className?: string;
};

export const ActionMenuPopper: VFC<ActionMenuPopperProps> = ({ className, classes: extClasses }) => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  /********************/
  /*   custom hooks   */
  /********************/

  const [actionMenuPopper] = useAtom(actionMenuPopperAtom);

  /********************/
  /*     useState     */
  /********************/

  const [open, setOpen] = useState<boolean>(false);

  /********************/
  /* useMemo & consts */
  /********************/

  const menuProps = useMemo<MenuProps>(
    () => ({
      open,
      placement: "left-start",
      classes: { paper: classes.paper },
      anchorOrigin: { vertical: "center", horizontal: "left" },
      transformOrigin: { vertical: "center", horizontal: "right" },
      ...(actionMenuPopper?.PopoverProps || {}),
    }),
    [open, actionMenuPopper, classes.paper]
  );

  /********************/
  /*    useCallback   */
  /********************/

  const handleClose = useCallbackSafeRef(() => {
    actionMenuPopper?.PopoverProps?.onClose?.();
    setOpen(false);
  });

  const stopPropagation = useCallbackSafeRef((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  });

  /********************/
  /*    useEffects    */
  /********************/

  useEffect(() => {
    if (actionMenuPopper) setOpen(true);
  }, [actionMenuPopper]);

  /********************/
  /*       JSX        */
  /********************/

  return (
    <Popover className={clsx(classes.root, className)} {...menuProps} onClose={handleClose} onClick={stopPropagation}>
      <div className={classes.content}>
        <Button onClick={handleClose} className={classes.closeBtn} color="primary" variant="text">
          <CloseRoundedIcon fontSize="small" />
        </Button>
        {actionMenuPopper?.component
          ? createElement(actionMenuPopper.component, { onClose: handleClose, ...actionMenuPopper.props })
          : null}
      </div>
    </Popover>
  );
};

export const useActionMenuPopper = () => {
  const [, setActionMenuPopper] = useAtom(actionMenuPopperAtom);
  return useCallbackSafeRef(
    <P extends ActionMenuPopperChildrenProps>(
      component: ComponentType<P>,
      props: P,
      PopoverProps?: ActionMenuPopperBaseProps
    ) => setActionMenuPopper({ component, props, PopoverProps })
  );
};
