import { useSetAtom } from "jotai";
import { reclaim } from "../reclaim-api";
import { IntegrationsEnabledMap } from "../reclaim-api/Integrations.types";
import { LoadingErrorData } from "../reclaim-api/types";
import { makeLoadableHook } from "../utils/react";
import { useCallbackSafeRef } from "./useCallbackSafeRef";

export type UseIntegrationsEnabledMapReturnType = LoadingErrorData<
  Readonly<IntegrationsEnabledMap>,
  "integrationsEnabledMap"
> & {
  reloadIntegrationsEnabledMap: () => Promise<void>;
};

const loadData = async () => await reclaim.integrations.getEnabledMap();

const { useHook, dataAtom } = makeLoadableHook(loadData, {
  dataKey: "integrationsEnabledMap",
  errorizer: "Failed to get integrations enabled map",
});

export const useIntegrationsEnabledMap = (): UseIntegrationsEnabledMapReturnType => {
  const hookData = useHook();
  const setData = useSetAtom(dataAtom);

  const reloadIntegrationsEnabledMap = useCallbackSafeRef(async () => {
    const newData = await loadData();
    await setData(newData);
  });

  return { ...hookData, reloadIntegrationsEnabledMap };
};
