import { Button, ButtonProps, lighten } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ReactNode } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: "1px solid",
      borderColor: lighten(theme.colors.logo.darkness, 0.85),
      borderRadius: theme.shape.borderRadius * 5,
      whiteSpace: "nowrap",
      alignSelf: "center",
      display: "flex",
    },
    button: {
      ...theme.typography.body2,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.2,
      margin: theme.spacing(0.375),
      minWidth: 0,
      color: theme.colors.black,
      padding: theme.spacing(0.5, 1),
      width: "auto",
      flex: "1 1 auto",
    },
    buttonSmall: {
      padding: theme.spacing(0.25, 0.5),
      fontSize: 12,
      margin: theme.spacing(0.25),
    },
    selected: {
      backgroundColor: theme.colors.logo.darkness100,
      color: theme.colors.black,
      cursor: "default",
      "&:hover": {
        backgroundColor: theme.colors.logo.darkness100,
        color: theme.colors.black,
      },
    },
  }),
  {
    classNamePrefix: "MiniRadioGroup",
  }
);

export type MiniRadioGroupJSSClassKey = keyof ReturnType<typeof useStyles>;
export type MiniRadioGroupChangeHandler<K extends string | number> = (key: K) => unknown;

export type MiniRadioGroupProps<K extends string | number> = {
  classes?: Partial<ClassNameMap<MiniRadioGroupJSSClassKey>>;
  className?: string;
  tabs: (K | { key: K; label?: ReactNode; disabled?: boolean })[];
  selected: K | undefined;
  disabled?: boolean;
  onChange: MiniRadioGroupChangeHandler<K>;
  ButtonProps?: ButtonProps;
  small?: boolean;
};

export const MiniRadioGroup = <K extends string | number>({
  className,
  classes: extClasses,
  tabs,
  selected,
  disabled,
  onChange,
  ButtonProps,
  small,
}: MiniRadioGroupProps<K>) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div className={clsx(classes.root, className)}>
      {tabs.map((tab) => {
        const {
          key,
          label,
          disabled: tabDisabled,
        } = typeof tab === "object" ? tab : { key: tab, label: tab, disabled: false };

        return (
          <Button
            key={key}
            onClick={() => onChange(key as K)}
            disabled={disabled || tabDisabled}
            className={clsx(classes.button, {
              [classes.selected]: key === selected,
              [classes.buttonSmall]: small,
            })}
            {...ButtonProps}
          >
            {label || key}
          </Button>
        );
      })}
    </div>
  );
};
