import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import { CloseRounded } from "@mui/icons-material";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import { forwardRef } from "react";
import { useCallbackSafeRef } from "../../../hooks/useCallbackSafeRef";
import { Modal, ModalProps } from "../../modal/Modal";
import { OverageOMatic } from "../OverageOMatic";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: 10000,
    },
    paper: {
      padding: theme.spacing(5, 2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(5),
      },
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(),
      right: theme.spacing(),
    },
  }),
  {
    classNamePrefix: "OverageModal",
  }
);

export class OverageModalError extends Error {}

export type OverageModalJSSClassKey = keyof ReturnType<typeof useStyles>;

export type OverageModalProps = Omit<ModalProps, "classes"> & {
  classes?: Partial<ClassNameMap<OverageModalJSSClassKey>>;
  ModalClasses?: ModalProps["classes"];
  closeModal(): void;
};

export const OverageModal = forwardRef<HTMLDivElement, OverageModalProps>(
  ({ className, classes: extClasses, ModalClasses, closeModal, ...rest }, ref) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));

    /********************/
    /*   custom hooks   */
    /********************/

    /********************/
    /*     useState     */
    /********************/

    /********************/
    /* useMemo & consts */
    /********************/

    /********************/
    /*    useCallback   */
    /********************/

    /********************/
    /*       JSX        */
    /********************/

    return (
      <Modal
        className={clsx(className, classes.root)}
        classes={{ paper: classes.paper }}
        ref={ref}
        fullWidth
        fullScreen={small}
        maxWidth="sm"
        onClose={closeModal}
        {...rest}
      >
        <IconButton className={classes.closeBtn} onClick={closeModal} size="large">
          <CloseRounded />
        </IconButton>
        <OverageOMatic onComplete={closeModal} />
      </Modal>
    );
  }
);

export const useOverageModal = () => {
  const { showModal } = useModal();
  return useCallbackSafeRef((props?: Partial<Omit<OverageModalProps, "closeModal" | "ref">>) => {
    const closeModal = showModal(OverageModal, { closeModal: () => closeModal(), ...props }).hide;
  });
};
