import { Typography } from "@mui/material";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import { parseDate } from "chrono-node";
import { useMemo, VFC } from "react";
import { useForm } from "react-hook-form";
import { useCallbackSafeRef } from "src/hooks/useCallbackSafeRef";
import { DateTimeFormatHandler, useDateTimeFormatter } from "../../hooks/useDateTimeFormatter";
import { useMicroShortcuts } from "../../hooks/useMicroShortcuts";
import { durationStrDecimal, getLargestContainedUnit, getLatestMinuteInterval, isDateToday } from "../../utils/dates";
import { parseTime } from "../../utils/time";
import { CircularProgressWithBackground } from "../CircularProgressWithBackground";
import { AsyncButton } from "./AsyncButton";
import moduleClasses from "./LogWorkForm.module.scss";
import { DateControl, getDateControlDisplayDate } from "./rhf/DateControl";
import { DurationControl, durationControlParseDuration } from "./rhf/DurationControl";
import { RhfForm } from "./rhf/RhfForm";
import { RhfRadioBox } from "./rhf/RhfRadioBox";
import { RhfRadioBoxGroupControl } from "./rhf/RhfRadioBoxGroupControl";
import { TimeControl, TimeControlValidators } from "./rhf/TimeControl";

export type LogWorkPopperValues = {
  endTime: Date; // Represents the end time of the event as a date
  duration: number; // minutes
};

type FormValuesFieldToggle = "duration" | "startEnd";

type FormValues = {
  duration: string;
  date: string;
  start: string;
  end: string;
  fieldToggle: "duration" | "startEnd";
};

const fieldToggleKeys: { [K in FormValuesFieldToggle]: K } = {
  duration: "duration",
  startEnd: "startEnd",
};

const defaultValues = (): LogWorkPopperValues => ({
  endTime: new Date(),
  duration: 60,
});

const dataToFormValues = ({ endTime, duration }: LogWorkPopperValues, format: DateTimeFormatHandler): FormValues => {
  const lastInterval = getLatestMinuteInterval(endTime, 15);

  return {
    duration: "1hr",
    date: isDateToday(lastInterval) ? "" : getDateControlDisplayDate(lastInterval, format, undefined, true),
    start: format(new Date(lastInterval.getTime() - duration * 60000), "TIME_DISPLAY_FORMAT"),
    end: format(lastInterval, "TIME_DISPLAY_FORMAT"),
    fieldToggle: "duration",
  };
};

const formValuesToData = (values: FormValues): LogWorkPopperValues => {
  const endTime = values.date === "" ? parseDate("today") : parseDate(values.date);

  const start = parseTime(values.start) as Date;
  const end = parseTime(values.end) as Date;
  const duration =
    values.fieldToggle === "startEnd"
      ? (end.getTime() - start.getTime()) / 1000 / 60
      : durationControlParseDuration(values.duration) || 0;

  // Set the date to the configured end time.
  endTime.setHours(end.getHours(), end.getMinutes(), 0, 0);

  return { endTime, duration };
};

export type LogWorkFormCSSClassKey = keyof typeof moduleClasses;

export type LogWorkFormProps = {
  classes?: SCSSClasses<LogWorkFormCSSClassKey>;
  className?: string;
  value?: LogWorkPopperValues;
  submitLabel?: string;
  secondsDone?: number;
  secondsTotal?: number;
  onCancel: () => void;
  onSubmit: (endTime: Date, duration: number) => void | Promise<void>;
};

export const LogWorkForm: VFC<LogWorkFormProps> = ({
  className,
  classes: extClasses,
  value,
  submitLabel = "Log work",
  onCancel,
  onSubmit,
  secondsDone,
  secondsTotal,
}) => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  /********************/
  /*   custom hooks   */
  /********************/

  const { format } = useDateTimeFormatter();

  const form = useForm<FormValues>({
    defaultValues: {
      ...(!!value ? dataToFormValues(value, format) : dataToFormValues(defaultValues(), format)),
    },
    mode: "onBlur",
  });

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  const watch = form.watch();

  const inputProps = useMemo(
    () => ({
      inputProps: {
        className: classes.input,
        classes: { input: classes.input__input },
      },
    }),
    [classes.input, classes.input__input]
  );

  const hasProgress = typeof secondsDone === "number" && typeof secondsTotal === "number";

  const loggedLabel = useMemo(() => {
    if (!hasProgress) return "0";
    return durationStrDecimal(secondsDone, {
      fallbackUnit: getLargestContainedUnit(secondsTotal),
      noDaysOrWeeks: true,
    });
  }, [hasProgress, secondsDone, secondsTotal]);
  const progress = (secondsDone || 0) / (secondsTotal || 1);

  /********************/
  /*    useCallback   */
  /********************/

  const triggerEnd = useCallbackSafeRef(() => form.trigger("end"));
  const triggerStart = useCallbackSafeRef(() => form.trigger("start"));

  const hasError = useCallbackSafeRef(() => {
    return Object.keys(form.formState.errors).length !== 0;
  });

  const handleSubmit = useCallbackSafeRef(async () => {
    await form.trigger();

    if (!hasError()) {
      const { endTime, duration } = formValuesToData(form.getValues());
      await onSubmit(endTime, duration);
    }
  });

  const validateEndTime = useCallbackSafeRef((v: string) => {
    const endTime = formValuesToData({ ...form.getValues(), end: v }).endTime;
    const now = new Date();

    return endTime.getTime() > now.getTime() ? "Must be in the past" : true;
  });

  useMicroShortcuts(handleSubmit, onCancel);

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div className={classes.root}>
      <CircularProgressWithBackground className={classes.progress} size={72} fraction={progress}>
        <Typography className={classes.focusProgressLabel} variant="h5">
          {loggedLabel.replace(" ", "")}
        </Typography>
        <Typography className={classes.focusProgressSubLabel} variant="h6">
          Done
        </Typography>
      </CircularProgressWithBackground>
      <RhfForm form={form} onSubmit={handleSubmit}>
        <RhfRadioBoxGroupControl name="fieldToggle" orientation="vertical" defaultValue={fieldToggleKeys.duration}>
          <RhfRadioBox styleless value={fieldToggleKeys.duration}>
            Duration
          </RhfRadioBox>
          {watch.fieldToggle === "duration" && (
            <div className={classes.flexFields}>
              <DurationControl
                className={classes.durationField}
                name="duration"
                aria-label="Duration"
                size="small"
                variant="outlined"
                rules={{
                  validate: {
                    min: (v) => {
                      const parsed = durationControlParseDuration(v);
                      return !!parsed && parsed < 15 ? "Minimum duration is 15 min" : true;
                    },
                  },
                }}
              />
            </div>
          )}
          <RhfRadioBox styleless value={fieldToggleKeys.startEnd}>
            Specific date & time
          </RhfRadioBox>
          {watch.fieldToggle === "startEnd" && (
            <>
              <div className={classes.flexFields}>
                <Typography variant="body1" className={classes.flexFields__label}>
                  Date
                </Typography>
                <DateControl
                  name="date"
                  className={classes.dateControl}
                  aria-label="Date"
                  size="small"
                  variant="outlined"
                  disableFuture={true}
                  disablePast={false}
                  inlinePicker
                  InputProps={inputProps}
                  placeholder="Today"
                  onBlur={triggerEnd}
                  fullWidth
                  dayMode
                />
              </div>
              <div className={classes.flexFields}>
                <Typography variant="body1" className={classes.flexFields__label}>
                  Time
                </Typography>
                <TimeControl
                  className={classes.timeControl}
                  name="start"
                  aria-label="Start time"
                  size="small"
                  variant="outlined"
                  InputProps={inputProps}
                  required="required"
                  rules={{
                    validate: {
                      beforeEnd: (v: string) =>
                        TimeControlValidators.beforeTime(v, form.getValues().end, "Must be less than end"),
                    },
                  }}
                />
                <Typography variant="body1" className={classes.flexFields__autoLabel}>
                  to
                </Typography>
                <TimeControl
                  className={classes.timeControl}
                  name="end"
                  aria-label="End time"
                  size="small"
                  variant="outlined"
                  required="required"
                  rules={{
                    validate: {
                      beforeNow: validateEndTime,
                    },
                  }}
                  onBlur={triggerStart}
                  InputProps={inputProps}
                />
              </div>
            </>
          )}
        </RhfRadioBoxGroupControl>
        <AsyncButton
          onClick={handleSubmit}
          className={classes.submitBtn}
          variant="contained"
          color="primary"
          size="small"
        >
          {submitLabel}
        </AsyncButton>
      </RhfForm>
    </div>
  );
};
