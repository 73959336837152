import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { createElement, ReactElement, useRef, useState, VFC } from "react";
import { useCallbackSafeRef } from "./useCallbackSafeRef";
import { useIsMountedRef } from "./useIsMountedRef";
import { useWaitFor } from "./useWaitFor";

/**
 * State of promises
 */
export type UsePromiseStateState = "idle" | "running" | "complete" | "error";

/**
 * Options for `usePromiseState`
 */
export type UsePromiseStateOptions = {
  /**
   * If `true`, the error state will not clear until another promise is adeed.
   */
  persistErrors?: boolean;
  /**
   * The number of milliseconds to wait after beginning a `complete` or `error` state before clearing to `idle`
   */
  resetMs?: number;
};

export type UsePromiseStateAddPromise = (promise: unknown) => void;

const DEFAULT_RESET_MS = 2000;

/**
 * Hook which provides a function which, when
 * passed a promise, indicates when the promise
 * is running, and (for a short while) when it
 * has errored or completed.
 *
 * If a promise is added before the previous one
 * completes, the state will remain in `running`
 * until all promises complete.
 * @param options options
 * @returns A tuple with the current proimse state, and a method to add more promises.
 */
export const usePromiseState = (
  options: UsePromiseStateOptions = {}
): [state: UsePromiseStateState, addPromise: UsePromiseStateAddPromise] => {
  const { resetMs = DEFAULT_RESET_MS, persistErrors } = options;

  const [state, setState] = useState<UsePromiseStateState>("idle");
  const promiseStackRef = useRef<unknown>();
  const isMountedRef = useIsMountedRef();

  const waitForReset = useWaitFor(resetMs);

  const addPromise = useCallbackSafeRef<UsePromiseStateAddPromise>(async (promise) => {
    if (promiseStackRef.current) promiseStackRef.current = Promise.all([promiseStackRef.current, promise]);
    else promiseStackRef.current = promise;

    const currentPromise = promiseStackRef.current;
    const isInvalid = () => !isMountedRef.current || !(currentPromise === promiseStackRef.current);
    const beginReset = async () => {
      await waitForReset();
      if (isInvalid()) return;
      setState("idle");
    };

    setState("running");

    try {
      await currentPromise;

      if (isInvalid()) return;
      setState("complete");
      await beginReset();
    } catch {
      if (isInvalid()) return;
      setState("error");
      if (!persistErrors) await beginReset();
    }

    promiseStackRef.current = undefined;
  });

  return [state, addPromise];
};

export type UsePromiseStateIconVariant = "onDark" | "onLight";

const ICON_MAP: { [S in UsePromiseStateState]?: { [V in UsePromiseStateIconVariant]: VFC<{ className: string }> } } = {
  error: {
    onDark: ErrorRoundedIcon,
    onLight: ErrorOutlineRoundedIcon,
  },
  complete: {
    onDark: CheckCircleRoundedIcon,
    onLight: CheckCircleOutlineRoundedIcon,
  },
};

export const getIconForPromiseState = (
  state: UsePromiseStateState,
  variant: UsePromiseStateIconVariant = "onDark"
): ReactElement<{ className: string }> | undefined =>
  ICON_MAP[state] ? createElement(ICON_MAP[state]![variant]) : undefined;
