import { Skeleton } from "@mui/material";
import { MouseEventHandler, VFC } from "react";
import { useTeamState } from "../../hooks/atoms/useTeam";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { useSubscriptionLogic } from "../../hooks/useSubscriptionLogic";
import { MembershipRole } from "../../reclaim-api/team/Team";
import { ButtonWithLinkVariant, ButtonWithLinkVariantProps } from "../ButtonWithLinkVariant";
import { usePurchaseModal } from "./modals/PurchaseModal";

export type SeePlansButtonProps = Omit<ButtonWithLinkVariantProps, ""> & {};

export const SeePlansButton: VFC<SeePlansButtonProps> = ({ children, onClick, ...rest }) => {
  /********************/
  /*   custom hooks   */
  /********************/

  const { team, userMembership } = useTeamState();
  const { hasActiveSubscription } = useSubscriptionLogic();
  const openPurchaseModal = usePurchaseModal();

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  const isAdmin = userMembership.membershipRole === MembershipRole.Admin;

  /********************/
  /*    useCallback   */
  /********************/

  const handleClick = useCallbackSafeRef<MouseEventHandler<HTMLButtonElement>>(async (e) => {
    onClick?.(e);
    openPurchaseModal();
  });

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <ButtonWithLinkVariant variant="contained" color="primary" size="small" {...rest} onClick={handleClick}>
      {(() => {
        if (children) return children;
        if (!team) return <Skeleton variant="rectangular" width={50} />;
        if (isAdmin) {
          if (hasActiveSubscription) return "Change plan";
          else return "Purchase";
        } else return "See plans";
      })()}
    </ButtonWithLinkVariant>
  );
};
