import { Button, Collapse, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import { Alert, AlertTitle, Skeleton } from "@mui/material";
import clsx from "clsx";
import { isFuture, isToday } from "date-fns";
import { useEffect, useState, VFC } from "react";
import { useTeamState } from "../../../hooks/atoms/useTeam";
import { useCallbackSafeRef } from "../../../hooks/useCallbackSafeRef";
import { useDateTimeFormatter } from "../../../hooks/useDateTimeFormatter";
import { useSubscriptionLogic } from "../../../hooks/useSubscriptionLogic";
import { reclaim } from "../../../reclaim-api";
import { EDITION_META, ENTITLEMENT_META } from "../../../reclaim-api/team/Team.consts";
import {
  EntitlementTable,
  SubscriptionChange,
  SubscriptionChangeResult,
  SupportedCurrency,
} from "../../../reclaim-api/team/Team.types";
import { ProductUsageReport } from "../../../reclaim-api/Users.types";
import { formatDollarAmount, FormatDollarAmountOptions } from "../../../utils/i18n";
import { pluralize } from "../../../utils/strings";
import { AsyncButton } from "../../forms/AsyncButton";
import { Link } from "../../Link";
import { ModalCloseButton } from "../../modal/ModalCloseButton";
import { QuestStepWrapper } from "../../quests/QuestStepWrapper";
import { ReasonTip, ReasonTipReason } from "../../ReasonTip";
import { useConfirmSubChangeModal } from "../modals/ConfirmSubChangeModal";
import { DOWNGRADE_TO_NON_PAID_MESSAGE_IN_OVERAGE } from "./PurchaseOMatic.consts";
import { DowngradeIssues, PurchaseOMaticPlanData } from "./PurchaseOMatic.types";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
      bottom: 0,
      left: 0,
      right: 0,
      overflow: "hidden",
      paddingTop: 25,
      pointerEvents: "none",
    },
    preview: {
      position: "relative",
      borderRadius: theme.shape.borderRadius * 3,
      boxShadow: "0px 0px 25px 2px rgba(0,0,0,0.2);",
      background: "white",
      marginBottom: 0,
      pointerEvents: "all",
      transition: theme.transitions.create(["transform", "margin-bottom"]),
      zIndex: 1,
    },
    previewOff: {
      transform: "translateY(100%)",
    },
    previewBox: {
      padding: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    invoiceContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    downgrade: {
      maxWidth: "36rem",
      textAlign: "center",
      gap: theme.spacing(3),
    },
    containerButtonsInvoice: {
      display: "flex",
      gap: theme.spacing(),
    },
    invoiceTable: {
      backgroundColor: theme.colors.borderGrey,
      borderRadius: theme.shape.borderRadius * 3,
      margin: theme.spacing(0, "auto", 2),
      maxWidth: "fit-content",
      padding: theme.spacing(2, 0.5, 2, 2),
      display: "flex",
      flexDirection: "column",
    },
    summary: {
      marginBottom: theme.spacing(2),
      maxWidth: 600,
      textAlign: "center",
    },
    alert: {
      margin: theme.spacing(1, 0),
    },
    table: {
      "& th": {
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.spacing(0, 1.5, 1.5, 0),
        textAlign: "left",
      },
      "& td": {
        padding: theme.spacing(0, 1.5, 1.5, 0),
      },
      "& > tfoot > tr > td": {
        fontWeight: theme.typography.fontWeightBold,
        paddingBottom: 0,
        textAlign: "right",
      },
    },
    money: {
      textAlign: "right",
    },
    freqPlan: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    chargeLine: {
      display: "flex",
      justifyContent: "end",
      padding: theme.spacing(0, 1.5),
      gap: theme.spacing(0, 1.5),
      ...theme.typography.body2,
    },
    chargeLineSmall: {
      fontSize: "0.8em",

      "&::before": {
        content: "'('",
        marginRight: theme.spacing(-1.5),
      },
      "&::after": {
        content: "')'",
        marginLeft: theme.spacing(-1.5),
      },
    },
    chargeLineGreen: {
      color: theme.palette.success.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    boldText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    skele: {
      margin: theme.spacing(2, 0),
    },
    promoCodeText: {
      margin: theme.spacing(1, 0),
    },
    promoCodeFields: {
      margin: theme.spacing(1, 0, 1, 3),
      display: "flex",
      alignItems: "center",
      gap: 10,
      paddingBottom: 16,
      justifyContent: "flex-start",
    },
  }),
  {
    classNamePrefix: "PurchaseCheckout",
  }
);

export type PurchaseCheckoutJSSClassKey = keyof ReturnType<typeof useStyles>;

export type PurchaseCheckoutProps = {
  currency: SupportedCurrency;
  classes?: Partial<ClassNameMap<PurchaseCheckoutJSSClassKey>>;
  className?: string;
  plan: PurchaseOMaticPlanData;
  entitlementTable?: EntitlementTable;
  usageData?: ProductUsageReport;
  loading?: boolean;
  disableContinueReasons?: ReasonTipReason[];
  onClose?: () => void;
};

export const PurchaseCheckout: VFC<PurchaseCheckoutProps> = ({
  className,
  classes: extClasses,
  plan,
  entitlementTable,
  usageData,
  loading,
  disableContinueReasons,
  currency,
  onClose,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  const { team } = useTeamState();
  const { userCurrentEdition, userIsOnTrial, hasActiveSubscription, subscriptionFrequency } = useSubscriptionLogic();
  const { format } = useDateTimeFormatter();
  const openConfirmSubChangeModal = useConfirmSubChangeModal({});

  /********************/
  /*     useState     */
  /********************/

  const [submitting, setSubmitting] = useState(false);
  const [promoCode, setPromoCode] = useState<string>("");
  const [promoCodeInputValue, setPromoCodeInputValue] = useState<string>("");
  const [showPromoCode, setShowPromoCode] = useState(false);

  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceError, setInvoiceError] = useState<Error | undefined>();
  const [invoice, setInvoice] = useState<SubscriptionChangeResult<true> | undefined>(undefined);

  /********************/
  /* useMemo & consts */
  /********************/
  loading = loading || invoiceLoading;

  /********************/
  /*    useCallback   */
  /********************/

  const formatPennies = useCallbackSafeRef((pennies: number, options?: FormatDollarAmountOptions) =>
    formatDollarAmount(pennies / 100, { currency, ...options })
  );

  const fetchInvoicePreview = useCallbackSafeRef(async () => {
    const newPlan = { ...plan, promoCode: promoCode || undefined };
    if (newPlan.edition && newPlan.edition !== "LITE" && newPlan.frequency && newPlan.seats && hasActiveSubscription) {
      setInvoiceLoading(true);

      try {
        const preview = await reclaim.team.previewSubscriptionChange(newPlan as SubscriptionChange);
        setInvoiceError(undefined);
        setInvoice(preview);
      } catch (err) {
        setInvoiceError(err);
        setInvoice(undefined);
      } finally {
        setInvoiceLoading(false);
      }
    } else {
      setInvoice(undefined);
    }
  });

  const getIssueWithSelectedPlan = useCallbackSafeRef((): DowngradeIssues | undefined => {
    const selectedEdition = plan?.edition;
    if (!plan || !selectedEdition || !entitlementTable) return undefined;

    if (userIsOnTrial && EDITION_META[selectedEdition].isNonPaid) {
      return "YouWillEndTrial";
    }

    if (hasActiveSubscription && EDITION_META[selectedEdition].isNonPaid) {
      const subEnding = new Date(team.pricingSummary.subscriptionEnd || "");
      if (!isToday(subEnding) && isFuture(subEnding)) {
        return "YouCanLoseFeaturesAfterTimeEnd";
      }
    }

    const willOverage: string[] = [];
    Object.keys(usageData?.terminalActuals || {}).map((entitlement) => {
      const entData = usageData?.terminalActuals[entitlement];
      if (entData) {
        if (
          ENTITLEMENT_META[entitlement].comparator(
            entData.actualValue as never,
            entitlementTable[selectedEdition][entitlement].value as never
          ) > 0
        ) {
          willOverage.push(entitlement);
        }
      }
    });

    if (willOverage.length > 0) {
      return "YouWillBeOnSoftOverage";
    }

    return undefined;
  });

  const proceedWithSub = useCallbackSafeRef(async (plan: SubscriptionChange, cancelReason?: string) => {
    if (plan.edition === "LITE") {
      // has selected LITE
      if (userIsOnTrial && !hasActiveSubscription) {
        await reclaim.team.endTrial();
      } else {
        await reclaim.team.cancelSubscription(cancelReason);
      }
      window.location.reload();
    } else {
      //has selected anything else
      if (hasActiveSubscription) {
        // only call change w/ active subscription
        await reclaim.team.subscriptionChange({ ...plan, promoCode: promoCode || undefined });
        window.location.reload();
      } else {
        await reclaim.team.startSubscription(plan, { currency });
      }
    }
  });

  const handleDoSub = useCallbackSafeRef(async () => {
    try {
      setSubmitting(true);
      if (!plan?.edition) throw new Error("Problem while starting/changing sub: no plan");

      const { frequency, seats, edition } = plan;

      if (!edition) throw new Error("Problem while starting/changing sub: no edition");
      if (!team) throw new Error("Problem while starting/changing sub: team not ready");
      if (!userCurrentEdition) throw new Error("User edition was not available");

      const issue = getIssueWithSelectedPlan();

      if (issue) {
        openConfirmSubChangeModal({
          onConfirm: (cancelReason) =>
            proceedWithSub({ edition, frequency, seats, promoCode: promoCode || undefined }, cancelReason),
          newEdition: plan.edition,
          downgradeIssue: issue,
        });
      } else {
        await proceedWithSub({ edition, frequency, seats, promoCode: promoCode || undefined });
      }
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  });

  /********************/
  /*    useEffects    */
  /********************/

  useEffect(() => {
    void fetchInvoicePreview();
  }, [fetchInvoicePreview, plan, promoCode]);

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div className={clsx(classes.root, className)}>
      <div
        className={clsx(classes.preview, {
          [classes.previewOff]: !plan?.edition,
        })}
      >
        <ModalCloseButton onClose={() => onClose?.()} />
        <div className={classes.previewBox}>
          {(() => {
            if (!plan || !team || !plan.edition) return;

            if (EDITION_META[plan.edition].isNonPaid) {
              return (
                <div className={clsx(classes.invoiceContainer, classes.downgrade)}>
                  {(() => {
                    const issues = getIssueWithSelectedPlan();
                    const buttonText = EDITION_META[plan.edition].isNonPaid
                      ? `Switch to ${EDITION_META[plan.edition].label} & cancel plan`
                      : `Switch to ${EDITION_META[plan.edition].label}`;
                    return (
                      <>
                        {issues === "YouWillBeOnSoftOverage" && (
                          <Typography>{DOWNGRADE_TO_NON_PAID_MESSAGE_IN_OVERAGE}</Typography>
                        )}
                        <AsyncButton disabled={submitting} color="primary" variant="contained" onClick={handleDoSub}>
                          {userIsOnTrial ? "End Trial" : buttonText}
                        </AsyncButton>
                      </>
                    );
                  })()}
                </div>
              );
            }

            if (!hasActiveSubscription) {
              return (
                <QuestStepWrapper
                  config={{ group: "INVITE_TEAM", quest: "SELECT_PLAN", step: "CLICK_FINISH_ON_STRIPE" }}
                >
                  <AsyncButton disabled={submitting} color="primary" variant="contained" onClick={handleDoSub}>
                    Continue on Stripe
                  </AsyncButton>
                </QuestStepWrapper>
              );
            }

            // Has an active subscription && has selected a paid plan.
            return (
              <div className={classes.invoiceContainer}>
                <Typography variant="h4">Here's what comes next</Typography>
                {(() => {
                  if (invoiceError)
                    return (
                      <Alert className={classes.alert} severity="warning">
                        Looks like we're having trouble gathering your purchase details; you can still proceed.
                      </Alert>
                    );
                  else if (!!invoice && !!plan?.edition && !!userCurrentEdition && !loading) {
                    let nextBillingDate =
                      invoice.upcomingInvoice?.lines[invoice.upcomingInvoice.lines.length - 1]?.periodEnd;
                    nextBillingDate = !!nextBillingDate
                      ? format(new Date(nextBillingDate), "DATE_CONTROL_FORMAT_DAY_MODE")
                      : undefined;

                    const previousEdition = userCurrentEdition;
                    const newEdition = plan.edition;
                    const previousFrequency = subscriptionFrequency === "MONTH" ? "monthly" : "yearly";
                    const newFrequency = plan.frequency === "MONTH" ? "monthly" : "yearly";

                    const changingQuantityOnly = previousEdition === newEdition && previousFrequency === newFrequency;

                    return (
                      <>
                        {changingQuantityOnly ? (
                          <Typography className={classes.summary}>
                            We'll update your{" "}
                            <span className={classes.freqPlan}>
                              {newFrequency} {EDITION_META[newEdition].label}
                            </span>{" "}
                            plan to {plan.seats} {pluralize(plan.seats, "seat")}.
                          </Typography>
                        ) : (
                          <Typography className={classes.summary}>
                            We'll update your{" "}
                            <span className={classes.freqPlan}>
                              {previousEdition !== "LITE" ? previousFrequency + " " : ""}
                              {EDITION_META[previousEdition].label}
                            </span>{" "}
                            plan to a{" "}
                            <span className={classes.freqPlan}>
                              {newFrequency} {EDITION_META[newEdition].label}
                            </span>{" "}
                            plan.
                          </Typography>
                        )}
                        {(() => {
                          if (!invoice.upcomingInvoice) return;

                          const {
                            lines: lineItems,
                            total,
                            totalDiscount,
                            totalExcludingTax,
                            subtotalExcludingTax,
                          } = invoice.upcomingInvoice;
                          const nextAmountDue = lineItems[lineItems.length - 1]?.amount || 0;
                          const tax = total - totalExcludingTax;

                          return (
                            <div className={classes.invoiceTable}>
                              <table className={classes.table}>
                                <thead>
                                  <tr>
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {lineItems.map(({ description, quantity, amount }, i) => (
                                    <tr key={i}>
                                      <td>{description}</td>
                                      <td>{quantity}</td>
                                      <td className={classes.money}>{formatPennies(amount)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div className={classes.chargeLine}>
                                <span>Sub-total:</span>
                                <span>{formatPennies(subtotalExcludingTax)}</span>
                              </div>
                              {!!tax && (
                                <div className={classes.chargeLine}>
                                  <span>Tax:</span>
                                  <span>{formatPennies(tax)}</span>
                                </div>
                              )}
                              {!!totalDiscount && (
                                <div className={clsx(classes.chargeLine, classes.chargeLineGreen)}>
                                  <span>Discount:</span>
                                  <span>{formatPennies(totalDiscount)}</span>
                                </div>
                              )}
                              <div className={clsx(classes.chargeLine, classes.boldText)}>
                                <span>Due now:</span>
                                <span>{formatPennies(total || 0)}</span>
                              </div>
                              <div className={clsx(classes.chargeLine, classes.chargeLineSmall)}>
                                {formatPennies(nextAmountDue)} due {nextBillingDate}
                              </div>
                            </div>
                          );
                        })()}
                      </>
                    );
                  } else {
                    return (
                      <div className={classes.skele}>
                        <Skeleton variant="rectangular" width={500} height={150} />
                      </div>
                    );
                  }
                })()}
                <Collapse in={(invoice?.upcomingInvoice?.total || 0) < 0}>
                  <Alert className={classes.alert} color="success">
                    <AlertTitle>You're getting credit!</AlertTitle>
                    {
                      // "--" will never be visible while the
                      // Collapse is open, this is just to keep
                      // `formatPennies` happy
                      invoice?.upcomingInvoice?.total ? formatPennies(-invoice.upcomingInvoice.total) : "--"
                    }{" "}
                    will be applied to your account as credit for future invoices.
                  </Alert>
                </Collapse>
                {!showPromoCode ? (
                  <Link className={classes.promoCodeText} disabled={!invoice} onClick={() => setShowPromoCode(true)}>
                    Apply promo code
                  </Link>
                ) : (
                  <div className={classes.promoCodeFields}>
                    <span>Your promo code:</span>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={promoCodeInputValue}
                      onChange={(e) => setPromoCodeInputValue(e.target.value)}
                    />
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={promoCode === promoCodeInputValue}
                      onClick={() => {
                        setPromoCode(promoCodeInputValue);
                      }}
                    >
                      Apply
                    </Button>
                    <Button
                      onClick={() => {
                        setShowPromoCode(false);
                      }}
                      variant="outlined"
                      size="small"
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                <div className={classes.containerButtonsInvoice}>
                  <ReasonTip
                    reasons={[
                      {
                        test: submitting,
                        message: "Purchasing your plan...",
                      },
                      {
                        test: loading || (!invoice && !invoiceError),
                        message: "Loading...",
                      },
                      ...(disableContinueReasons || []),
                    ]}
                  >
                    {(disabled) => (
                      <AsyncButton disabled={disabled} color="primary" variant="contained" onClick={handleDoSub}>
                        Confirm and purchase
                      </AsyncButton>
                    )}
                  </ReasonTip>
                </div>
              </div>
            );
          })()}
        </div>
      </div>
    </div>
  );
};
