import { TeamRedirectAction } from "@api/team/Team";
import { EntitlementType } from "@api/team/Team.types";
import { EditionBadgeBaseProps } from "@components/billing/EditionBadgeBase";
import { Link } from "@components/Link";
import { evaluateReason, ReasonTipReason } from "@components/ReasonTip";
import { useEntitlementBadges } from "@hooks/useEntitlementBadges";
import { useEntitlementContent } from "@hooks/useEntitlementContent";
import { useEntitlementLogic } from "@hooks/useEntitlementLogic";
import { getBillingUrl } from "@utils/router";
import { ReactElement, ReactNode, VFC } from "react";
import { EntitletipMode } from "./Entitletip.types";
import { PlanLockedMessage } from "./PlanLockedMessage";

export type EntitlementWrapperProps = {
  entitlement: EntitlementType;
  mode?: EntitletipMode;
  reasons?: ReasonTipReason[];
  children(data: {
    disabled: boolean;
    chip: ReactElement<EditionBadgeBaseProps> | undefined;
    message: ReactNode;
  }): ReactNode;
};

export const EntitlementWrapper: VFC<EntitlementWrapperProps> = ({
  entitlement,
  mode = "usage",
  children,
  reasons = [],
}) => {
  /********************/
  /*   custom hooks   */
  /********************/

  const { disableAdd, disableUsage, loading, entitlementIsInOverage, entitlementAddWouldBeOverage } =
    useEntitlementLogic(entitlement);
  const { usageMessage } = useEntitlementContent(entitlement, mode);
  const { nextEditionBadge } = useEntitlementBadges(entitlement);

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  const chip = nextEditionBadge?.();

  const [disabled, message] = evaluateReason([
    { test: loading, message: "One moment..." },
    {
      test: entitlementAddWouldBeOverage && disableAdd && mode === "add",
      message: usageMessage ? (
        usageMessage
      ) : (
        <>
          You cannot do this because it would put you over the limit of your current plan.{" "}
          <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade now</Link> to unlock.
        </>
      ),
    },
    {
      test: entitlementIsInOverage && disableUsage && mode === "usage",
      message: usageMessage ? (
        usageMessage
      ) : (
        <>
          We've limited access to this feature because you're over the limit on your current plan.{" "}
          <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade now</Link> to unlock.
        </>
      ),
    },
    // If we don't trigger the first reason, but
    // `disableUsage` is still true, it's because
    // something else is in overage...
    {
      test: !!disableUsage,
      message: <PlanLockedMessage />,
    },
    ...reasons,
  ]);

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return <>{children({ chip, disabled, message })}</>;
};
