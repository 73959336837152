import { Grow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";

import { createElement, useMemo, VFC } from "react";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { Override } from "../../types";
import { QuestGenericPopperContent } from "./QuestGenericPopperContent";
import { GenericQuestStepConfig, QuestStepProps, QuestStepType } from "./quests.types";
import { Modal, ModalProps } from "@components/modal/Modal";
import { ModalContent } from "@components/modal/ModalContent";
import clsx from "clsx";

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      boxShadow: "0px 4px 72px 0px rgba(104, 109, 167, 0.50)",
    },
    content: {
      padding: 0,
    },
  }),
  {
    classNamePrefix: "QuestStepModal",
  }
);

export type QuestStepModalJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestStepModalProps = Override<
  Omit<ModalProps, "children">,
  {
    classes?: Partial<ClassNameMap<QuestStepModalJSSClassKey>>;
    className?: string;
    stepConfig: GenericQuestStepConfig;
    type: QuestStepType;
    onClose: () => void;
    onBlur: () => void;
    onBack: () => void;
    arrow?: boolean;
  }
>;

export const QuestStepModal: VFC<QuestStepModalProps> = ({
  className,
  classes: extClasses,
  stepConfig,
  open,
  type,
  onClose,
  onBlur,
  onBack,
  arrow = true,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const component = useMemo(() => stepConfig?.component || QuestGenericPopperContent, [stepConfig]);

  const handleClose = useCallbackSafeRef(() => {
    if (stepConfig.type !== "action") onClose?.();
  });

  return (
    <Modal open={open} hideBackdrop classes={{ paper: clsx(classes.paper, className) }} onClose={onBlur} {...rest}>
      <ModalContent className={classes.content}>
        <Grow>
          {createElement<QuestStepProps>(component, {
            onComplete: handleClose,
            onClose: handleClose,
            onBack: onBack,
            onBlur: onBlur,
            stepConfig,
          })}
        </Grow>
      </ModalContent>
    </Modal>
  );
};
