import { isSchedulerV16 } from "@hooks/useV16User";
import { User } from "src/reclaim-api/Users";
import { ProductUsageReport } from "../../reclaim-api/Users.types";
import { YouTubeOptions } from "../../types/youtube";
import { QUESTS_CONFIG_V14 } from "./questCollections/v14.quests";
import { QUESTS_CONFIG_V17 } from "./questCollections/v17.quests";
import {
  ActiveQuest,
  GenericQuestConfig,
  GenericQuestGroupConfig,
  GenericQuestStepConfig,
  Quest,
  QuestConfig,
  QuestGroup,
  QuestsProgress,
} from "./quests.types";

export let QUESTS_CONFIG: typeof QUESTS_CONFIG_V14 | typeof QUESTS_CONFIG_V17;

export const DEFAULT_VIDEO_PLAYER_OPTIONS: YouTubeOptions = {
  autoplay: 1,
};

export const initQuestSteps = (user: User | null) => {
  if (isSchedulerV16(user?.features?.scheduler)) {
    QUESTS_CONFIG = QUESTS_CONFIG_V17;
  } else {
    QUESTS_CONFIG = QUESTS_CONFIG_V14;
  }
};

export const getActiveQuestFromQuestId = (quest: Quest): ActiveQuest<QuestGroup> | undefined => {
  let active: ActiveQuest<QuestGroup> | undefined;

  Object.keys(QUESTS_CONFIG).find((g: QuestGroup) => {
    const match = (QUESTS_CONFIG[g].quests as QuestConfig<typeof g>[]).find(
      (q: QuestConfig<typeof g>) => q.id === quest
    );
    if (match) {
      active = { group: match.groupId, quest: match.id, step: match.steps[0].id };
    }
    return !!match;
  });

  return active;
};

export const getQuestConfigFromActiveQuest = (quest: ActiveQuest<QuestGroup>): QuestConfig<QuestGroup> | undefined => {
  const questCfg = QUESTS_CONFIG[quest.group];
  if (questCfg) {
    const cfg = (questCfg.quests as QuestConfig<QuestGroup>[]).find((s) => s.id === quest.quest);
    return cfg || undefined;
  }
};

export const isQuestV2 = (quest?: ActiveQuest<QuestGroup>) => {
  if (!quest) return false;
  const questConfig = getQuestConfigFromActiveQuest(quest);
  return questConfig?.version && questConfig.version > 1;
};

export const getQuestsStepConfig = (activeQuest: ActiveQuest<QuestGroup>): GenericQuestStepConfig | undefined => {
  const quest = getQuestConfigFromActiveQuest(activeQuest);
  if (quest) {
    const step = quest?.steps.find((ss) => ss.id === activeQuest.step);
    return step || undefined;
  }
};

export const getPreviousQuestStep = (activeQuest: ActiveQuest<QuestGroup>): GenericQuestStepConfig | undefined => {
  const quest = getQuestConfigFromActiveQuest(activeQuest);
  if (quest) {
    const i = quest.steps.findIndex((s) => s.id === activeQuest.step);
    return i === 0 ? undefined : quest.steps[i - 1];
  }
};

export const getNextQuestStep = (
  activeQuest: ActiveQuest<QuestGroup>,
  user?: User | null,
  productUsage?: ProductUsageReport
): GenericQuestStepConfig | undefined => {
  const quest = getQuestConfigFromActiveQuest(activeQuest);
  if (!quest) return;
  const stepConfig = getQuestsStepConfig(activeQuest);

  // Support nextQuest callback
  if (stepConfig?.getNextQuest) {
    const questStepId =
      typeof stepConfig?.getNextQuest === "function"
        ? stepConfig?.getNextQuest({ user: user, usage: productUsage })
        : stepConfig?.getNextQuest;
    return quest.steps.find((step) => step.id === questStepId);
  }

  // Get natural next step
  const i = quest.steps.findIndex((s) => s.id === activeQuest.step);
  return i === quest.steps.length - 1 ? undefined : quest.steps[i + 1];
};

export const getQuestConfigFromQuestId = (quest: Quest): GenericQuestConfig | undefined => {
  const tempActive = getActiveQuestFromQuestId(quest);
  return tempActive ? getQuestConfigFromActiveQuest(tempActive) : undefined;
};

export const getNextQuestFromQuestId = (quest: Quest): GenericQuestConfig | undefined => {
  const tempActive = getActiveQuestFromQuestId(quest);

  if (tempActive) {
    const qIndex = QUESTS_CONFIG[tempActive.group].quests.findIndex((q) => q.id === tempActive.quest);
    if (QUESTS_CONFIG[tempActive.group].quests[qIndex + 1]) {
      return QUESTS_CONFIG[tempActive.group].quests[qIndex + 1];
    }
  }
};

export const getGroupConfigFromQuestId = (quest: Quest): GenericQuestGroupConfig | undefined => {
  const tempActive = getActiveQuestFromQuestId(quest);
  return tempActive ? QUESTS_CONFIG[tempActive.group] : undefined;
};

export const getNextQuestFromGroup = (progress: QuestsProgress, quest: Quest): GenericQuestConfig | undefined => {
  const group = getGroupConfigFromQuestId(quest);
  return group?.quests.find((q) => !progress[group.id].quests[q.id].complete && q.id !== quest);
};
