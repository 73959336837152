import { isSchedulerV16 } from "@hooks/useV16User";
import { makeOrderedListComparator } from "@utils/sort";
import { PriorityLevel } from "../../reclaim-api/Events";
import { evalOnce, indexMap } from "../../utils/objects";
import { PRIORITY_ORDERING } from "./priority.consts";
import {
  CombinedPriorityLevel,
  PriorityLevelV14,
  PriorityLevelV16,
  PriorityQueryResourceType,
  ResourceType,
} from "./priority.types";

const PRIORITY_INDEX_MAP = evalOnce(() => indexMap<PriorityLevel>(PRIORITY_ORDERING));

const PRIORITY_VERSION_MAP: { readonly [P in CombinedPriorityLevel]: 14 | 16 } = {
  DEFAULT: 14,
  PRIORITIZE: 14,
  HIGH: 14,
  P1: 16,
  P2: 16,
  P3: 16,
  P4: 16,
};

/**
 * Returns true if test is less than or equal to the provided priority
 */
export const hasPriority = (test: PriorityLevelV16, priority: CombinedPriorityLevel): boolean => {
  if (PRIORITY_INDEX_MAP()[priority] !== undefined && PRIORITY_INDEX_MAP()[priority] >= 0) {
    return (PRIORITY_INDEX_MAP()[test] || 0) >= (PRIORITY_INDEX_MAP()[priority] || 0);
  } else {
    return false;
  }
};

export const priorityComparator = makeOrderedListComparator<PriorityLevelV16>(PRIORITY_ORDERING);

export const getPriorityVersion = (priority: CombinedPriorityLevel) => PRIORITY_VERSION_MAP[priority];
export const priorityIsV16 = (priority: CombinedPriorityLevel): priority is PriorityLevelV16 =>
  isSchedulerV16(getPriorityVersion(priority));

export const toV14Priority = (priority: CombinedPriorityLevel): PriorityLevelV14 => {
  switch (priority) {
    case "P1":
      return "HIGH";
    case "P2":
    case "P3":
    case "P4":
      return "DEFAULT";
    case "PRIORITIZE":
      return "HIGH";
    default:
      return priority;
  }
};

export const toV16Priority = (v14Priority: CombinedPriorityLevel): PriorityLevelV16 => {
  switch (v14Priority) {
    case "PRIORITIZE":
    case "HIGH":
      return "P1";
    case "DEFAULT":
      return "P3";
    default:
      return v14Priority;
  }
};
const QUERY_TO_RESOURCE: Record<PriorityQueryResourceType, ResourceType> = {
  "scheduling-link": "SCHEDULING_LINKS",
  "one-on-one": "ONE_ON_ONES",
  habit: "HABITS",
  task: "TASKS",
} as const;

export const queryTypeToResourceType = (type: PriorityQueryResourceType): ResourceType => QUERY_TO_RESOURCE[type];
