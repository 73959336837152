import { Badge, Box, Button, Collapse, FormControlLabel, lighten, Radio, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckIcon from "@mui/icons-material/Check";
import { Alert } from "@mui/material";
import clsx from "clsx";
import { useCallback, useMemo, VFC } from "react";
import { useForm } from "react-hook-form";
import { getPlanDetails } from ".";
import { DesiredSubscription, SubscriptionFrequency, TeamMember } from "../../reclaim-api/team/Team";
import { EDITION_META } from "../../reclaim-api/team/Team.consts";
import { ReclaimEdition } from "../../reclaim-api/team/Team.types";
import { calculateCost } from "../../utils/billing";
import { hasError } from "../../utils/form";
import { formatDollarAmount } from "../../utils/i18n";
import { AsyncButton } from "../forms/AsyncButton";
import { RhfForm } from "../forms/rhf/RhfForm";
import { RhfRadioGroupControl } from "../forms/rhf/RhfRadioGroupControl";
import { TextFieldControl } from "../forms/rhf/TextFieldControl";
import { Link } from "../Link";
import { QuestStepWrapper } from "../quests/QuestStepWrapper";
import { ContactSupportButton } from "../ContactSupportButton";

const useStyles = makeStyles(
  (theme) => ({
    title: {
      padding: theme.spacing(0, 0.25, 0.5),
    },
    subtext: {
      marginTop: theme.spacing(-0.5),
      padding: theme.spacing(0, 0.25, 1),
    },
    sectionWrap: {
      display: "flex",
      alignItems: "baseline",
      marginBottom: theme.spacing(2.5),
    },
    seats: {
      width: 80,
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        width: 100,
      },
    },
    seatsInput: {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    labeledButtonGroup: {
      border: "1px solid",
      borderColor: lighten(theme.colors.logo.darkness, 0.85),
      borderRadius: theme.shape.borderRadius * 5,
    },
    groupButton: {
      margin: theme.spacing(0.375),
      color: theme.colors.black,
      fontSize: "0.75rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "0.875rem",
      },
    },
    selected: {
      backgroundColor: theme.colors.logo.darkness100,
      color: theme.colors.black,
      cursor: "default",
      "&:hover": {
        backgroundColor: theme.colors.logo.darkness100,
        color: theme.colors.black,
      },
    },
    editionGroup: {
      marginBottom: theme.spacing(2.5),
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    editionGroupRoot: {
      display: "grid",
      gap: theme.spacing(1),
      gridTemplateColumns: "100%",
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "1fr 1fr",
      },
    },
    radio: {
      alignItems: "flex-start",
      padding: theme.spacing(1.5),
      margin: 0,
      borderColor: lighten(theme.colors.logo.darkness, 0.85),
      border: "1px solid",
      borderRadius: 12,
      [theme.breakpoints.up("sm")]: {
        maxWidth: 220,
      },
    },
    radioRoot: {
      padding: 0,
      margin: theme.spacing(0, "5px", 0, 0),
    },
    radioSelected: {
      backgroundColor: theme.colors.logo.darkness100,
    },
    editionDetails: {},
    footerButtons: {
      display: "flex",
      margin: theme.spacing(2, 0, 1),
    },
    detail: {
      display: "flex",
      margin: theme.spacing(0.5, 0, 0.5, "-26px"),
      "&:first-of-type": {
        marginTop: theme.spacing(1.5),
      },
    },
    check: {
      color: theme.palette.success.main,
      marginRight: theme.spacing(1),
      width: 18,
      height: 18,
    },
    costs: {
      display: "flex",
      alignItems: "flex-start",
      fontWeight: theme.typography.fontWeightLight,
      gap: theme.spacing(0.5),
    },
    baseCost: {},
    notThisPrice: {
      textDecoration: "line-through",
      textDecorationColor: "rgba(0,0,0,.3)",
      fontSize: "0.5em",
      marginTop: theme.spacing(0.5),
      color: theme.colors.textGrey,
    },
    discountedCost: {},
    savingsBadge: {
      background: theme.colors.logo.corn,
      margin: theme.spacing(0.5, 0),
      position: "static",
      transform: "none",
      whiteSpace: "nowrap",
    },
  }),
  {
    classNamePrefix: "SubscriptionForm",
  }
);

type FormValues = {
  seats: string;
  paymentPeriod: SubscriptionFrequency;
  edition: "PRO" | "TEAM";
};

const defaultValues: FormValues = {
  seats: "0",
  paymentPeriod: SubscriptionFrequency.Year,
  edition: "TEAM",
};

export type SubscriptionFormProps = {
  teamMembers: TeamMember[];
  onSubmit: (data: DesiredSubscription) => Promise<void> | void;
};

export const SubscriptionForm: VFC<SubscriptionFormProps> = ({ teamMembers, onSubmit }) => {
  const classes = useStyles();

  const form = useForm<FormValues, object>({
    defaultValues: {
      ...defaultValues,
      seats: teamMembers.length.toString(),
    },
  });

  const watch = form.watch();
  const isAnnual = watch.paymentPeriod === SubscriptionFrequency.Year;

  const showWarning = useMemo(
    () => !!watch.seats && parseInt(watch.seats) > 0 && parseInt(watch.seats) < teamMembers.length,
    [watch, teamMembers]
  );

  const showMessage = useMemo(() => !!watch.seats && parseInt(watch.seats) > 1, [watch]);

  const annualSeatCosts = useMemo<{ PRO: string; TEAM: string }>(() => {
    const seats = parseInt(watch.seats);

    if (!!seats && seats >= 1) {
      const { proPricePerUser, teamPricePerUser } = calculateCost(seats, "annually");

      return {
        PRO: formatDollarAmount(proPricePerUser),
        TEAM: formatDollarAmount(teamPricePerUser),
      };
    } else {
      return { PRO: "-", TEAM: "-" };
    }
  }, [watch.seats]);

  const monthlySeatCosts = useMemo(() => {
    const seats = parseInt(watch.seats);

    if (!!seats && seats >= 1) {
      const { proPricePerUser, teamPricePerUser } = calculateCost(seats, "monthly");

      return {
        PRO: formatDollarAmount(proPricePerUser),
        TEAM: formatDollarAmount(teamPricePerUser),
      };
    } else {
      return { PRO: "-", TEAM: "-" };
    }
  }, [watch.seats]);

  const handleSubmit = useCallback(async () => {
    await form.trigger();

    if (!hasError(form)) {
      const values = form.getValues();
      const data: DesiredSubscription = {
        frequency: values.paymentPeriod,
        edition: values.edition,
        seats: Number(values.seats),
      };

      await onSubmit(data);
    } else {
      throw new Error("Subscription validation failed.");
    }
  }, [form, onSubmit]);

  const handlePaymentPeriodChange = useCallback(
    (period: SubscriptionFrequency) => {
      form.setValue("paymentPeriod", period);
    },
    [form]
  );

  const PlanRadioBox: VFC<{ edition: ReclaimEdition }> = ({ edition }) => {
    const seatCostKey = edition === "TEAM" ? "TEAM" : "PRO";

    return (
      <FormControlLabel
        value={edition}
        label={
          <Box className={classes.editionDetails}>
            <Typography variant="h6">{edition === "TEAM" ? "Team" : EDITION_META[edition].label}</Typography>
            {isAnnual && <Badge badgeContent="17% annual discount" classes={{ badge: classes.savingsBadge }} />}
            <Typography className={classes.costs} variant="h2">
              <Typography
                component="span"
                variant="inherit"
                className={clsx(classes.baseCost, {
                  [classes.notThisPrice]: isAnnual,
                })}
              >
                {monthlySeatCosts[seatCostKey]}
              </Typography>
              {isAnnual && (
                <Typography component="span" className={classes.discountedCost} variant="inherit">
                  {annualSeatCosts[seatCostKey]}
                </Typography>
              )}
            </Typography>
            <Typography variant="body2">per user/month</Typography>
            {getPlanDetails(edition).map((deet: string, i) => (
              <Box key={`plan-detail-${i}`} className={classes.detail}>
                <CheckIcon className={classes.check} />
                <Typography variant="body2">{deet}</Typography>
              </Box>
            ))}
          </Box>
        }
        control={<Radio color="primary" className={classes.radioRoot} />}
        className={clsx(classes.radio, {
          [classes.radioSelected]: watch.edition === edition,
        })}
      />
    );
  };

  return (
    <RhfForm form={form} onSubmit={handleSubmit}>
      <Typography className={classes.title} variant="h6">
        Number of seats
      </Typography>
      <Box className={classes.sectionWrap}>
        <TextFieldControl
          name="seats"
          className={classes.seats}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          InputProps={{
            classes: {
              input: classes.seatsInput,
            },
          }}
          type="number"
          variant="outlined"
          size="small"
          options={{
            min: {
              value: 1,
              message: "minimum of 1 seat",
            },
          }}
          required
          autoFocus
        />

        {/* TODO (SS): Make this a rhf component? */}
        <Box className={classes.labeledButtonGroup}>
          <Button
            onClick={() => handlePaymentPeriodChange(SubscriptionFrequency.Year)}
            className={clsx(classes.groupButton, {
              [classes.selected]: watch.paymentPeriod === SubscriptionFrequency.Year,
            })}
          >
            Annually
          </Button>
          <Button
            onClick={() => handlePaymentPeriodChange(SubscriptionFrequency.Month)}
            className={clsx(classes.groupButton, {
              [classes.selected]: watch.paymentPeriod === SubscriptionFrequency.Month,
            })}
          >
            Monthly
          </Button>
        </Box>
      </Box>

      <Typography className={classes.title} variant="h6">
        Plan
      </Typography>
      <Typography className={classes.subtext} variant="body2">
        More details about each plan are available on our{" "}
        <Link href="//reclaim.ai/pricing" target="reclaim-pricing">
          Pricing
        </Link>{" "}
        page.{" "}
        {showMessage && (
          <span>
            <ContactSupportButton variant="link" lead="I want to add Pro & Team seats to my plan...">
              Contact us
            </ContactSupportButton>{" "}
            for a plan of both Pro &amp; Team seats.
          </span>
        )}
      </Typography>
      <RhfRadioGroupControl
        name="edition"
        className={classes.editionGroup}
        classes={{ root: classes.editionGroup }}
        RadioGroupProps={{ className: classes.editionGroupRoot }}
      >
        <PlanRadioBox edition="PRO" />
        <PlanRadioBox edition="TEAM" />
      </RhfRadioGroupControl>
      <Collapse in={showWarning}>
        <Alert severity="warning">
          {`You\'re purchasing ${watch.seats} seats for ${teamMembers.length} team members. We\'ll automatically switch some users to the Free plan, but you can adjust this later.`}
        </Alert>
      </Collapse>
      <Box className={classes.footerButtons}>
        <QuestStepWrapper config={{ group: "INVITE_TEAM", quest: "SELECT_PLAN", step: "CLICK_FINISH_ON_STRIPE" }}>
          <AsyncButton onClick={handleSubmit} variant="contained" color="primary">
            Finish on Stripe
          </AsyncButton>
        </QuestStepWrapper>
      </Box>
    </RhfForm>
  );
};
