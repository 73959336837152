import { MembershipRole, Team, TeamMember } from "../reclaim-api/team/Team";
import { User } from "../reclaim-api/Users";

export const isOnlyAdminOnTeam = (team: Team, userMembership: TeamMember): boolean => {
  // The user is not on a team
  if (team.members.length === 1) return false;

  // The user is an admin, and there is another admin on the team.
  if (userMembership.membershipRole === MembershipRole.Admin) {
    const otherAdmin = team.members.find(
      (m) => m.teamMemberId.userId !== userMembership.teamMemberId.userId && m.membershipRole === MembershipRole.Admin
    );
    return !otherAdmin;
  } else {
    // Normal user role.
    return false;
  }
};

export const userIsAdminOnTeam = (user: User | undefined, team: Team): boolean =>
  (user && team.members.find(({ teamMemberId: { userId } }) => userId === user?.id))?.membershipRole ===
  MembershipRole.Admin;
