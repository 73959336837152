import { TeamRedirectAction } from "../../reclaim-api/team/Team";
import { EDITION_META, ENTITLEMENT_META } from "../../reclaim-api/team/Team.consts";
import { EntitlementType } from "../../reclaim-api/team/Team.types";
import { getBillingUrl } from "../../utils/router";
import { pluralize } from "../../utils/strings";
import { Link } from "../Link";
import { EditionBadge } from "./EditionBadge";
import { EntitletipTitlesCollection, EntitletipTitlesFn } from "./Entitletip.types";

const numToStr = (num: number) => (num === Infinity ? "unlimited" : `${num}`);

const makeIntegrationTitleFn =
  <T extends EntitlementType>(entitlement: T): EntitletipTitlesFn<T> =>
  ({ nextEdition }) => (
    <>
      You can't use the {ENTITLEMENT_META[entitlement].label} on your current plan.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to keep access to it!
    </>
  );

// Keeping these as separate functions so we can break
// them out separately if we want later without hassle.
const makeCustomBlockingTitleFn = makeIntegrationTitleFn;

const pluralizeUpTo = (count: number, noun: string, options: { plural?: string; upToText?: string } = {}) =>
  `${!!count ? options.upToText || `up to ${count}` : "no"} ${pluralize(count, noun, options.plural)}`;

export const ENTITLETIP_TITLES: {
  [ENTITLEMENT in EntitlementType]: EntitletipTitlesCollection<ENTITLEMENT> | EntitletipTitlesFn<ENTITLEMENT>;
} = {
  TEAM_SIZE: ({ value, nextValue, nextEdition }) => (
    <>
      You can have {pluralizeUpTo(value.max, "member")} on your team.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>
        Upgrade to
        {EDITION_META[nextEdition].label}
      </Link>{" "}
      to increase your time size to {numToStr(nextValue.max)} people!
    </>
  ),
  SCHEDULER_WEEKS: ({ value, nextValue, nextEdition }) => (
    <>
      You can currently schedule links {pluralizeUpTo(value, "week")} in advance.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to schedule {nextValue} weeks out!
    </>
  ),
  MAX_TASKS: ({ value, nextValue, nextEdition }) => (
    <>
      You can create {pluralizeUpTo(value, "Task")}.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to get {numToStr(nextValue)} Tasks.
    </>
  ),
  MAX_CALENDARS: ({ value, nextValue, nextEdition }) => (
    <>
      You can connect {pluralizeUpTo(value, "calendar")}.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to connect {numToStr(nextValue)} calendars.
    </>
  ),
  MAX_SYNCS: ({ value, nextValue, nextEdition }) => (
    <>
      You can {value === 1 && "only "} sync{" "}
      {value === 1 ? `${value} calendar` : pluralizeUpTo(value, "calendar", { upToText: "up across" })}.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to add {numToStr(nextValue)} syncs.
    </>
  ),
  MAX_HABITS: ({ value, nextValue, nextEdition }) => (
    <>
      You can create {pluralizeUpTo(value, "Habit")}.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to get {numToStr(nextValue)} Habits.
    </>
  ),
  MAX_CUSTOM_TIME_SCHEMES: ({ nextEdition }) => (
    <>
      You cannot create custom hours.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to get unlimited.
    </>
  ),
  MAX_SCHEDULING_LINKS: ({ value, nextValue, nextEdition }) => (
    <>
      You can {value === 1 && "only "} create{" "}
      {value === 1 ? `${value} Scheduling Link` : pluralizeUpTo(value, "Scheduling Link")}.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to get {numToStr(nextValue)} Scheduling Links.
    </>
  ),
  MAX_1_ON_1_ORGANIZE: ({ value, nextValue, nextEdition }) => (
    <>
      You can {value === 1 && "only "} organize {value === 1 ? `${value} Smart 1:1` : pluralizeUpTo(value, "Smart 1:1")}
      . <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to organize {pluralizeUpTo(nextValue, "Smart 1:1")}.
    </>
  ),
  MAX_1_ON_1_ATTEND: ({ value, nextValue, nextEdition }) => (
    <>
      You can join {pluralizeUpTo(value, "Smart 1:1")}.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to join {numToStr(nextValue)} Smart 1:1s.
    </>
  ),
  TEAM_ANALYTICS: ({ nextEdition }) => (
    <>
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to get Team Analytics.
    </>
  ),
  SUPPORT: ({ value, nextValue, nextEdition }) => (
    <>
      You have access to {value.toLowerCase()} support.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to get
      {nextValue.toLocaleLowerCase()} support.
    </>
  ),
  SSO: ({ nextEdition }) => (
    <>
      Your team cannot log in via SSO.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to allow you and your team mates to log in with SSO!
    </>
  ),
  DERIVATIVE_SCHEDULING_LINKS: ({ nextEdition }) => (
    <>
      Custom links are only available with a <EditionBadge edition={nextEdition} /> plan.
    </>
  ),
  CUSTOM_SLACK_STATUS: makeIntegrationTitleFn("CUSTOM_SLACK_STATUS"),

  // custom blocking
  CUSTOM_BLOCKING: ({ nextEdition }) => (
    <>
      Customize how this time gets displayed on your calendar.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>
    </>
  ),
  CUSTOM_BLOCKING_CALENDAR_SYNC: makeCustomBlockingTitleFn("CUSTOM_BLOCKING_CALENDAR_SYNC"),
  CUSTOM_BLOCKING_DECOMPRESSION: makeCustomBlockingTitleFn("CUSTOM_BLOCKING_DECOMPRESSION"),
  CUSTOM_BLOCKING_HABITS: makeCustomBlockingTitleFn("CUSTOM_BLOCKING_HABITS"),

  // custom questions
  SCHEDULING_LINK_SURVEY: ({ nextEdition }) => (
    <>
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to add custom questions.
    </>
  ),

  SCHEDULING_LINK_REDIRECT: ({ nextEdition }) => (
    <>
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to add redirect URLs
    </>
  ),

  // integrations
  INTEGRATIONS: ({ value, nextValue, nextEdition }) => (
    <>
      You have access to {value.toLowerCase()} integrations but are using some from a higher plan.{" "}
      <Link href={getBillingUrl(TeamRedirectAction.StartPurchase)}>Upgrade to {EDITION_META[nextEdition].label}</Link>{" "}
      to get {nextValue.toLocaleLowerCase()} integrations.
    </>
  ),

  INTEGRATION_SLACK: makeIntegrationTitleFn("INTEGRATION_SLACK"),
  INTEGRATION_RAYCAST: makeIntegrationTitleFn("INTEGRATION_RAYCAST"),
  INTEGRATION_CLICKUP: makeIntegrationTitleFn("INTEGRATION_CLICKUP"),
  INTEGRATION_ZOOM: makeIntegrationTitleFn("INTEGRATION_ZOOM"),
  INTEGRATION_GOOGLE_TASKS: makeIntegrationTitleFn("INTEGRATION_GOOGLE_TASKS"),
  INTEGRATION_JIRA: makeIntegrationTitleFn("INTEGRATION_JIRA"),
  INTEGRATION_ASANA: makeIntegrationTitleFn("INTEGRATION_ASANA"),
  INTEGRATION_TODOIST: makeIntegrationTitleFn("INTEGRATION_TODOIST"),
  INTEGRATION_LINEAR: makeIntegrationTitleFn("INTEGRATION_LINEAR"),
  INTEGRATION_GOOGLE_ADD_ON: makeIntegrationTitleFn("INTEGRATION_GOOGLE_ADD_ON"),
  INTEGRATION_MONDAY: makeIntegrationTitleFn("INTEGRATION_MONDAY"),
  INTEGRATION_OFFICE_365: makeIntegrationTitleFn("INTEGRATION_OFFICE_365"),
  INTEGRATION_TRELLO: makeIntegrationTitleFn("INTEGRATION_TRELLO"),
};
