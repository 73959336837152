import { useModal } from "mui-modal-provider";
import { useCallback, VFC } from "react";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { reclaim } from "../../reclaim-api";
import { DesiredSubscription, TeamMember } from "../../reclaim-api/team/Team";
import { Modal } from "../modal/Modal";
import { ModalContent } from "../modal/ModalContent";
import { ModalHeader } from "../modal/ModalHeader";
import { QuestStepWrapper } from "../quests/QuestStepWrapper";
import { SubscriptionForm } from "./SubscriptionForm";

export type SubscriptionModalProps = {
  open: boolean;
  teamMembers: TeamMember[];
  onClose: () => void;
};

export const SubscriptionModal: VFC<SubscriptionModalProps> = ({ open, teamMembers, onClose }) => {
  const handleSubmit = useCallback(
    async (data: DesiredSubscription) => {
      await reclaim.team.startSubscription(data);
      onClose();
    },
    [onClose]
  );

  return (
    <Modal open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <ModalHeader onClose={onClose}>
        Reclaim subscription
        <QuestStepWrapper
          config={{ group: "INVITE_TEAM", quest: "SELECT_PLAN", step: "PURCHASE_INFO" }}
          QuestOrbProps={{ offsetX: 10 }}
          popperPlacement="bottom-start"
        />
      </ModalHeader>
      <ModalContent>
        <SubscriptionForm teamMembers={teamMembers} onSubmit={handleSubmit} />
      </ModalContent>
    </Modal>
  );
};

export const useSubscriptionModal = (teamMembers: TeamMember[]) => {
  const { showModal } = useModal();
  return useCallbackSafeRef(() => {
    const closeModal = showModal(SubscriptionModal, { teamMembers, onClose: () => closeModal() }).hide;
  });
};
