import { DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ReactNode, VFC } from "react";
import { ModalCloseHandler } from "./Modal";
import { ModalCloseButton } from "./ModalCloseButton";

import { ClassNameMap } from "@mui/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(4, 4, 2),
    },
  }),
  {
    classNamePrefix: "ModalHeader",
  }
);

export type ModalHeaderJSSClassKey = keyof ReturnType<typeof useStyles>;

export type ModalHeaderProps = {
  classes?: Partial<ClassNameMap<ModalHeaderJSSClassKey>>;
  className?: string;
  description?: string;
  children?: ReactNode;
  onClose: ModalCloseHandler;
};

export const ModalHeader: VFC<ModalHeaderProps> = ({ className, description, classes: extClasses, children, onClose }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <DialogTitle className={clsx(classes.root, className)}>
      <Typography variant="h5">{children}</Typography>
      {description && <Typography>{description}</Typography>}
      <ModalCloseButton onClose={onClose} />
    </DialogTitle>
  );
};
