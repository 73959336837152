import { ReactNode } from "react";
import { ENTITLETIP_TITLES } from "../components/billing/Entitletip.consts";
import { EntitletipMode, EntitletipTitlesCollection } from "../components/billing/Entitletip.types";
import { EntitlementType } from "../reclaim-api/team/Team.types";
import { useEntitlementTable } from "./useEntitlementTable";
import { useUsageData } from "./useUsageData";

export type UseEntitlementContentReturnType = {
  usageMessage?: ReactNode;
  loading: boolean;
};

export const useEntitlementContent = <ENTITLEMENT extends EntitlementType>(
  entitlement: ENTITLEMENT,
  mode: EntitletipMode
): UseEntitlementContentReturnType => {
  const hookData = useUsageData();
  const { loading: usageDataLoading, usageData } = hookData;
  const { entitlementTable, loading: entitlementTableLoading } = useEntitlementTable();

  const userCurrentEdition = usageData?.currentEdition;

  const loading = entitlementTableLoading || usageDataLoading;

  const entitlementObj =
    userCurrentEdition && (entitlementTable ? entitlementTable[userCurrentEdition][entitlement] : undefined);

  const entitletipContent = ENTITLETIP_TITLES[entitlement];
  const entitletipContentCollection: EntitletipTitlesCollection<ENTITLEMENT> =
    typeof entitletipContent === "function"
      ? {
          add: entitletipContent,
          usage: entitletipContent,
        }
      : entitletipContent;

  const usageMessage =
    mode !== "subtract" && entitlementObj?.nextEdition
      ? entitletipContentCollection[mode](entitlementObj as never)
      : undefined;

  return {
    usageMessage,
    loading,
  };
};
