import RepeatRoundedIcon from "@mui/icons-material/RepeatRounded";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import AdjustIcon from "../../../img/adjust-icon.svg";
import HabitsImg from "../../../img/habits.svg";
import { QuestsConfig } from "../quests.types";
import { satisfies } from "@utils/typescript";
import { Loading } from "@components/Loading";

export type HabitQuestSteps<QuestIdx extends number> = (typeof habitsQuest)["quests"][QuestIdx]["steps"][number]["id"];

export type HabitQuestStepIds = (typeof habitsQuest)["quests"][number]["steps"][number]["id"];

export const habitsQuest = satisfies<QuestsConfig["HABITS"]>()({
  id: "HABITS",
  title: "Set your Habits",
  quests: [
    {
      version: 2,
      id: "HABITS_OVERVIEW_2",
      groupId: "HABITS",
      title: "Habits overview",
      description: "Learn about habits",
      shortDescription: "Learn about habits",
      duration: "5min",
      entryPathname: "/habits",
      icon: RepeatRoundedIcon,
      steps: [
        {
          id: "WHAT_ARE_HABITS",
          type: "orb",
          popoverConfig: {
            gif: <Loading />,
            title: "What are Habits?",
            description: (
              <div>
                Habits are smart, flexible routines for your calendar. <br />
                You pick the things you want to get done regularly, roughly when you want them to happen, and how
                important they are, and Reclaim automatically finds the right spots for them on your busy schedule.
              </div>
            ),
          },
        },
        {
          id: "NOT_RECURRING_EVENTS",
          type: "orb",
          popoverConfig: {
            gif: <Loading />,
            title: "What are Habits?",
            description: (
              <div>
                Habits are not the same as recurring events. <br />
                They don't always happen at the same time every day, and they're meant to have a little bit of room to
                wiggle as your schedule changes. <br />
                They're intended to keep your schedule flexible, just like you!
              </div>
            ),
          },
        },
        // Start Branching based on if user has a habit or not
        {
          id: "FREE_BUSY",
          type: "orb",
          popoverConfig: {
            gif: <Loading />,
            title: "What are Habits?",
            description: (
              <div>
                Just like all of Reclaim's smart calendar events, Habits automatically flip between Free and Busy to
                ensure that you get the time you need without completely blocking off your availability. <br />
                As Reclaim's AI starts to see you running out of time for your routine, it'll block the time off to
                prevent you from getting overbooked.
              </div>
            ),
          },
          getNextQuest: ({ usage }) =>
            (usage?.actuals.MAX_HABITS?.actualValue ?? 0) === 0 ? "CREATE_NEW" : "VIEW_EXISTING",
        },
        {
          id: "CREATE_NEW",
          type: "action",
          popoverConfig: {
            title: "Lets Get Started",
            description: <div>let's create a new Habit or one from an existing template</div>,
          },
          getNextQuest: "FOCUS_TIME_STEP",
          allowedRoutes: /habits\/.*\/edit/,
        },
        {
          id: "VIEW_EXISTING",
          type: "orb",
          popoverConfig: {
            title: "Lets Get Started",
            description: <div>To get started, let's check out a Habit you've already created</div>,
          },
        },
        {
          id: "EXISTING_REDIRECT",
          type: "redirect",
          redirect: "/habits/1/edit",
          getNextQuest: "PRIORITY",
        },
        // End Branching based on if user has a habit or not
        {
          id: "FOCUS_TIME_STEP",
          type: "orb",
          popoverConfig: {
            title: "Lets Get Started",
            description: (
              <div>
                All great routines start with a great name. Let's call this one "Focus Time" just to keep things easy
              </div>
            ),
          },
        },
        {
          id: "PRIORITY",
          type: "orb",
          popoverConfig: {
            title: "Habit Priorities",
            description: (
              <div>
                Habits have a priority that you can change at any time. <br />
                Critical Habits schedule first, Low priority Habits schedule last.
                <br /> If Reclaim needs to make tradeoffs to get time for your routines, it'll prefer higher-priority
                Habits.
              </div>
            ),
          },
        },
        {
          id: "CATEGORIZE",
          type: "orb",
          popoverConfig: {
            title: "Habit Categories",
            description: (
              <div>
                You can categorize your Habit as Personal or Work, which will define how it gets color-coded on your
                calendar and shows up in your Stats. <br />
                You can also pick any old color you'd like for a specific Habit.
              </div>
            ),
          },
        },
        {
          id: "DURATION",
          type: "orb",
          popoverConfig: {
            title: "Habit Duration",
            description: (
              <div>
                Now to the fun part! Set the minimum amount of time you'd ever want to spend on the Habit, and the
                maximum amount of time you might want. <br />
                This is the range that Reclaim will try to schedule on your calendar.
              </div>
            ),
          },
        },
        {
          id: "HOURS",
          type: "orb",
          popoverConfig: {
            title: "Habit Hours",
            description: (
              <div>
                Pick from your existing Hours, or set one-off Hours for a Habit that has specific days and times. <br />
                For example, Lunch might only ever make sense to happen between 11am and 2pm M-F.
              </div>
            ),
          },
        },
        {
          id: "FREQUENCY",
          type: "orb",
          popoverConfig: {
            title: "Habit Frequency",
            description: (
              <div>
                Now tell Reclaim how often you want to do the Habit, and ideally what time you'd like to have it happen.
                <br />
                Reclaim will try to schedule as close to this ideal time as possible.
              </div>
            ),
          },
        },
        {
          id: "NOTES",
          type: "orb",
          popoverConfig: {
            title: "Habit Notes",
            description: (
              <div>
                Lastly, you can add some notes to the Habit -- for example, a link to a doc or a to-do list -- as well
                as decide how you'd like it to show up to people who view your calendar.
              </div>
            ),
          },
        },
        {
          id: "PRE_PLANNER",
          type: "orb",
          popoverConfig: {
            title: "Habits in the Planner",
            description: <div>Let's talk about how to manage Habits on your calendar now. </div>,
            nextButtonLabel: "Go To Planner",
          },
        },
        {
          id: "GO_TO_PLANNER",
          type: "redirect",
          redirect: "/planner",
        },
        {
          id: "NOT_LIKE_THE_OTHER_GUYS",
          type: "orb",
          popoverConfig: {
            title: "Habits Hit Different",
            description: (
              <div>
                Habits can be worked with like any other event on your calendar, but there are some special smarts to
                them.
                <ul>
                  <li>Drag a Habit to any other time to Lock it in place and prevent Reclaim from rescheduling it.</li>
                  <li>
                    Delete a Habit event to skip it for the day. Reclaim will reschedule it to the next best day, if it
                    makes sense for the Habit.
                  </li>
                  <li>
                    Use the Reschedule option from our Gcal add-on or from the Planner to automatically reschedule a
                    Habit to later in the day.
                  </li>
                </ul>
              </div>
            ),
          },
        },
        {
          id: "HOW_TO_START",
          type: "orb",
          popoverConfig: {
            title: "How to start habits",
            description: (
              <div>
                If you want to get started on a Habit right now, use the Start button. <br />
                You can Stop or Restart it at any time if you change your mind.
              </div>
            ),
          },
        },
        {
          id: "YOU_THE_EXPERT",
          type: "orb",
          popoverConfig: {
            title: "",
            description: (
              <div>
                OK -- you're a Habits expert now! Click here to learn more about Habits, or explore on your own.
              </div>
            ),
          },
        },
        {
          id: "CONFETTI",
          type: "confetti",
          popoverConfig: {
            description: "Nice work completing the Habits overview  🎉",
          },
        },
      ],
    },
    {
      id: "CREATE_AND_CUSTOMIZE_HABITS",
      groupId: "HABITS",
      title: "Create & customize your Habits",
      description: "Set up & prioritize your Habits to keep your workweek focused & balanced.",
      shortDescription: "Add & customize your routines",
      duration: "4min",
      entryPathname: "/habits",
      indeterminant: true,
      icon: AdjustIcon,
      steps: [
        {
          id: "CREATE_NEW_HABIT",
          type: "action",
          popoverConfig: {
            description: "Tap to create a new Habit.",
            svg: HabitsImg,
          },
        },
        {
          id: "HABIT_FORM_INFORMATION",
          type: "orb",
          popoverConfig: {
            description:
              "Set preferences for how you want the Habit to schedule, & how it will appear on your calendar.",
          },
        },
        {
          id: "SET_HABIT_PRIORITY",
          type: "action",
          popoverConfig: {
            description: "Tap to set a priority",
          },
        },
        {
          id: "SAVE_HABIT",
          type: "action",
          popoverConfig: {
            description: 'Tap "Save" to start automatically scheduling your Habit.',
          },
        },
      ],
    },
    {
      id: "MANAGE_HABITS",
      groupId: "HABITS",
      title: "Manage Habits on your calendar",
      description: "Learn how to adjust Habit events in your schedule.",
      shortDescription: "How to work with Habits on your schedule",
      duration: "3min",
      entryPathname: "/planner",
      icon: TodayOutlinedIcon,
      steps: [
        {
          id: "PLANNER_HABIT_MANAGEMENT",
          type: "orb",
          popoverConfig: {
            description: "Watch a quick overview on how to manage your Habit events.",
            img: "/img/quests/habits-planner3.gif",
          },
        },
        { id: "VIDEO_HABIT_MANAGEMENT", type: "video", videoLink: "4PxE7M_eNTg" },
      ],
    },
  ],
} as const);
