import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import { FC } from "react";
import moduleClasses from "./CircularProgressWithBackground.module.scss";

export type CircularProgressWithBackgroundCSSClassKey = keyof typeof moduleClasses;

export type CircularProgressWithBackgroundProps = {
  classes?: SCSSClasses<CircularProgressWithBackgroundCSSClassKey>;
  className?: string;
  size?: number;
  thickness?: number;
  fraction: number;
  color?: CircularProgressProps["color"];
};

export const CircularProgressWithBackground: FC<CircularProgressWithBackgroundProps> = ({
  className,
  classes: extClasses,
  fraction,
  size = 75,
  thickness = size / 15,
  children,
  color = "primary",
}) => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  return (
    <Box className={clsx(classes.root, className)}>
      <CircularProgress
        className={classes.foreground}
        thickness={thickness}
        size={size}
        variant="determinate"
        value={fraction * 100}
        color={color}
      />
      <CircularProgress
        className={classes.background}
        thickness={thickness}
        size={size}
        variant="determinate"
        value={100}
        color={color}
      />
      {children && <Box className={classes.children}>{children}</Box>}
    </Box>
  );
};