import { QuestTypeStr } from "../../../reclaim-api/Users";
import { QuestGroup, QuestGroupMap } from "../quests.types";

export const QUESTS_DIRECTORY: { [QG in QuestGroup]: (QuestTypeStr & QuestGroupMap[QG])[] } = {
  GETTING_STARTED: [
    "WELCOME_TO_RECLAIM",
    "PRIORITIZATION",
    "SYNC_CALENDAR",
    "SET_AVAILABILITY",
    "SET_SCHEDULING_HOURS",
    "INSTALL_GCAL_ADDON",
    "INSTALL_SLACK_INTEGRATION",
  ],
  HABITS: ["HABITS_OVERVIEW", "CREATE_AND_CUSTOMIZE_HABITS", "MANAGE_HABITS"],
  SMART_MEETINGS: [
    "SCHEDULING_LINKS_OVERVIEW",
    "SETUP_SCHEDULING_LINKS",
    "SETUP_TEAM_LINKS",
    "SMART_ONE_ON_ONE_OVERVIEW",
    "SETUP_FIRST_SMART_ONE_ON_ONE",
    "MANAGE_SMART_ONE_ON_ONE",
  ],
  TASKS: ["TASK_OVERVIEW", "ADD_FIRST_TASK", "ADD_TASK_INTEGRATION", "MANAGE_TASKS", "SET_TASK_DEFAULTS"],
  CUSTOMIZE_CALENDAR: [
    "AUTO_SCHEDULE_BREAK_AND_TRAVEL_TIME",
    "SETUP_NO_MEETING_DAYS",
    "CUSTOMIZE_EVENT_COLOR_CODING",
    "TRACK_AND_ANALYZE_PRODUCTIVITY",
  ],
  INVITE_TEAM: ["ADD_MEMBERS_TO_TEAM", "SELECT_PLAN"],
};
