import { ReactNode } from "react";
import { EntitlementActualObject } from "../../reclaim-api/Users.types";
import { EntitlementType, EntitlementValueTypeMap, ReclaimEdition } from "../../reclaim-api/team/Team.types";
import { getHabitsPath, getHoursUrl, getSchedulingLinksUrl, getSyncHomeUrl } from "../../utils/router";
import { pluralize } from "../../utils/strings";
import { Link } from "../Link";
import { OverageListIntegrationsSubList } from "./OverageListIntegrationsSubList";
import { OverageListCustomBlockingSubList } from "./OverageListCustomBlockingSubList";

export const OVERAGE_LIST_ENTITLEMENT_ORDER: readonly EntitlementType[] = Object.freeze([
  "TEAM_SIZE",
  "MAX_SCHEDULING_LINKS",
  "MAX_HABITS",
  "MAX_SYNCS",
  "SCHEDULER_WEEKS",
  "MAX_CALENDARS",
  "MAX_TASKS",
  "MAX_CUSTOM_TIME_SCHEMES",
  "CUSTOM_BLOCKING",
  "MAX_1_ON_1_ORGANIZE",
  "MAX_1_ON_1_ATTEND",
  "INTEGRATIONS",
  "SUPPORT",
  "SSO",
]);

const pluralizeEntitlementObject = (
  { actualValue, allowedValueForCurrentEdition }: EntitlementActualObject<number>,
  noun: string,
  url?: string
) => {
  const pluralized = pluralize(actualValue - allowedValueForCurrentEdition, noun);

  return (
    <>
      <em>{actualValue - allowedValueForCurrentEdition}</em> {url ? <Link href={url}>{pluralized}</Link> : pluralized}
    </>
  );
};
const disableOrRemoveStr = (noun: string, url?: string) => (obj: EntitlementActualObject<number>) =>
  <>Disable or remove {pluralizeEntitlementObject(obj, noun, url)}</>;
const removeStr = (noun: string, url?: string) => (obj: EntitlementActualObject<number>) =>
  <>Remove {pluralizeEntitlementObject(obj, noun, url)}</>;

export const OVERAGE_LIST_ENTITLEMENT_META: {
  [E in EntitlementType]?: {
    render: (obj: EntitlementActualObject<EntitlementValueTypeMap[E]>, currentEdition: ReclaimEdition) => ReactNode;
  };
} = {
  MAX_SCHEDULING_LINKS: { render: disableOrRemoveStr("Scheduling Link", getSchedulingLinksUrl()) },
  MAX_HABITS: { render: disableOrRemoveStr("Habit", getHabitsPath()) },
  MAX_CUSTOM_TIME_SCHEMES: { render: removeStr("Custom Hour", getHoursUrl()) },
  MAX_SYNCS: { render: disableOrRemoveStr("Calendar Sync", getSyncHomeUrl()) },
  MAX_CALENDARS: { render: removeStr("Connected Calendar", "/settings/accounts") },
  MAX_TASKS: { render: disableOrRemoveStr("Task", "/tasks") },
  CUSTOM_BLOCKING: { render: () => <OverageListCustomBlockingSubList /> },
  MAX_1_ON_1_ORGANIZE: { render: disableOrRemoveStr("Smart 1:1", "/one-on-ones") },
  MAX_1_ON_1_ATTEND: { render: disableOrRemoveStr("Smart 1:1", "/one-on-ones") },
  INTEGRATIONS: {
    render: () => <OverageListIntegrationsSubList />,
  },
};
