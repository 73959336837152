import { Snackbar } from "../../components/notifications/Snackbar";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { closeSnackbar, enqueueSnackbar } from "notistack";
import classes from "./notifications.module.scss";
import { atom } from "jotai";
import { createStore } from "jotai/vanilla";
import { MILLISECONDS_PER_HOUR } from "@utils/dates";
import { Typography } from "@mui/material";
import { LinkButton } from "@components/LinkButton";

const notifificationStore = createStore();

const allowAppVersionAtom = atom(true);
notifificationStore.set(allowAppVersionAtom, true);

const NETWORK_NOTIFICATION_KEY = "network-refresh";
const APP_VERSION_NOTIFICATION_KEY = "app-version";

export const showNetworkRefreshNotification = () => {
  closeSnackbar(NETWORK_NOTIFICATION_KEY);
  setTimeout(() => {
    enqueueSnackbar("", {
      key: NETWORK_NOTIFICATION_KEY,
      persist: true,
      content: (
        <Snackbar
          onClose={() => closeSnackbar(NETWORK_NOTIFICATION_KEY)}
          message={<span className={classes.snackbarMessage}>Network connection was lost.</span>}
          uiActions={[{ label: " Refresh", action: () => window.location.reload() }]}
          dismissible
        />
      ),
    });
  }, 1000);
};

export const showAppVersionNotification = () => {
  if (notifificationStore.get(allowAppVersionAtom)) {
    closeSnackbar(APP_VERSION_NOTIFICATION_KEY);
    enqueueSnackbar("", {
      key: APP_VERSION_NOTIFICATION_KEY,
      persist: true,
      anchorOrigin: { horizontal: "center", vertical: "top" },
      content: (
        <Snackbar
          type="info"
          className={classes.appVersionRoot}
          onClose={() => closeSnackbar(APP_VERSION_NOTIFICATION_KEY)}
          message={(
          <div className={classes.appVersionMessage}>
            <ErrorOutlineOutlinedIcon className={classes.appVersionMessage__icon}/>
            <div className={classes.appVersionMessage__content}>
              <Typography variant="body2">You are running an out-of-date version of Reclaim.</Typography>
              <LinkButton onClick={() => window.location.reload()}>Get the latest</LinkButton>
            </div>
          </div>
          )}
          dismissible
        />
      ),
    });

    notifificationStore.set(allowAppVersionAtom, false);

    // After an hour allow the notification to be shown again
    setTimeout(() => notifificationStore.set(allowAppVersionAtom, true), MILLISECONDS_PER_HOUR);
  }
};
