import { useEffect, useRef } from "react";

export const useErrorIfChanged = <T>(
  thing: T,
  error: Error | string | ((thing: T, lastThing: T) => Error | string) = "value must not change"
): void => {
  const errorRef = useRef(error);
  useEffect(() => {
    const lastThing = thing;
    return () => {
      let { current: error } = errorRef;
      if (thing !== lastThing) {
        if (typeof error === "function") error = error(thing, lastThing);
        if (typeof error === "string") throw new Error(error);
        else throw error;
      }
    };
  }, [thing]);
};
