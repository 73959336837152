import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import { useState, VFC } from "react";
import { useTeamState } from "../../../hooks/atoms/useTeam";
import { useCallbackSafeRef } from "../../../hooks/useCallbackSafeRef";
import { useDateTimeFormatter } from "../../../hooks/useDateTimeFormatter";
import { useUsageData } from "../../../hooks/useUsageData";
import { EDITION_META } from "../../../reclaim-api/team/Team.consts";
import { ReclaimEdition } from "../../../reclaim-api/team/Team.types";
import { CappedTextArea } from "../../CappedTextArea";
import { ConfirmDialog, ConfirmDialogProps } from "../../ConfirmDialog";
import { DowngradeIssues } from "../PurchaseOMatic/PurchaseOMatic.types";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    cancelReason: {
      height: 100,
    },
    cancelReasonInput: {
      background: theme.colors.white,
    },
  }),
  {
    classNamePrefix: "ConfirmSubChangeModal",
  }
);

export type ConfirmSubChangeModalJSSClassKey = keyof ReturnType<typeof useStyles>;
export type ConfirmSubChangeModalConfirmHandler = (cancelReason: string | undefined) => unknown;

export type ConfirmSubChangeModalProps = Omit<ConfirmDialogProps, "classes" | "onConfirm"> & {
  classes?: Partial<ClassNameMap<ConfirmSubChangeModalJSSClassKey>>;
  className?: string;
  ConfirmDialogClasses?: ConfirmDialogProps["classes"];
  downgradeIssue?: DowngradeIssues;
  newEdition: ReclaimEdition;
  onConfirm?: ConfirmSubChangeModalConfirmHandler;
};

export const ConfirmSubChangeModal: VFC<ConfirmSubChangeModalProps> = ({
  className,
  classes: extClasses,
  ConfirmDialogClasses,
  downgradeIssue,
  newEdition,
  onConfirm,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  const { format } = useDateTimeFormatter();
  const { usageData } = useUsageData();
  const { team } = useTeamState();

  /********************/
  /*     useState     */
  /********************/

  const [cancelReason, setCancelReason] = useState("");

  /********************/
  /* useMemo & consts */
  /********************/

  const selectedPlanLabel = EDITION_META[newEdition].label;

  /********************/
  /*    useCallback   */
  /********************/

  const handleConfirm = useCallbackSafeRef(() => onConfirm?.(cancelReason));

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <ConfirmDialog
      className={clsx(classes.root, className)}
      classes={ConfirmDialogClasses}
      title="Before you proceed..."
      {...rest}
      onConfirm={handleConfirm}
      confirmLabel={`Continue with ${selectedPlanLabel}`}
      message={(() => {
        if (!usageData || !team) return <Skeleton width={300} height={200} />;

        const currenPlanLabel = EDITION_META[usageData.currentEdition].label;

        switch (downgradeIssue) {
          case "YouWillEndTrial": {
            return "Are you sure that you want to end your trial?";
          }
          case "YouWillBeOnSoftOverage": {
            return (
              <>
                You have more features enabled than are allowed on the {selectedPlanLabel} plan. Your account may be
                impacted.
              </>
            );
          }
          case "YouCanLoseFeaturesAfterTimeEnd": {
            const subEnding = new Date(team.pricingSummary.subscriptionEnd || "");
            const dt = `${format(subEnding, "DATE_CONTROL_FORMAT_DAY_MODE")}`;

            return (
              <>
                <p>
                  You won't be charged again. You will continue to have access to {currenPlanLabel} features through{" "}
                  {dt} & can opt to keep your subscription anytime before then. At the end of the subscription period
                  your team will be automatically downgraded & lose access to {currenPlanLabel} features.
                </p>
                <p>Tell us about why you want to cancel your plan:</p>
                <CappedTextArea
                  className={classes.cancelReason}
                  classes={{ input: classes.cancelReasonInput }}
                  variant="outlined"
                  value={cancelReason}
                  onChange={setCancelReason}
                  maxLength={500}
                />
              </>
            );
          }
          case "TeamWillBeOnSoftOverage": {
            return (
              <>
                Your team has more features enabled than are allowed on the {selectedPlanLabel} plan. Their accounts may
                be impacted.
              </>
            );
          }
        }
      })()}
    />
  );
};

export type UseConfirmSubChangeModalProps = Omit<ConfirmSubChangeModalProps, "open">;

export const useConfirmSubChangeModal = <P extends Partial<UseConfirmSubChangeModalProps>>(baseProps: P = {} as P) => {
  const { showModal } = useModal();

  return useCallbackSafeRef(
    (props?: Partial<UseConfirmSubChangeModalProps> & Omit<UseConfirmSubChangeModalProps, keyof P>) => {
      const { onClose, onCancel, onConfirm, ...combinedProps } = {
        ...baseProps,
        ...props,
      } as UseConfirmSubChangeModalProps;

      const { hide, destroy } = showModal(ConfirmSubChangeModal, {
        onClose: () => {
          onClose?.();
          hide();
          destroy();
        },
        onCancel: (e) => {
          onCancel?.(e);
          hide();
          destroy();
        },
        onConfirm: async (e) => {
          await onConfirm?.(e);
          hide();
          destroy();
        },
        ...combinedProps,
      });
    }
  );
};
