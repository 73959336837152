import { List, ListItem, Skeleton } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { VFC, createElement } from "react";
import { useMemoizedPromise } from "../../hooks/useMemoizedPromise";
import { useUsageData } from "../../hooks/useUsageData";
import { reclaim } from "../../reclaim-api";
import { SyncTransparency } from "../../reclaim-api/CalendarSyncPolicy";
import { ENTITLEMENT_META } from "../../reclaim-api/team/Team.consts";
import { EntitlementType } from "../../reclaim-api/team/Team.types";
import { getGeneralSettingsUrl, getHabitEditUrl, getSyncUrl, getSyncUrlId } from "../../utils/router";
import { satisfies } from "../../utils/typescript";
import { Link } from "../Link";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    skeleton: {
      width: "60%",
      height: "1.5em",
    },
    list: {
      listStyleType: "circle",
      margin: 0,
      padding: 0,
    },
    listItem: {
      display: "list-item",
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5, 0),
      "& .MuiMenuItem-root": {
        display: "list-item",
        marginLeft: theme.spacing(2),
        padding: 0,
      },
    },
    subList: {
      marginTop: theme.spacing(0.5),
      padding: 0,
    },
    subListItem: {
      padding: 0,
    },
    subLink: {
      paddingRight: theme.spacing(2),
      wordBreak: "break-word",
    },
  }),
  {
    classNamePrefix: "OverageListCustomBlockingSubList",
  }
);

const CUSTOM_BLOCKING_ENTITLEMENTS = satisfies<EntitlementType[]>()([
  "CUSTOM_BLOCKING_DECOMPRESSION",
  "CUSTOM_BLOCKING_HABITS",
  "CUSTOM_BLOCKING_CALENDAR_SYNC",
]);

type CustomBlockingEntitlements = typeof CUSTOM_BLOCKING_ENTITLEMENTS[number];

const OVERAGE_LIST_CUSTOM_BLOCKING_SUB_LIST_META: Record<
  CustomBlockingEntitlements,
  {
    SubComp: VFC<{}>;
  }
> = {
  CUSTOM_BLOCKING_DECOMPRESSION: {
    SubComp: () => (
      <>
        Decompression in&nbsp;<Link href={getGeneralSettingsUrl("decompression")}>General Settings</Link>
      </>
    ),
  },
  CUSTOM_BLOCKING_HABITS: {
    SubComp: () => {
      const [habits] = useMemoizedPromise(() => reclaim.habits.list(), []);
      const classes = useStyles();
      return (
        <>
          Visibility in the following Habits:
          {habits ? (
            <List className={classes.subList}>
              {habits.map(
                ({ alwaysPrivate, id, title, enabled }) =>
                  enabled &&
                  alwaysPrivate && (
                    <ListItem key={id} className={classes.subListItem}>
                      <Link href={getHabitEditUrl(id, "custom-visibility")} className={classes.subLink}>
                        {title}
                      </Link>
                    </ListItem>
                  )
              )}
            </List>
          ) : (
            "..."
          )}
        </>
      );
    },
  },
  CUSTOM_BLOCKING_CALENDAR_SYNC: {
    SubComp: () => {
      const [syncs] = useMemoizedPromise(() => reclaim.calendarSyncPolicies.list(), []);
      const classes = useStyles();
      return (
        <>
          Visibility in the following Calendar Syncs:
          {syncs ? (
            <List className={classes.subList}>
              {syncs.map(
                ({ targetCalendarId, sourceCalendarId, sourceCalendar, targetCalendar, transparency, enabled }) =>
                  enabled &&
                  sourceCalendar &&
                  targetCalendar &&
                  transparency !== SyncTransparency.Default && (
                    <ListItem key={getSyncUrlId(sourceCalendarId, targetCalendarId)} className={classes.subListItem}>
                      <Link
                        className={classes.subLink}
                        href={getSyncUrl(sourceCalendarId, targetCalendarId, "custom-visibility")}
                      >
                        {sourceCalendar.name} → {targetCalendar.name}
                      </Link>
                    </ListItem>
                  )
              )}
            </List>
          ) : (
            "..."
          )}
        </>
      );
    },
  },
};

export type OverageListCustomBlockingSubListJSSClassKey = keyof ReturnType<typeof useStyles>;

export type OverageListCustomBlockingSubListProps = {
  classes?: Partial<ClassNameMap<OverageListCustomBlockingSubListJSSClassKey>>;
  className?: string;
};

export const OverageListCustomBlockingSubList: VFC<OverageListCustomBlockingSubListProps> = ({
  className,
  classes: extClasses,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  const { usageData } = useUsageData();

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div className={clsx(classes.root, className)}>
      {!usageData ? (
        <>
          <Skeleton className={classes.skeleton} />
          <Skeleton className={classes.skeleton} />
          <Skeleton className={classes.skeleton} />
          <Skeleton className={classes.skeleton} />
        </>
      ) : (
        <>
          You have custom blocking enabled in the following places:
          <List className={classes.list}>
            {CUSTOM_BLOCKING_ENTITLEMENTS.map((entitlement) => {
              const actuals = usageData.actuals[entitlement];
              if (!actuals) return;
              const { actualValue, allowedValueForCurrentEdition } = actuals;
              const { comparator } = ENTITLEMENT_META[entitlement];

              return (
                comparator(actualValue as never, allowedValueForCurrentEdition as never) > 0 && (
                  <ListItem key={entitlement} className={classes.listItem}>
                    {createElement(OVERAGE_LIST_CUSTOM_BLOCKING_SUB_LIST_META[entitlement].SubComp, {})}
                  </ListItem>
                )
              );
            })}
          </List>
        </>
      )}
    </div>
  );
};
