import { GradientBackedVideo } from "@components/GradientBackedVideo";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Button, Skeleton, Typography } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import { ShowFnOutput } from "mui-modal-provider/dist/types";
import Image from "next/image";
import { MouseEvent, VFC, createElement, useMemo, useState } from "react";
import { useQuestsState } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { YoutubeVideoPlayerModal } from "../YoutubeVideoPlayerModal";
import { QuestVideoPlayerModalProps } from "./QuestVideoPlayerModal";
import { QuestConfig, QuestGroup, QuestStepProps } from "./quests.types";
import { getQuestConfigFromActiveQuest, isQuestV2 } from "./quests.util";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 340,
      display: "flex",
      flexDirection: "column",
    },
    rootV2: {
      maxWidth: 422,
      display: "flex",
      flexDirection: "column",
    },
    textAndBtns: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    description: {},
    btnCont: {
      paddingTop: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      "&:empty": {
        display: "none",
      },
    },
    stepper: {
      backgroundColor: theme.colors.white,
    },
    backBtn: {
      marginLeft: theme.spacing(-1),
      minWidth: 0,
    },
    nextBtn: {
      marginLeft: "auto",
      marginRight: theme.spacing(-1),
    },
    btn: {
      minWidth: 0,
    },
    imgWrapper: {
      padding: theme.spacing(1, 1, 0),
      width: "100%",
      "& > span": {
        position: "unset !important",
      },
      "& > span > img": {
        borderRadius: theme.shape.borderRadius * 1.5,
        objectFit: "contain",
        width: "100% !important",
        position: "relative !important",
        height: "unset !important",
      },
    },
    notReady: {
      "& > span > img": {
        height: "0 !important",
      },
    },
    svg: {
      borderRadius: theme.shape.borderRadius * 1.5,
      display: "block",
      minWidth: 268,
    },
    skeleton: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    noDisplay: {
      display: "none",
    },
    closeBtn: {
      margin: theme.spacing(-0.75, -1, -0.75, 0),
      minWidth: 0,
    },
    progressAndClose: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
    },
    progressTxt: {
      opacity: 0.5,
    },
    rightArrow: {
      height: 16,
      marginLeft: 4,
      width: 16,
    },
  }),
  {
    classNamePrefix: "QuestGenericPopperContent",
  }
);

export type QuestGenericPopperContentJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestGenericPopperContentProps = QuestStepProps & {
  classes?: Partial<ClassNameMap<QuestGenericPopperContentJSSClassKey>>;
  className?: string;
};

export const QuestGenericPopperContent: VFC<QuestGenericPopperContentProps> = ({
  className,
  classes: extClasses,
  stepConfig,
  onClose,
  onBlur,
  onBack,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const { showModal } = useModal();

  const { activeQuest } = useQuestsState();

  const [imageReady, setImageReady] = useState(false);

  const config = useMemo<QuestConfig<QuestGroup> | undefined>(
    () => (activeQuest ? getQuestConfigFromActiveQuest(activeQuest) : undefined),
    [activeQuest]
  );

  const stepIndex: number | undefined = useMemo(
    () => config?.steps.findIndex((s) => s.id === activeQuest?.step),
    [config, activeQuest]
  );

  const isLastStep: boolean = useMemo(() => {
    if (!!config?.steps.length) return stepIndex === config?.steps.length;
    else return false;
  }, [stepIndex, config?.steps.length]);

  const isFirstStep: boolean = useMemo(() => {
    if (!!config?.steps.length) return stepIndex === 0;
    else return false;
  }, [stepIndex, config?.steps.length]);

  const handleClose = useCallbackSafeRef(() => {
    onClose?.();
  });

  const preventDefault = useCallbackSafeRef((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  });

  const handleVideoClose = useCallbackSafeRef((modal: ShowFnOutput<QuestVideoPlayerModalProps>) => {
    modal.hide();
  });

  const progressDisplay = useMemo<string | undefined>(() => {
    if (!!config?.steps.length && stepIndex !== undefined && stepIndex >= 0) {
      return `${stepIndex + 1} of ${config.steps.length}`;
    }
  }, [config?.steps.length, stepIndex]);

  const handleWatchVideo = useCallbackSafeRef(() => {
    const modal = showModal(YoutubeVideoPlayerModal, {
      onClose: () => handleVideoClose(modal),
      videoId: stepConfig.videoLink,
      videoOptions: stepConfig.videoOptions,
    });
  });

  const getNextLabel = () => {
    if (isLastStep) {
      return "Finish";
    }
    if (stepConfig.popoverConfig?.nextButtonLabel) {
      return (
        <>
          {stepConfig.popoverConfig?.nextButtonLabel}
          <ArrowForwardRoundedIcon className={classes.rightArrow} />
        </>
      );
    }
    return (
      <>
        Next
        <ArrowForwardRoundedIcon className={classes.rightArrow} />
      </>
    );
  };

  return (
    <div
      className={clsx(isQuestV2(activeQuest) ? classes.rootV2 : classes.root, className)}
      onMouseOver={preventDefault}
      onClick={preventDefault}
    >
      {(() => {
        if (!!stepConfig.popoverConfig?.img) {
          return (
            <>
              <Skeleton
                className={clsx(classes.skeleton, { [classes.noDisplay]: imageReady })}
                variant="rectangular"
                height={150}
                width="100%"
              />
              <div
                className={clsx(classes.imgWrapper, { [classes.notReady]: !imageReady })}
                onClick={stepConfig.type === "orb" ? onBlur : undefined}
              >
                <Image layout="fill" src={stepConfig.popoverConfig.img} onLoadingComplete={() => setImageReady(true)} />
              </div>
            </>
          );
        } else if (!!stepConfig.popoverConfig?.svg) {
          return (
            <div className={classes.imgWrapper}>
              {createElement(stepConfig.popoverConfig.svg, { className: classes.svg })}
            </div>
          );
        } else if (!!stepConfig.popoverConfig?.gif) {
          return <GradientBackedVideo>{stepConfig.popoverConfig.gif}</GradientBackedVideo>;
        }
      })()}
      <div className={classes.textAndBtns}>
        <div className={classes.progressAndClose}>
          {!!progressDisplay && <Typography className={classes.progressTxt}>{progressDisplay}</Typography>}
          <Button color="inherit" onClick={onBlur} className={classes.closeBtn} variant="text">
            <CloseRoundedIcon fontSize="small" />
          </Button>
        </div>

        {stepConfig?.popoverConfig?.title && <Typography variant="h4">{stepConfig?.popoverConfig?.title}</Typography>}
        <Typography className={classes.description}>{stepConfig.popoverConfig?.description}</Typography>
        {(!!stepConfig.videoLink || stepConfig.type === "orb") && (
          <div className={classes.btnCont}>
            {stepConfig.type === "orb" && isQuestV2(activeQuest) && !isFirstStep && (
              <Button
                variant="text"
                color="primary"
                className={classes.backBtn}
                onClick={onBack}
                disabled={isFirstStep}
              >
                Back
              </Button>
            )}
            {stepConfig.type === "orb" && (
              <Button variant="text" color="primary" className={classes.nextBtn} onClick={handleClose}>
                {getNextLabel()}
              </Button>
            )}
            {!!stepConfig.videoLink && (
              <Button className={classes.btn} onClick={handleWatchVideo}>
                Watch video
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
