import { TimeZone } from "../types";
import { CurrencyCode } from "./currency.types";
import { Currency } from "@depay/local-currency";
import { BROWSER_LOCALE } from "../consts/date.consts";

export type FormatDollarAmountOptions = {
  hidePenniesForEvenDollars?: boolean;
  currency?: CurrencyCode;
};

/**
 * Formats a dollar amount - adding a dollar sign,
 * ensuring there are exactly 2 decimal places,
 * and adding commas
 * https://stackoverflow.com/questions/5340530/how-to-format-a-dollar
 * @param amount an amount of money as a number
 * @returns a formatted string
 */
export function formatDollarAmount(amount: number, options: FormatDollarAmountOptions = {}): string {
  const { hidePenniesForEvenDollars, currency = "USD" } = options;

  return new Intl.NumberFormat(BROWSER_LOCALE, {
    style: "currency",
    currency,
    maximumFractionDigits: hidePenniesForEvenDollars && amount === Math.floor(amount) ? 0 : 2,
  }).format(amount);
}

export const timeZoneToCurrency = (timeZone: TimeZone): CurrencyCode => Currency.getCode(timeZone);
