import {
  AccordionSectionState,
  AccordionState,
  CombinedPriorityLevel,
  PriorityLevelV14,
  PriorityLevelV16,
  PriorityQueryResourceType,
  ResourceType,
} from "./priority.types";

export const PRIOTIZATION_GETTING_STARTED_VIDEO = "oy11k1V7sHM";

export const PRIORITY_ORDERING: Readonly<PriorityLevelV16>[] = ["P1", "P2", "P3", "P4"];
export const LEGACY_PRIORITY_ORDERING: Readonly<PriorityLevelV14>[] = ["HIGH", "DEFAULT"];
export const PRIORITY_RESOURCES_ORDERING: Readonly<ResourceType>[] = [
  "SCHEDULING_LINKS",
  "ONE_ON_ONES",
  "HABITS",
  "TASKS",
];

export const PRIORITY_TYPE_FILTERS: Readonly<PriorityQueryResourceType[]> = ["scheduling-link", "one-on-one", "habit", "task"];

export const PRIORITY_META: Record<CombinedPriorityLevel, { label: string; empty?: string }> = {
  P1: { label: "Critical", empty: "No critical items" },
  P2: { label: "High priority", empty: "No high priority items" },
  P3: { label: "Medium priority", empty: "No medium priority items" },
  P4: { label: "Low priority", empty: "No low priority items" },
  DEFAULT: { label: "Default" },
  PRIORITIZE: { label: "Prioritize" },
  HIGH: { label: "High" },
} as const;

export const PRIORITY_RESOURCES_META: Record<ResourceType, { label: string; assignment: PriorityQueryResourceType }> = {
  HABITS: { label: "Habits", assignment: "habit" },
  SCHEDULING_LINKS: { label: "Scheduling Links", assignment: "scheduling-link" },
  ONE_ON_ONES: { label: "Smart 1:1s", assignment: "one-on-one" },
  TASKS: { label: "Tasks", assignment: "task" },
} as const;

export const DEFAULT_PRIORITY_ACCORDION_SECTION_STATE: AccordionSectionState = {
  ONE_ON_ONES: true,
  TASKS: true,
  HABITS: true,
  SCHEDULING_LINKS: true,
};

export const DEFAULT_PRIORITY_ACCORDION_STATE: AccordionState = {
  DISABLED: DEFAULT_PRIORITY_ACCORDION_SECTION_STATE,
  P1: DEFAULT_PRIORITY_ACCORDION_SECTION_STATE,
  P2: DEFAULT_PRIORITY_ACCORDION_SECTION_STATE,
  P3: DEFAULT_PRIORITY_ACCORDION_SECTION_STATE,
  P4: DEFAULT_PRIORITY_ACCORDION_SECTION_STATE,
};

export const PRIORITY_COLUMN_WIDTH_MIN = 280;
export const PRIORITY_COLUMN_WIDTH_MAX = 1200;
export const PRIORITY_COLUMN_WIDTH_DEFAULT = 440;
