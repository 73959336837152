import { alpha, lighten, Table, TableBody, TableCell, TableHead, TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { forwardRef, Fragment, MutableRefObject, CSSProperties as ReactCSSProperties, ReactNode, useMemo } from "react";
import { FIRST_OF_SELECTOR_MIXIN, LAST_OF_SELECTOR_MIXIN } from "../../AppTheme";
import { useTeamState } from "../../hooks/atoms/useTeam";
import { useEntitlementTable } from "../../hooks/useEntitlementTable";
import { useUsageData } from "../../hooks/useUsageData";
import { EDITION_META, ENTITLEMENT_META } from "../../reclaim-api/team/Team.consts";
import { EntitlementType, ReclaimEdition } from "../../reclaim-api/team/Team.types";
import { entitlementValueObjectWithOverride } from "../../reclaim-api/team/Team.utils";
import { typedEntries, typedValues } from "../../utils/objects";
import Helptip from "../Helptip";
import { Tooltip } from "../Tooltip";
import {
  ENTITLEMENT_TABLE_ENTITLEMENT_META,
  ENTITLEMENT_TABLE_ENTITLEMENT_ORDER,
  ENTITLEMENT_TABLE_SHORT_LIST_LENGTH,
} from "./EntitlementTable.consts";

const BORDER_RADIUS_MULTIPLIER = 1;

const EVEN_ROW_BG_COLOR = (theme: Theme): string => alpha(theme.colors.bgGrey, 1);
const EVEN_ROW_SELECTED_BG_COLOR = (theme: Theme): string => lighten(theme.palette.primary.light, 0.75);

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    table: {
      display: "flex",
      flexDirection: "column",
      "& thead": {
        "& tr": {
          "&:first-child": {
            ...LAST_OF_SELECTOR_MIXIN(
              "$borderedCell",
              {
                borderTopRightRadius: theme.spacing(BORDER_RADIUS_MULTIPLIER),
              },
              {
                borderTopRightRadius: 0,
              }
            ),

            ...FIRST_OF_SELECTOR_MIXIN("$borderedCell", {
              borderTopLeftRadius: theme.spacing(BORDER_RADIUS_MULTIPLIER),
            }),
          },
        },
      },
      "& tbody": {
        "& tr": {
          "&:last-child": {
            ...LAST_OF_SELECTOR_MIXIN(
              "$borderedCell",
              {
                borderBottomRightRadius: theme.spacing(BORDER_RADIUS_MULTIPLIER),
              },
              {
                borderBottomRightRadius: 0,
              }
            ),

            ...FIRST_OF_SELECTOR_MIXIN("$borderedCell", {
              borderBottomLeftRadius: theme.spacing(BORDER_RADIUS_MULTIPLIER),
            }),
          },

          "&:nth-child(even)": {
            "& $borderedCell": {
              background: EVEN_ROW_BG_COLOR(theme),
              borderLeftColor: EVEN_ROW_BG_COLOR(theme),
              borderRightColor: EVEN_ROW_BG_COLOR(theme),
              borderTopColor: EVEN_ROW_BG_COLOR(theme),
            },

            "& $cellEditionHighlighted": {
              background: EVEN_ROW_SELECTED_BG_COLOR(theme),
              borderLeftColor: EVEN_ROW_SELECTED_BG_COLOR(theme),
              borderRightColor: EVEN_ROW_SELECTED_BG_COLOR(theme),
              borderTopColor: EVEN_ROW_SELECTED_BG_COLOR(theme),
            },
          },
        },
      },
    },
    editionHeaderCell: {
      textAlign: "center",
      padding: theme.spacing(0, 2, 2, 2),

      "&::before": {
        content: "'placeholder'",
        visibility: "hidden",
        textTransform: "uppercase",
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.spacing(0, 0, 0.75, 0.75),
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 12,
        letterSpacing: 0.5,
        lineHeight: 1,
        marginBottom: theme.spacing(),
      },
    },
    editionHeaderText: {
      textTransform: "uppercase",
      color: "#181D25",
      opacity: 0.6,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
    },
    editionHeaderTextLegacy: {
      "&::after": {
        ...theme.typography.caption,
        color: "#181D25",
        content: "'Legacy'",
        display: "inline-block",
        letterSpacing: 0.5,
        marginLeft: theme.spacing(0.5),
        opacity: 0.85,
        textTransform: "uppercase",
      },
    },
    editionHeaderCellCurrent: {
      "&::before": {
        content: "'Current'",
        visibility: "visible",
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[300],
      },
    },
    editionHeaderCellHighlighted: {
      "&::before": {
        content: "'Recommended'",
        visibility: "visible",
        color: theme.palette.primary.main,
        backgroundColor: "rgba(85,98,235,.2)",
      },
    },
    entitlementHeaderCell: {
      "&&": {
        cursor: "default",
        fontWeight: theme.typography.fontWeightMedium,
        borderBottom: "none",
        overflow: "hidden",
        alignItems: "flex-end",
        textAlign: "right",
        padding: theme.spacing(0, 1, 0, 0),
        flex: "0 0 0",
      },
    },
    cellCallout: {
      "&&": {
        padding: theme.spacing(0, 1, 0, 0),
        flex: "0 0 22px",
        boxSizing: "content-box",
      },
    },
    entitlementHeaderCellHighlighted: {},
    entitlementHeaderCellWithEnts: {
      "&&": {
        flex: "1 1 100%",
      },
    },
    row: {
      display: "flex",
      justifyContent: "stretch",
      alignItems: "stretch",
    },
    headerRow: {},
    bodyRow: {},
    cell: {
      display: "flex",
      flex: "1 1 100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: -1,
      marginLeft: -1,
      border: "1px solid transparent",
      overflow: "hidden",
    },
    gapCell: {
      flex: "0 0 auto",
      width: theme.spacing(1),
      padding: 0,
    },
    borderedCell: {
      borderColor: lighten(theme.palette.primary.light, 0.75),
      borderTopColor: EVEN_ROW_BG_COLOR(theme),
    },
    bodyCell: {
      cursor: "default",
      lineHeight: 1.3,
      textAlign: "center",
      padding: theme.spacing(1.5, 2),
    },
    entitlementValue: {
      whiteSpace: "pre-line",
    },
    entitlementUsage: {
      "&::before": {
        background: theme.palette.primary.main,
        borderRadius: "50%",
        content: "''",
        display: "inline-block",
        height: theme.spacing(),
        marginRight: theme.spacing(0.75),
        width: theme.spacing(),
      },
    },
    cellEntitlementHighlighted: {},
    cellEditionHighlighted: {},
  }),
  {
    classNamePrefix: "EntitlementTable",
  }
);

export type EntitlementTableJSSClassKey = keyof ReturnType<typeof useStyles>;
export type EntitlementTableHeaderRenderer = (data: { edition: ReclaimEdition; hasOverage?: boolean }) => ReactNode;

export type EntitlementTableProps = {
  classes?: Partial<ClassNameMap<EntitlementTableJSSClassKey>>;
  className?: string;
  showAll?: boolean;
  recommendedEdition?: ReclaimEdition;
  editions: readonly ReclaimEdition[];
  showEntitlementHeaders?: boolean;
  highlightedEntitlement?: EntitlementType;
  style?: ReactCSSProperties;
  tableRef?: MutableRefObject<HTMLTableElement | null>;
  renderHeaderContent?: EntitlementTableHeaderRenderer;
};

export const EntitlementTable = forwardRef<HTMLDivElement, EntitlementTableProps>(
  (
    {
      className,
      classes: extClasses,
      showAll,
      recommendedEdition,
      editions,
      showEntitlementHeaders,
      highlightedEntitlement,
      style,
      tableRef,
      renderHeaderContent,
    },
    ref
  ) => {
    const classes = useStyles({
      classes: extClasses,
    });

    /********************/
    /*   custom hooks   */
    /********************/

    const { team } = useTeamState();
    const { entitlementTable } = useEntitlementTable();
    const { usageData } = useUsageData();

    /********************/
    /*     useState     */
    /********************/

    /********************/
    /* useMemo & consts */
    /********************/

    const entitlements = useMemo(() => {
      const ents = [...ENTITLEMENT_TABLE_ENTITLEMENT_ORDER];
      if (!showAll) ents.splice(ENTITLEMENT_TABLE_SHORT_LIST_LENGTH);
      return ents;
    }, [showAll]);

    const loading = !entitlementTable || !team;

    const overageMap = useMemo<Partial<Record<ReclaimEdition, boolean>>>(() => {
      let overageMap: Partial<Record<ReclaimEdition, boolean>> = {};

      entitlements.forEach((entitlement) => {
        editions.forEach((edition) => {
          const entActuals = usageData?.actuals[entitlement];
          const entObj = entitlementValueObjectWithOverride(
            entitlement,
            edition,
            entitlementTable?.[edition][entitlement]
          );

          if (entActuals && entObj && !overageMap[edition]) {
            overageMap[edition] =
              ENTITLEMENT_META[entitlement].comparator(entActuals.actualValue as never, entObj.value as never) > 0;
          }
        });
      });

      return overageMap;
    }, [editions, entitlements, usageData?.actuals, entitlementTable]);

    const calloutContentByEntitlement = useMemo(
      () =>
        usageData
          ? typedEntries(ENTITLEMENT_TABLE_ENTITLEMENT_META).reduce(
              (contentMap, [entitlement, { entitlementCallout }]) => {
                if (entitlementCallout)
                  contentMap[entitlement] = entitlementCallout({
                    currentEdition: usageData.currentEdition,
                    ...usageData.actuals[entitlement],
                  } as never);
                return contentMap;
              },
              {} as { [E in EntitlementType]?: ReactNode }
            )
          : {},
      [usageData]
    );

    const showCalloutColumn = useMemo(
      () => !!typedValues(calloutContentByEntitlement).find((content) => !!content),
      [calloutContentByEntitlement]
    );

    /********************/
    /*    useCallback   */
    /********************/

    /********************/
    /*    useEffects    */
    /********************/

    /********************/
    /*       JSX        */
    /********************/

    return (
      <div ref={ref} className={clsx(classes.root, className)} style={style}>
        {loading ? (
          <Skeleton width="100%" height={500} />
        ) : (
          <>
            <Table className={classes.table} ref={tableRef}>
              <TableHead>
                <TableRow className={clsx(classes.row, classes.headerRow)}>
                  <TableCell
                    className={clsx(classes.cell, classes.entitlementHeaderCell, {
                      [classes.entitlementHeaderCellWithEnts]: showEntitlementHeaders,
                    })}
                  />
                  {showCalloutColumn && <TableCell className={clsx(classes.cell, classes.cellCallout)} />}
                  {editions.map((edition) => {
                    const { label, isLegacy } = EDITION_META[edition];
                    const isRecommendedEdition = recommendedEdition === edition;
                    const isCurrentEdition = usageData?.currentEdition === edition;

                    return (
                      <Fragment key={edition}>
                        <TableCell
                          className={clsx(classes.cell, classes.borderedCell, classes.editionHeaderCell, {
                            [classes.editionHeaderCellHighlighted]: isRecommendedEdition,
                            [classes.editionHeaderCellCurrent]: isCurrentEdition,
                          })}
                        >
                          <div
                            className={clsx(classes.editionHeaderText, {
                              [classes.editionHeaderTextLegacy]: isLegacy,
                            })}
                          >
                            {label}
                          </div>
                          {renderHeaderContent?.({ edition, hasOverage: !!overageMap[edition] })}
                        </TableCell>
                        {isLegacy && <TableCell className={clsx(classes.cell, classes.gapCell)} />}
                      </Fragment>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {entitlements.map((entitlement) => {
                  const isHighlightedEntitlement = highlightedEntitlement === entitlement;
                  const { valueRenderer, actualRenderer, microValueRenderer } =
                    ENTITLEMENT_TABLE_ENTITLEMENT_META[entitlement];
                  const {
                    label: entitlementLabel,
                    comparator: entitlementComparator,
                    description: entitlementDescription,
                  } = ENTITLEMENT_META[entitlement];
                  const calloutContent = calloutContentByEntitlement[entitlement];

                  return (
                    <TableRow key={entitlement} className={clsx(classes.row, classes.bodyRow)}>
                      <Tooltip title={entitlementDescription || ""} disabled={!entitlementDescription}>
                        <TableCell
                          className={clsx(classes.cell, classes.entitlementHeaderCell, {
                            [classes.entitlementHeaderCellHighlighted]: isHighlightedEntitlement,
                            [classes.entitlementHeaderCellWithEnts]: showEntitlementHeaders,
                          })}
                        >
                          {entitlementLabel}
                        </TableCell>
                      </Tooltip>
                      {showCalloutColumn && (
                        <TableCell className={clsx(classes.cell, classes.cellCallout)}>
                          {!!calloutContent && <Helptip title={calloutContent} interactive variant="info" />}
                        </TableCell>
                      )}

                      {editions.map((edition) => {
                        const { isLegacy } = EDITION_META[edition];
                        const entValueObj = entitlementValueObjectWithOverride(
                          entitlement,
                          edition,
                          entitlementTable[edition][entitlement]
                        );

                        const isHighlightedEdition = recommendedEdition === edition;
                        const entActualValue = usageData?.actuals[entitlement]?.actualValue;
                        const isRecommended = isHighlightedEdition;
                        const recommendedOverage =
                          !!usageData && isRecommended
                            ? entitlementComparator(
                                entActualValue as never,
                                usageData.terminalActuals[entitlement]?.allowedValueForCurrentEdition as never
                              ) > 0
                            : false;

                        const title = (actualRenderer &&
                          entActualValue &&
                          isRecommended &&
                          actualRenderer?.(entActualValue as never)) || <></>;

                        return (
                          <Fragment key={edition}>
                            <TableCell
                              className={clsx(classes.cell, classes.borderedCell, classes.bodyCell, {
                                [classes.cellEditionHighlighted]: isHighlightedEdition,
                                [classes.cellEntitlementHighlighted]: isHighlightedEntitlement,
                              })}
                            >
                              <Tooltip
                                disabled={!actualRenderer || !entActualValue || !isRecommended || !recommendedOverage}
                                title={title}
                              >
                                <div
                                  className={clsx(classes.entitlementValue, {
                                    [classes.entitlementUsage]:
                                      actualRenderer && entActualValue && isRecommended && recommendedOverage,
                                  })}
                                >
                                  {showEntitlementHeaders && !!microValueRenderer
                                    ? microValueRenderer?.(entValueObj as never)
                                    : valueRenderer(entValueObj as never)}
                                </div>
                              </Tooltip>
                            </TableCell>
                            {isLegacy && <TableCell className={clsx(classes.cell, classes.gapCell)} />}
                          </Fragment>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </div>
    );
  }
);
