import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import HelpIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import { ReactElement } from "react";
import moduleClasses from "./Helptip.module.scss";
import { Tooltip, TooltipProps } from "./Tooltip";
import { Warning } from "@mui/icons-material";

export type HelptipCSSClassKey = keyof typeof moduleClasses;

export type HelptipVariant = "help" | "info" | "beta" | "assist" | "pro" | "new" | "warning" | "error";
export type HelptipColor = "grey" | "primary" | "secondary" | "error" | "auto" | "warn" | "success";

const VARIANT_COLORS: { [V in HelptipVariant]?: Exclude<HelptipColor, "auto"> } = {
  error: "error",
  warning: "warn",
};

type HelptipProps<WITH_SPAN extends boolean> = Omit<TooltipProps<WITH_SPAN>, "children" | "classes"> & {
  classes?: SCSSClasses<HelptipCSSClassKey>;
  variant?: HelptipVariant;
  color?: HelptipColor;
};

export const Helptip = <WITH_SPAN extends boolean>({
  variant = "help",
  placement = "right",
  color = "auto",
  title,
  className,
  classes: extClasses,
  ...rest
}: HelptipProps<WITH_SPAN>): ReactElement<HelptipProps<WITH_SPAN>> | null => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  color = color === "auto" ? VARIANT_COLORS[variant] || "grey" : color;

  function renderIcon() {
    switch (variant) {
      case "assist":
        return (
          <span role="img" aria-label="Reclaim Assist feature" className={clsx(classes.icon, classes[`icon--emoji`])}>
            ⭐️
          </span>
        );
      case "beta":
        return (
          <Typography component="sup" className={clsx(classes.icon, classes[`icon--superscript`])}>
            β
          </Typography>
        );
      case "info":
        return <InfoIcon fontSize="small" className={classes.icon} />;
      case "new":
        return <NewReleasesIcon className={classes.icon} />;
      case "error":
        return <ErrorIcon className={classes.icon} />;
      case "warning":
        return <Warning color="warning" className={classes.icon} />;
      case "help":
      default:
        return <HelpIcon className={classes.icon} color="primary" />;
    }
  }

  return !title ? null : (
    <Tooltip
      className={clsx(classes.root, className, classes[`root--${color}`])}
      classes={{
        tooltip: classes.tooltip,
      }}
      tabIndex={-1}
      placement={placement}
      title={title}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      {...rest}
    >
      <IconButton size="small">{renderIcon()}</IconButton>
    </Tooltip>
  );
};

export default Helptip;
