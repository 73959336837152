import { MenuItem, MenuItemProps } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { forwardRef, ReactNode } from "react";
import { Tooltip, TooltipProps } from "./Tooltip";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
    },
    wrapper: {
      padding: theme.spacing(0.75, 2),
      flex: "1 1 100%",
      display: "flex",
    },
  }),
  {
    classNamePrefix: "MenuItemWithTooltip",
  }
);

export type MenuItemWithTooltipJSSClassKey = keyof ReturnType<typeof useStyles>;

export type MenuItemWithTooltipProps = Omit<MenuItemProps, "classes" | "title"> & {
  classes?: Partial<ClassNameMap<MenuItemWithTooltipJSSClassKey>>;
  MenuItemClasses?: MenuItemProps["classes"];
  TooltipProps?: Partial<Omit<TooltipProps<false>, "withSpan">>;
  title?: ReactNode;
};

export const MenuItemWithTooltip = forwardRef<HTMLLIElement, MenuItemWithTooltipProps>(
  ({ className, classes: extClasses, MenuItemClasses, title, children, TooltipProps, ...rest }, ref) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const content = <div className={classes.wrapper}>{children}</div>;

    return (
      <MenuItem className={clsx(classes.root, className)} classes={MenuItemClasses} ref={ref} {...(rest as {})}>
        {title ? (
          <Tooltip title={title} placement="left" {...TooltipProps}>
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </MenuItem>
    );
  }
);
