import { differenceInDays } from "date-fns";
import { useMemo } from "react";
import { useUserContext } from "../context/UserContext";

export const isSchedulerV16 = (version?: number) => !!version && version >= 16;

export type UseV16UserReturnType = {
  isV16: boolean;
  canMigrate: boolean;
};

// TODO (RAI-10333)
export const useV16User = () => {
  const [{ user }] = useUserContext();

  const canMigrate = useMemo(
    () => (user?.created ? Math.abs(differenceInDays(user.created, new Date())) >= 2 : true),
    [user?.created]
  );

  return {
    isV16: isSchedulerV16(user?.features.scheduler),
    canMigrate,
  };
};
