import { useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import { forwardRef, useState } from "react";
import { useTeamState } from "../../../hooks/atoms/useTeam";
import { useCallbackSafeRef } from "../../../hooks/useCallbackSafeRef";
import { EntitlementType, ReclaimEdition } from "../../../reclaim-api/team/Team.types";
import { Modal, ModalProps } from "../../modal/Modal";
import { ModalCloseButton } from "../../modal/ModalCloseButton";
import { PurchaseOMatic } from "../PurchaseOMatic/PurchaseOMatic";
import { useSubscriptionModal } from "../SubscriptionModal";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    modalPaper: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    modalPaperMobile: {
      width: "100vw",
      height: "100vh",
      padding: 0,
      margin: 0,
      maxHeight: "none",
      borderRadius: 0,
      overflow: "hidden",
    },
    oMatic: {
      flex: "1 1 auto",
    },
    purchaseOMaticMobile: {
      height: "100%",
    },
  }),
  {
    classNamePrefix: "PurchaseModal",
  }
);

export type PurchaseModalJSSClassKey = keyof ReturnType<typeof useStyles>;

export type PurchaseModalProps = Omit<ModalProps, "classes"> & {
  classes?: Partial<ClassNameMap<PurchaseModalJSSClassKey>>;
  ModalClasses?: ModalProps["classes"];
  closeModal(): void;
  highlightedEntitlement?: EntitlementType;
};

export const PurchaseModal = forwardRef<HTMLDivElement, PurchaseModalProps>(
  ({ className, classes: extClasses, ModalClasses, closeModal, highlightedEntitlement, ...rest }, ref) => {
    const classes = useStyles({
      classes: extClasses,
    });

    /********************/
    /*   custom hooks   */
    /********************/

    const theme = useTheme();
    const mobileMode = useMediaQuery(theme.breakpoints.down("sm"));

    /********************/
    /*     useState     */
    /********************/

    const [editions, setEditions] = useState<ReclaimEdition[]>([]);

    /********************/
    /* useMemo & consts */
    /********************/

    /********************/
    /*    useCallback   */
    /********************/

    const handleEditionsChange = useCallbackSafeRef((editions: ReclaimEdition[]) => setEditions(editions));

    /********************/
    /*    useEffects    */
    /********************/

    /********************/
    /*       JSX        */
    /********************/

    return (
      <Modal
        className={clsx(className, classes.root)}
        ref={ref}
        fullWidth
        maxWidth={editions.length <= 4 ? "md" : "lg"}
        classes={{
          ...ModalClasses,
          paper: clsx(classes.modalPaper, ModalClasses?.paper, { [classes.modalPaperMobile]: mobileMode }),
        }}
        {...rest}
      >
        <ModalCloseButton onClose={closeModal} />

        <PurchaseOMatic
          className={clsx(classes.oMatic, { [classes.purchaseOMaticMobile]: mobileMode })}
          highlightedEntitlement={highlightedEntitlement}
          mobileMode={mobileMode}
          onAvailableEditionsChange={handleEditionsChange}
          onClose={closeModal}
        />
      </Modal>
    );
  }
);

export const usePurchaseModal = () => {
  const { team } = useTeamState();
  const showSubscriptionModal = useSubscriptionModal(team.members);

  // This modal was having trouble with
  // auto-destroy when launched from a
  // tooltip, so we'll do it manually.
  const { showModal } = useModal({ disableAutoDestroy: true });

  return useCallbackSafeRef((props?: Partial<Omit<PurchaseModalProps, "closeModal" | "ref">>) => {
    if (team.newPricing) {
      const { hide, destroy } = showModal(PurchaseModal, {
        closeModal: () => {
          hide();
          destroy();
        },
        ...props,
      });
    } else showSubscriptionModal();
  });
};
