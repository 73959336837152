import { useCallbackSafeRef } from "@hooks/useCallbackSafeRef";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Typography } from "@mui/material";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import { SnackbarContent } from "notistack";
import { forwardRef, ReactNode, useMemo } from "react";
import { UIAction } from "../../types/uiActions";
import { AsyncButton } from "../forms/AsyncButton";
import { UIActionButton } from "../UIActionButton";
import moduleClasses from "./Snackbar.module.scss";

function useResolvedFactory<T>(factory: SnackbarPropFactory<T>, helpers: SnackbarPropFactoryHelpers): T {
  return useMemo(() => {
    if (typeof factory === "function") return (factory as (helpers: SnackbarPropFactoryHelpers) => T)(helpers);
    else return factory;
  }, [factory, helpers]);
}

export type SnackbarCSSClassKey = keyof typeof moduleClasses;

export type SnackbarPropFactoryHelpers = Readonly<{
  readonly close: () => void;
}>;

export type SnackbarPropFactory<T> = T | ((helpers: SnackbarPropFactoryHelpers) => T);
export type SnackbarType = "default" | "info" | "error" | "warning" | "success";

export type SnackbarOptionsProps = {
  type?: SnackbarType;
  header?: SnackbarPropFactory<ReactNode>;
  message: SnackbarPropFactory<ReactNode>;
  uiActions?: SnackbarPropFactory<UIAction[]>;
  dismissible?: boolean;
  onView?: () => void;
  onUndo?: () => Promise<void> | void;
};

export type SnackbarProps = SnackbarOptionsProps & {
  classes?: SCSSClasses<SnackbarCSSClassKey>;
  className?: string;
  onClose(): void;
};

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  (
    {
      className,
      classes: extClasses,
      type = "default",
      message,
      dismissible,
      header,
      uiActions,
      onUndo,
      onView,
      onClose,
    },
    ref
  ) => {
    const classes = mergeClassNames(moduleClasses, extClasses || {});

    /********************/
    /*   custom hooks   */
    /********************/

    /********************/
    /*     useState     */
    /********************/

    /********************/
    /* useMemo & consts */
    /********************/

    const helpers = useMemo<SnackbarPropFactoryHelpers>(() => Object.freeze({ close: onClose }), [onClose]);

    header = useResolvedFactory(header, helpers);
    message = useResolvedFactory(message, helpers);
    uiActions = useResolvedFactory(uiActions, helpers);

    /********************/
    /*    useCallback   */
    /********************/

    const handleUndoClick = useCallbackSafeRef(async () => {
      await onUndo?.();
      await onClose();
    });

    /********************/
    /*    useEffects    */
    /********************/

    /********************/
    /*       JSX        */
    /********************/

    return (
      <SnackbarContent
        ref={ref}
        className={clsx(classes.root, className, classes[`colors--${type}`])}
      >
        {!!dismissible && (
          <IconButton aria-label="close" className={classes.close} onClick={onClose} size="large">
            <CloseIcon className={clsx(classes.closeIcon, classes[`colors--${type}`])} />
          </IconButton>
        )}

        <div className={clsx(classes.content, { [classes["content--dismissible"]]: !!dismissible })}>
          {header ?? <Typography variant="h3">{header}</Typography>}
          <div className={classes.message}>{message}</div>
        </div>

        <div className={classes.actionsWrap}>
          {!!onUndo && (
            <AsyncButton
              className={clsx(classes.actionBtn, classes[`colors--${type}`])}
              onClick={handleUndoClick}
              size="small"
              color="primary"
            >
              Undo
            </AsyncButton>
          )}

          {!!onView && (
            <Button className={clsx(classes.actionBtn, classes[`colors--${type}`])} onClick={onUndo} size="small" color="primary">
              View
            </Button>
          )}

          {(uiActions || []).map((uiAction, i) => (
            <UIActionButton key={i} className={clsx(classes.actionBtn, classes[`colors--${type}`])} uiAction={uiAction} />
          ))}
        </div>
      </SnackbarContent>
    );
  }
);
