export const calculateCost = (() => {
  interface BreakpointPeriod {
    pro: number;
    team: number;
  }

  interface Breakpoint {
    annually: BreakpointPeriod;
    monthly: BreakpointPeriod;
  }

  const ADDITIONAL_ANNUAL_DISCOUNT = 0.2;

  const BREAKPOINTS: Readonly<Record<number, Breakpoint>> = Object.freeze({
    1: {
      annually: { pro: 6.5, team: 9.5 },
      monthly: { pro: 7.8, team: 11.4 },
    },
    5: {
      annually: { pro: 7.47, team: 10.93 },
      monthly: { pro: 8.97, team: 13.11 },
    },
    10: {
      annually: { pro: 8.59, team: 12.56 },
      monthly: { pro: 10.31, team: 15.08 },
    },
    25: {
      annually: { pro: 9.88, team: 14.45 },
      monthly: { pro: 11.86, team: 17.34 },
    },
    50: {
      annually: { pro: 8.4, team: 12.28 },
      monthly: { pro: 10.08, team: 14.74 },
    },
    100: {
      annually: { pro: 7.14, team: 10.44 },
      monthly: { pro: 8.57, team: 12.53 },
    },
    1000: {
      annually: { pro: 6.07, team: 8.87 },
      monthly: { pro: 7.28, team: 10.65 },
    },
  });

  const LOWER_BOUNDS = Object.keys(BREAKPOINTS).map((k) => parseInt(k));

  /**
   * Dont type this so that it can easily stay in sync with website calc until we having a
   * better plan for where to put this. -SS
   *
   * I've been trying to figure out a way to force Next's pipeline to output an individual
   * script file without a page.  Would be nice to just host this from Vercel and ref it
   * in Webflow.  Having said that you can just dump the code here: https://www.typescriptlang.org/play
   * - SG
   */
  return (seats: number, period: "monthly" | "annually") => {
    if (typeof seats !== "number" || seats < 1)
      throw new RangeError(`seats must be a number, 1 or greater (received ${seats})`);
    if (period !== "annually" && period !== "monthly")
      throw new Error(`period must be either "monthly" or "annually" (received "${period}")`);

    const lowerBoundIndex = LOWER_BOUNDS.findIndex((min, i) => LOWER_BOUNDS[i + 1] > seats || !LOWER_BOUNDS[i + 1]);
    const lowerBound = LOWER_BOUNDS[lowerBoundIndex];
    const upperBound = LOWER_BOUNDS[lowerBoundIndex + 1] ? LOWER_BOUNDS[lowerBoundIndex + 1] - 1 : Infinity;
    const seatsInRange = seats - (lowerBound - 1);

    const { pro: proRangePrice, team: teamRangePrice } = BREAKPOINTS[lowerBound][period];

    let proPrice = proRangePrice * seatsInRange;
    let teamPrice = teamRangePrice * seatsInRange;

    for (let i = 0; i < lowerBoundIndex; i++) {
      const lowerBoundForRange = LOWER_BOUNDS[i];
      const upperBoundForRange = LOWER_BOUNDS[i + 1];
      const breakpointForRange = BREAKPOINTS[lowerBoundForRange][period];
      proPrice += (upperBoundForRange - lowerBoundForRange) * breakpointForRange.pro;
      teamPrice += (upperBoundForRange - lowerBoundForRange) * breakpointForRange.team;
    }

    const discount = period === "annually" ? ADDITIONAL_ANNUAL_DISCOUNT : 0;

    const proPricePerUser = proPrice / seats;
    const teamPricePerUser = teamPrice / seats;

    const proPricePerUserDiscountedAmount = proPricePerUser * discount;
    const teamPricePerUserDiscountedAmount = teamPricePerUser * discount;
    const proPriceDiscountedAmount = proPrice * discount;
    const teamPriceDiscountedAmount = teamPrice * discount;

    const proPricePerUserWithDiscount = proPricePerUser - proPricePerUserDiscountedAmount;
    const teamPricePerUserWithDiscount = teamPricePerUser - teamPricePerUserDiscountedAmount;
    const proPriceWithDiscount = proPrice - proPriceDiscountedAmount;
    const teamPriceWithDiscount = teamPrice - teamPriceDiscountedAmount;

    return {
      proPricePerUser,
      teamPricePerUser,
      proPrice,
      teamPrice,
      proPricePerUserDiscountedAmount,
      teamPricePerUserDiscountedAmount,
      proPriceDiscountedAmount,
      teamPriceDiscountedAmount,
      proPricePerUserWithDiscount,
      teamPricePerUserWithDiscount,
      proPriceWithDiscount,
      teamPriceWithDiscount,
      lowerBound,
      upperBound,
      seatsInRange,
      discount,
    };
  };
})();
