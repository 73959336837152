import { PopperPlacementType, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import {
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuestPopperState, useQuestsActions, useQuestsState } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { QuestOrb, QuestOrbProps } from "./QuestOrb";
import { ActiveQuest, GenericQuestStepConfig, QuestConfig, QuestGroup } from "./quests.types";
import { getNextQuestStep, getPreviousQuestStep, getQuestsStepConfig, isQuestV2 } from "./quests.util";
import { QuestStepPopper } from "./QuestStepPopper";
import { QUESTS_CONFIG } from "./quests.util";
import { QuestStepModal } from "./QuestStepModal";
import { useNotifications } from "@hooks/useNotifications";
import { useConfettiCannon } from "@hooks/useConfettiCannon";
import { useOurRouter } from "@hooks/useOurRouter";
import { useUserContext } from "../../context/UserContext";
import { useUsageData } from "../../hooks/useUsageData";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
    },
    fullWidth: {
      width: "100%",
    },
    modal: {},
  }),
  {
    classNamePrefix: "QuestStepWrapper",
  }
);

export type QuestStepWrapperJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestStepWrapperProps<QG extends QuestGroup> = {
  classes?: Partial<ClassNameMap<QuestStepWrapperJSSClassKey>>;
  className?: string;
  config: ActiveQuest<QG> | ActiveQuest<QG>[];
  hidden?: boolean;
  hideOrb?: boolean;
  disabled?: boolean;
  QuestOrbProps?: QuestOrbProps;
  popperPlacement?: PopperPlacementType | "center";
  fullWidth?: boolean;
  children?: ReactNode;
  arrow?: boolean;
  center?: boolean;
  onShow?: () => void;
  orbOnly?: boolean;
};

export const QuestStepWrapper = forwardRef<HTMLSpanElement, QuestStepWrapperProps<QuestGroup>>(
  (
    {
      className,
      classes: extClasses,
      config,
      hidden,
      hideOrb,
      disabled,
      QuestOrbProps,
      popperPlacement,
      fullWidth,
      children,
      arrow,
      center,
      onShow,
      orbOnly,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const orbRef = useRef<HTMLDivElement | null>(null);
    const innerRef = useRef<HTMLSpanElement>(null);
    const [{ user }] = useUserContext();
    const { usageData } = useUsageData();

    const { fireConfettiCannon } = useConfettiCannon();

    const { sendNotification } = useNotifications();
    const router = useOurRouter();

    useImperativeHandle(ref, () => innerRef.current as HTMLSpanElement);

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("sm"));

    const { activeQuest } = useQuestsState();
    const activeConfig: ActiveQuest<QuestGroup> | undefined = useMemo<ActiveQuest<QuestGroup> | undefined>(() => {
      if (activeQuest) {
        const cfg = Array.isArray(config) ? config : [config];
        return cfg.find(
          ({ group, quest, step }) =>
            activeQuest.group === group && activeQuest.quest === quest && activeQuest.step === step
        );
      }
    }, [config, activeQuest]);

    const { popperActive } = useQuestPopperState(activeConfig);
    const { onStepComplete, onBack, setActiveQuest, setPopperActive, setPopperBackgrounded } =
      useQuestsActions(activeConfig);
    const [popperWasDisplayed, setPopperWasDisplayed] = useState(false);

    const stepConfig = useMemo<GenericQuestStepConfig | undefined>(
      () => (!activeConfig || !activeQuest ? undefined : getQuestsStepConfig(activeQuest)),
      [activeQuest, activeConfig]
    );

    const lastStepId = useMemo<string | undefined>(() => {
      // find the id of the last step of the active quest
      if (!!activeConfig) {
        return (QUESTS_CONFIG[activeConfig.group].quests as QuestConfig<QuestGroup>[])
          .find((quest) => quest.id === activeConfig.quest)
          ?.steps?.slice(-1)
          .pop()?.id;
      } else return undefined;
    }, [activeConfig]);

    const orbActive = !!(activeConfig && !hidden && stepConfig) && !!stepConfig;

    const handleClose = useCallbackSafeRef(() => {
      if (!activeConfig || !stepConfig) return;
      setPopperActive(false);

      if (["orb", "confetti"].includes(stepConfig.type)) {
        setPopperWasDisplayed(false);
        setTimeout(() => onStepComplete(user, usageData), 100);
      }

      // If they close the last step end the quest
      if (lastStepId === activeConfig.step) setActiveQuest(undefined);
    });

    const handleBlur = useCallbackSafeRef(() => {
      if (!activeConfig || !stepConfig) return;

      // If they close the last step end the quest
      if (lastStepId === activeConfig.step) setActiveQuest(undefined);

      setPopperActive(false);
      setPopperBackgrounded(true);
    });

    const handleBack = useCallbackSafeRef(() => {
      if (!activeConfig || !stepConfig) return;
      setPopperActive(false);

      if (stepConfig.type === "orb") {
        setPopperWasDisplayed(false);
        setImmediate(() => onBack());
      }
    });

    const showStepPopover = useCallbackSafeRef(() => {
      if (!stepConfig?.popoverConfig || popperActive) return;

      onShow?.();
      setPopperActive(true);
      setPopperBackgrounded(false);
      setPopperWasDisplayed(true);
    });

    const handleClick = useCallbackSafeRef<MouseEventHandler>((e) => {
      if (!stepConfig || !activeConfig || disabled) return;

      if (xs && stepConfig.type !== "action") {
        showStepPopover();
      } else if (stepConfig.type === "action") {
        onStepComplete(user, usageData);
      }
    });

    /**
     * Show popover if not shown yet and orb becomes active
     */
    useEffect(() => {
      let timer;

      if (orbActive && !popperWasDisplayed) {
        timer = setTimeout(() => {
          showStepPopover();
        }, 750);
      }

      return () => {
        clearTimeout(timer);
      };
    }, [orbActive, popperWasDisplayed, showStepPopover]);

    // Cancel on navigating away
    useEffect(() => {
      if (!activeConfig || !activeQuest || !isQuestV2(activeQuest)) return;

      const routeChangeHandler = (nextPath: string) => {
        // Bail if not actually changing route
        const nextBasePath = nextPath.split("?")[0];
        if (nextBasePath === window.location.pathname) return;

        const nextStep = getNextQuestStep(activeQuest, user, usageData);
        const curStep = getQuestsStepConfig(activeConfig);
        const prevStep = getPreviousQuestStep(activeQuest);

        // If we're going to a whitelisted route, bail
        if (curStep?.allowedRoutes && curStep?.allowedRoutes.test(nextPath)) {
          onStepComplete(user, usageData);
          return;
        }

        // If navigating away and next quest path is not where your going, kill quest
        if (nextBasePath !== nextStep?.redirect && nextBasePath != prevStep?.redirectBack) {
          setActiveQuest(undefined);
        }
      };

      router.events.on("routeChangeStart", routeChangeHandler);
      return () => {
        router.events.off("routeChangeStart", routeChangeHandler);
      };
    }, [router, activeConfig, activeQuest, setActiveQuest]);

    useEffect(() => {
      if (stepConfig?.type === "confetti") {
        if (stepConfig?.popoverConfig?.description) {
          sendNotification(stepConfig?.popoverConfig?.description);
        }
        setTimeout(() => {
          handleClose();
        }, 3000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepConfig, handleClose, sendNotification]);

    useEffect(() => {
      if (stepConfig?.type === "confetti") {
        fireConfettiCannon();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepConfig]);

    if (stepConfig?.type === "confetti") {
      return null;
    }

    return (
      <>
        <span
          ref={innerRef}
          className={clsx(classes.root, className, { [classes.fullWidth]: fullWidth })}
          onClick={handleClick}
          {...rest}
        >
          {children}
          {orbActive && !hideOrb && stepConfig.type !== "action" && (
            <QuestOrb
              ref={orbRef}
              type={stepConfig.type}
              onMouseEnter={showStepPopover}
              disabled={disabled}
              {...QuestOrbProps}
            />
          )}
        </span>
        {!!stepConfig && !hidden && !orbOnly && popperPlacement !== "center" && (
          <QuestStepPopper
            arrow={arrow}
            type={stepConfig.type}
            open={!!popperActive}
            stepConfig={stepConfig}
            onClose={handleClose}
            onBlur={handleBlur}
            onBack={handleBack}
            anchorEl={stepConfig.type === "action" ? innerRef.current : orbRef.current}
            placement={popperPlacement}
          />
        )}
        {!!stepConfig && !hidden && !orbOnly && popperPlacement === "center" && (
          <QuestStepModal
            arrow={arrow}
            type={stepConfig.type}
            open={!!popperActive}
            stepConfig={stepConfig}
            onClose={handleClose}
            onBlur={handleBlur}
            onBack={handleBack}
            className={classes.modal}
          />
        )}
      </>
    );
  }
);
