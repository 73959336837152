import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListSubheader,
  MenuItemProps,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ReactElement, ReactNode, isValidElement } from "react";
import { BORDER_RADIUS_MAXIMUS_ROUNDINUS, BUTTON_OUTLINE_BORDER_COLOR, BUTTON_OUTLINE_PADDING } from "../../AppTheme";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { PickRequired } from "../../types";
import { LoadingIcon } from "../LoadingIcon";
import { MenuItemWithTooltip } from "../MenuItemWithTooltip";

export type StrongSelectChangeEvent<T> = Event & { target: { value: T; name: string } };
export type StrongSelectChangeHandler<T> = (event: StrongSelectChangeEvent<T>, child: ReactNode) => void;

export type StrongSelectItemObject<T> = Omit<MenuItemProps, "value" | "children" | "onChange"> & {
  value: T;
  label: ReactNode;
  icon?: ReactNode;
  key?: string;
  description?: ReactNode;
  type?: "item" | "group";
  onChange?: StrongSelectChangeHandler<T>;
};

export type StrongSelectItemObjectWithKey<K> = PickRequired<StrongSelectItemObject<K>, "key">;
export type StrongSelectItem<T> = "divider" | undefined | false | ReactElement | StrongSelectItemObject<T>;
export type StrongSelectItemRenderer<K> = (item: StrongSelectItemObjectWithKey<K>, i: number) => ReactNode;

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    inputLabel: {
      transform: "translate(14px, -9px) scale(0.75)",
    },
    labelFilled: {
      transform: "translate(14px, 4px) scale(0.75)",
    },
    select: {
      letterSpacing: "normal",
    },
    selectButtonLike: {
      borderRadius: BORDER_RADIUS_MAXIMUS_ROUNDINUS,
      fontSize: "0.875rem",
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.2,
      "&& .MuiOutlinedInput-notchedOutline": {
        borderColor: BUTTON_OUTLINE_BORDER_COLOR,
        borderWidth: 1,
      },
      "&:focus .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.colors.primary,
      },
      "&:hover": {
        backgroundColor: "rgba(82, 99, 243, 0.04)",
      },
    },
    selectPrimary: {
      color: theme.colors.primary,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${alpha(theme.colors.primary, 0.3)}`,
        transition: "border-color ease .2s",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.colors.primary,
      },
    },
    selectFullRadius: {
      borderRadius: BORDER_RADIUS_MAXIMUS_ROUNDINUS,
      "&:focus": {
        borderRadius: BORDER_RADIUS_MAXIMUS_ROUNDINUS,
      },
    },
    innerSelect: {},
    innerSelectButtonLike: {
      padding: BUTTON_OUTLINE_PADDING,
      borderRadius: BORDER_RADIUS_MAXIMUS_ROUNDINUS,
      "&&&": {
        backgroundColor: "transparent",
      },
    },
    innerSelectTight: {
      "&&": {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightMedium,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    inputBase: {},
    defaultItem: {},
    defaultItemInner: {
      display: "flex",
      gap: theme.spacing(),
      alignItems: "center",
    },
    defaultItemInnerTight: {
      ...theme.typography.body2,
    },
    defaultItemIcon: {
      position: "relative",
      display: "flex",
      width: theme.spacing(2),
      height: theme.spacing(2),
      minWidth: "auto",
      flex: "0 0 auto",

      "& > *": {
        maxWidth: "100%",
        maxHeight: "100%",
        alignItems: "center",
        justifyContent: "middle",
      },
    },
    selectIcon: {
      height: 16,
      width: 16,
      right: theme.spacing(),
      top: 0,
      bottom: 0,
      margin: "auto 0",
    },
    selectIconPrimary: {
      color: theme.colors.primary,
    },
    selectIconTight: {},
    thinkingIcon: {},
  }),
  {
    classNamePrefix: "StrongSelect",
  }
);

export type StrongSelectJSSClassKey = keyof ReturnType<typeof useStyles>;

export type StrongSelectProps<K> = Omit<SelectProps, "classes" | "value" | "onChange"> & {
  classes?: Partial<ClassNameMap<StrongSelectJSSClassKey>>;
  items: StrongSelectItem<K>[];
  error?: boolean;
  helperText?: ReactNode;
  value?: K;
  SelectClasses?: SelectProps["classes"];
  thinking?: boolean;
  fullRadius?: boolean;
  tight?: boolean;
  buttonLike?: boolean;
  renderItem?: StrongSelectItemRenderer<K>;
  renderGroupItem?: StrongSelectItemRenderer<K>;
  onChange?: StrongSelectChangeHandler<K>;
};

export const StrongSelect = <K extends unknown>({
  items,
  name,
  classes: extClasses,
  className,
  label,
  labelId,
  error,
  helperText,
  renderItem,
  renderGroupItem,
  SelectClasses,
  children,
  thinking,
  color,
  variant = "standard",
  disabled,
  fullRadius,
  tight,
  buttonLike,
  ...rest
}: StrongSelectProps<K>) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const defaultItemRenderer = useCallbackSafeRef<StrongSelectItemRenderer<unknown>>(
    ({ label, value, description, key, icon }) =>
      rest.native ? (
        <option key={key} value={value as string}>
          {label}
        </option>
      ) : (
        <MenuItemWithTooltip
          className={classes.defaultItem}
          classes={{ wrapper: clsx(classes.defaultItemInner, { [classes.defaultItemInnerTight]: tight }) }}
          value={value as string}
          title={description}
          key={key}
        >
          {icon && <ListItemIcon className={classes.defaultItemIcon}>{icon}</ListItemIcon>}
          <Typography variant="inherit">{label}</Typography>
        </MenuItemWithTooltip>
      )
  );

  const colorIsPrimary = color === "primary";
  const variantIsFilled = variant === "filled";

  return (
    <FormControl
      variant="standard"
      error={!!error}
      className={clsx(classes.root, className)}
      fullWidth={rest.fullWidth}
    >
      {!!label && (
        <InputLabel id={labelId} className={clsx(classes.inputLabel, { [classes.labelFilled]: variantIsFilled })}>
          {label}
        </InputLabel>
      )}
      <Select
        variant={variant}
        name={name as string}
        label={label}
        labelId={labelId}
        notched={label ? true : undefined}
        className={clsx(classes.select, {
          [classes.selectPrimary]: colorIsPrimary,
          [classes.selectFullRadius]: fullRadius,
          [classes.selectButtonLike]: buttonLike,
        })}
        classes={{
          ...SelectClasses,
          icon: clsx(SelectClasses?.icon, classes.selectIcon, {
            [classes.selectIconPrimary]: colorIsPrimary,
            [classes.selectIconTight]: tight,
          }),
          select: clsx(classes.innerSelect, {
            [classes.innerSelectTight]: tight,
            [classes.innerSelectButtonLike]: buttonLike,
          }),
        }}
        IconComponent={
          thinking
            ? (props) => <LoadingIcon {...props} className={clsx(classes.thinkingIcon, props.className)} />
            : KeyboardArrowDownRoundedIcon
        }
        color={color}
        disabled={disabled || thinking}
        {...rest}
        inputProps={{
          ...rest.inputProps,
          className: clsx(classes.inputBase, rest.inputProps?.className),
        }}
      >
        {items.map((item, i) => {
          if (!item) return;
          if (item === "divider") return <Divider key={`divider-${i}`} />;
          if (isValidElement(item)) return item;

          const resolvedItem: StrongSelectItemObjectWithKey<K> = {
            key: `StrongSelect__item__${item.value || i}`,
            ...item,
          };

          if (resolvedItem.type === "group") {
            return renderGroupItem ? (
              renderGroupItem(resolvedItem, i)
            ) : (
              <ListSubheader key={resolvedItem.key} disableSticky>
                {resolvedItem.label}
              </ListSubheader>
            );
          }

          if (renderItem) return renderItem(resolvedItem, i);
          else return defaultItemRenderer(resolvedItem, i);
        })}
        {children}
      </Select>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
