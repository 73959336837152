import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import { VFC } from "react";
import moduleClasses from "./TaskUpNextIcon.module.scss";

export type TaskUpNextIconCSSClassKey = keyof typeof moduleClasses;

export type TaskUpNextIconProps = {
  classes?: SCSSClasses<TaskUpNextIconCSSClassKey>;
  className?: string;
  value: boolean;
};

export const TaskUpNextIcon: VFC<TaskUpNextIconProps> = ({ className, classes: extClasses, value }) => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  return <ArrowOutwardRoundedIcon className={clsx(classes.root, className, { [classes["root--active"]]: value })} />;
};
