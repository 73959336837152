import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IconButton } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { MouseEvent, VFC } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "absolute",
      zIndex: 1,
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    icon: {},
  }),
  {
    classNamePrefix: "ModalCloseButton",
  }
);

export type ModalCloseButtonJSSClassKey = keyof ReturnType<typeof useStyles>;

export type ModalCloseButtonProps = {
  classes?: Partial<ClassNameMap<ModalCloseButtonJSSClassKey>>;
  className?: string;
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const ModalCloseButton: VFC<ModalCloseButtonProps> = ({ className, classes: extClasses, onClose }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <IconButton
      aria-label="close"
      className={clsx(classes.root, className)}
      onClick={onClose}>
      <CloseRoundedIcon className={classes.icon} />
    </IconButton>
  );
};
