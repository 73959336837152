import { EditionBadgeBaseProps } from "@components/billing/EditionBadgeBase";
import { EntitlementWrapper } from "@components/billing/EntitlementWrapper";
import { getIconForPromiseState, usePromiseState, UsePromiseStateState } from "@hooks/usePromiseState";
import { Divider } from "@mui/material";
import {
  cloneElement,
  Fragment,
  isValidElement,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  RefObject,
  useEffect,
  useRef,
} from "react";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { UIAction, UIActionInList, UIActionObject, UIActionWrapper } from "../types/uiActions";
import { filterFalsy } from "./arrays";

export type UIActionMouseEventHandler = (e: MouseEvent) => Promise<unknown>;
export type UIActionElementOnClickHandler = (e: MouseEvent, promise: Promise<void>) => Promise<unknown> | unknown;
export type UIActionElementPostActionHandler = (e: MouseEvent) => Promise<unknown> | unknown;

export interface UseUIActionReturnType {
  handleClick: UIActionMouseEventHandler;
  promiseState: UsePromiseStateState;
}

export interface UIActionRendererInfo<IS_LINK extends boolean, E extends HTMLElement> {
  actionObject: UIActionObject<IS_LINK>;
  isLink: IS_LINK;
  handleClick: UIActionMouseEventHandler;
  ref: RefObject<E>;
  disabled: boolean;
  promiseState: UsePromiseStateState;
  entitlementChip: ReactElement<EditionBadgeBaseProps> | undefined;
  entitlementMessage: ReactNode | undefined;
  icon: ReactElement<{ className: string }> | undefined;
}

export type UIActionRenderer<E extends HTMLElement> = <IS_LINK extends boolean>(
  info: UIActionRendererInfo<IS_LINK, E>
) => ReactElement;

export type RenderUIActionOptions = {
  defaults?: Partial<UIActionObject>;
  ref?: Ref<HTMLElement>;
  onClick?: UIActionElementOnClickHandler | undefined;
  postAction?: UIActionElementPostActionHandler | undefined;
  forceDisable?: boolean;
};

export function useRenderUIAction<E extends HTMLElement>(
  wrapperType: UIActionWrapper,
  uiAction: UIAction,
  renderer: UIActionRenderer<E>,
  options: RenderUIActionOptions = {}
): ReactElement {
  const { defaults, ref, onClick, postAction, forceDisable } = options;

  const objRef = useRef<E>(null);

  useEffect(() => {
    if (!ref) return;

    const passRef = (ref: Ref<HTMLElement>) => {
      switch (typeof ref) {
        case "function":
          ref(objRef.current);
          break;
        case "object":
          (ref as MutableRefObject<HTMLElement | null>).current = objRef.current;
          break;
      }
    };

    passRef(ref);

    if (typeof uiAction === "object" && !isValidElement(uiAction) && uiAction.ref) passRef(uiAction.ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, objRef.current]);

  const action = (uiAction as UIActionObject | undefined)?.action;
  const [promiseState, addPromise] = usePromiseState();
  const disabled = !!(uiAction as UIActionObject | undefined)?.disabled || !!forceDisable || promiseState === "running";

  const handleClick = useCallbackSafeRef<UIActionMouseEventHandler>(async (e) => {
    e.stopPropagation();
    if (disabled) return;

    if (!action || typeof action === "string") return;

    const promise = action(objRef);
    addPromise(promise);
    await promise;
    await onClick?.(e, promise instanceof Promise ? promise : Promise.resolve());
    await postAction?.(e);
  });

  if (!uiAction) return <Fragment />;
  else if (typeof uiAction === "function") return uiAction(wrapperType);
  else if (isValidElement(uiAction)) return uiAction;
  else {
    const actionObject = { ...defaults, ...uiAction };
    const { entitlement, entitlementMode, action, icon: aoIcon } = actionObject;
    const isLink = typeof action === "string";
    const icon = getIconForPromiseState(promiseState) || aoIcon;

    if (entitlement)
      return (
        <EntitlementWrapper entitlement={entitlement} mode={entitlementMode}>
          {({ chip, disabled: entitlmentDisabled, message }) =>
            renderer({
              actionObject,
              icon,
              isLink,
              handleClick,
              ref: objRef,
              disabled: disabled || entitlmentDisabled,
              promiseState,
              entitlementChip: chip,
              entitlementMessage: message,
            })
          }
        </EntitlementWrapper>
      );
    else
      return renderer({
        actionObject,
        icon,
        isLink,
        handleClick,
        ref: objRef,
        disabled,
        promiseState,
        entitlementChip: undefined,
        entitlementMessage: undefined,
      });
  }
}

export const menuDivider = () => <Divider />;

export type MapUIActionsOptions<T> = Readonly<{
  keyPrefix?: string;
  separatorCb?(data: { i: number; key: string }): T;
}>;

export const mapUIActions = <T extends unknown>(
  uiActions: UIActionInList[],
  cb: (uiAction: UIAction, data: { i: number; key: string }) => T,
  options: MapUIActionsOptions<T> = {}
): T[] => {
  let { keyPrefix = "", separatorCb } = options;

  if (keyPrefix) keyPrefix = `${keyPrefix}_`;

  return uiActions.reduce((acc, uiAction, i) => {
    let key = `${i}`;
    if (typeof uiAction === "object" && !isValidElement(uiAction) && uiAction.key) key = uiAction.key;
    key = `${keyPrefix}${key}`;
    const data = { i, key };

    switch (uiAction) {
      case "separator":
        if (separatorCb) acc.push(separatorCb(data));
        break;
      default:
        acc.push(cb(uiAction, data));
        break;
    }

    return acc;
  }, [] as T[]);
};

export type MapUIActionsReactOptions<T> = MapUIActionsOptions<T>;

export const mapUIActionsReactElement = <T extends ReactElement>(
  uiActions: UIActionInList[] | undefined,
  cb: (uiAction: UIAction, data: { i: number; key: string }) => T,
  options: MapUIActionsReactOptions<T> = {}
): ReactElement<T>[] => {
  const { separatorCb, ...restOptions } = options;

  return filterFalsy(
    mapUIActions(uiActions || [], (uiAction, data) => uiAction && cloneElement(cb(uiAction, data), { key: data.key }), {
      ...restOptions,
      separatorCb: separatorCb && ((data) => cloneElement(separatorCb(data), { key: data.key })),
    })
  );
};

export const mapUIActionsReact = <T extends ReactNode>(
  uiActions: UIActionInList[] | undefined,
  cb: (uiAction: UIAction, data: { i: number; key: string }) => T,
  options: MapUIActionsReactOptions<T> = {}
): ReactElement<unknown>[] => {
  const { separatorCb, ...restOptions } = options;

  return mapUIActionsReactElement(uiActions, (uiAction, data) => <Fragment>{cb(uiAction, data)}</Fragment>, {
    ...restOptions,
    separatorCb: separatorCb && ((data) => <Fragment>{separatorCb(data)}</Fragment>),
  });
};
