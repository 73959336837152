import { SVGProps, VFC } from "react";
import { PriorityLevelV16 } from "./priority.types";
import { hasPriority } from "./priority.utils";

export type V16PriorityIconProps = SVGProps<SVGSVGElement> & {
  className?: string;
  priority: PriorityLevelV16;
};

export const V16PriorityIcon: VFC<V16PriorityIconProps> = ({ className, priority, ...rest }) => {
  const pMap = {
    P1: hasPriority("P1", priority),
    P2: hasPriority("P2", priority),
    P3: hasPriority("P3", priority),
    P4: hasPriority("P4", priority),
  };

  let color = "currentColor";

  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect
        x="2"
        y="10"
        width="2"
        height="4"
        rx="1"
        opacity={pMap["P4"] ? 1 : 0.25}
        fill={pMap["P4"] ? color : "currentColor"}
      />
      <rect
        x="5.33333"
        y="7.33333"
        width="2"
        height="6.66667"
        rx="1"
        opacity={pMap["P3"] ? 1 : 0.25}
        fill={pMap["P3"] ? color : "currentColor"}
      />
      <rect
        x="8.66667"
        y="4.66667"
        width="2"
        height="9.33333"
        rx="1"
        opacity={pMap["P2"] ? 1 : 0.25}
        fill={pMap["P2"] ? color : "currentColor"}
      />
      <rect
        x="12"
        y="2"
        width="2"
        height="12"
        rx="1"
        opacity={pMap["P1"] ? 1 : 0.25}
        fill={pMap["P1"] ? color : "currentColor"}
      />
    </svg>
  );
};
