import { ReclaimEdition } from "../../reclaim-api/team/Team.types";

const TEAM_FEATURES = ["Auto-schedule 1:1s", "Integrate your tasks & projects", "Manage your team's workload"];

export const getPlanDetails = (edition?: ReclaimEdition, terminalEdition?: ReclaimEdition): string[] => {
  switch (edition) {
    case "PRO":
      return ["Unlimited Habits", "Customize your time blocks", "Sync unlimited calendars"];
    case "TRIAL":
      if (terminalEdition !== "TEAM") return ["Use Team features for a limited time", ...TEAM_FEATURES];
      else return [...TEAM_FEATURES];
    case "TRIAL_BUSINESS":
      if (terminalEdition !== "BUSINESS") return ["Use Business features for a limited time", ...TEAM_FEATURES];
      else return [...TEAM_FEATURES];
    case "REWARD":
      return ["Use Team features while on reward credits", ...TEAM_FEATURES];
    case "TEAM_REWARD":
      return ["Use Business features while on reward credits", ...TEAM_FEATURES];
    case "TEAM":
      return [...TEAM_FEATURES];
    case "STARTER":
      return ["Manage a small team", ...TEAM_FEATURES];
    case "BUSINESS":
      return ["Manage a large team", "Unlimited habits, calendars, scheduling links", ...TEAM_FEATURES];
    case "ENTERPRISE":
      return ["Unlimited team size", "Priority support", "Unlimited everything", ...TEAM_FEATURES];
    case "LITE":
    case "ASSISTANT":
    default:
      return [
        "Block flexible time for anything",
        "Sync your personal + work calendar",
        "Manage your schedule via Slack",
      ];
  }
};
