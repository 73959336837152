import { useEffect, useRef } from "react";

export function useIsMountedRef(): { readonly current: boolean } {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => void (isMountedRef.current = false);
  }, []);

  return isMountedRef;
}
