import { List, ListItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { VFC, useMemo } from "react";
import { useUserContext } from "../../context/UserContext";
import { useOurRouter } from "../../hooks/useOurRouter";
import { useUsageData } from "../../hooks/useUsageData";
import { INTEGRATIONS_META } from "../../reclaim-api/Integrations.consts";
import { filterFalsy } from "../../utils/arrays";
import { typedKeys } from "../../utils/objects";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    skeleton: {
      width: "60%",
      height: "1.5em",
    },
    list: {},
    listItem: {
      display: "list-item",
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5, 0),
      "&::before": { content: "'• '" },
    },
  }),
  {
    classNamePrefix: "OverageListIntegrationsSubList",
  }
);

export type OverageListIntegrationsSubListJSSClassKey = keyof ReturnType<typeof useStyles>;

export type OverageListIntegrationsSubListProps = {
  classes?: Partial<ClassNameMap<OverageListIntegrationsSubListJSSClassKey>>;
  className?: string;
};

export const OverageListIntegrationsSubList: VFC<OverageListIntegrationsSubListProps> = ({
  className,
  classes: extClasses,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  const { usageData } = useUsageData();
  const router = useOurRouter();
  const [{ user }] = useUserContext();

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  const mustBeDisabled = useMemo(
    () =>
      usageData &&
      filterFalsy(
        typedKeys(INTEGRATIONS_META).map((integration) => {
          const integrationActuals = usageData.actuals[INTEGRATIONS_META[integration].entitlement];
          return (
            !!integrationActuals &&
            integrationActuals.actualValue &&
            !integrationActuals.allowedValueForCurrentEdition &&
            integration
          );
        })
      ),
    [usageData]
  );

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div className={clsx(classes.root, className)}>
      {!usageData || !user ? (
        <>
          <Skeleton className={classes.skeleton} />
          <Skeleton className={classes.skeleton} />
          <Skeleton className={classes.skeleton} />
          <Skeleton className={classes.skeleton} />
        </>
      ) : (
        <>
          Disconnect the following integrations:
          <List className={classes.list}>
            {mustBeDisabled?.map((integration) => (
              <ListItem key={integration} className={classes.listItem}>
                {INTEGRATIONS_META[integration].label({ router, user })}
              </ListItem>
            ))}
          </List>
        </>
      )}
    </div>
  );
};
