import { Box } from "@mui/material";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import { createContext } from "react";
import { IRhfControl } from ".";
import moduleClasses from "./RhfRadioBoxGroupControl.module.scss";
import { RhfRadioGroupControl, RhfRadioGroupControlProps } from "./RhfRadioGroupControl";

export const RadioBoxGroupControlContext = createContext<{
  orientation: RadioBoxGroupControlOrientation;
  fieldName: string;
}>({
  orientation: "vertical",
  fieldName: "",
});

export type RadioBoxGroupControlCSSClassKey = keyof typeof moduleClasses;
export type RadioBoxGroupControlOrientation = "horizontal" | "vertical";

export type RhfRadioBoxGroupControlProps = RhfRadioGroupControlProps & {
  classes?: SCSSClasses<RadioBoxGroupControlCSSClassKey>;
  className?: string;
  orientation?: RadioBoxGroupControlOrientation;
};

export const RhfRadioBoxGroupControl: IRhfControl<RhfRadioBoxGroupControlProps> = ({
  className,
  classes: extClasses,
  orientation = "vertical",
  children,
  ...rest
}) => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  return (
    <RadioBoxGroupControlContext.Provider
      value={{
        orientation,
        fieldName: rest.name,
      }}
    >
      <RhfRadioGroupControl className={clsx(classes.root, className)} aria-label={rest.name} {...rest}>
        <Box
          className={clsx(classes.content, {
            [classes.vertical]: orientation === "vertical",
            [classes.horizontal]: orientation === "horizontal",
          })}
        >
          {children}
        </Box>
      </RhfRadioGroupControl>
    </RadioBoxGroupControlContext.Provider>
  );
};

RhfRadioBoxGroupControl.isControl = RhfRadioGroupControl.isControl;
RhfRadioBoxGroupControl.isController = RhfRadioGroupControl.isController;
