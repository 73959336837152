import { List, ListItem, Skeleton } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { VFC } from "react";
import { useSubscriptionLogic } from "../../hooks/useSubscriptionLogic";
import { useUsageData } from "../../hooks/useUsageData";
import { ENTITLEMENT_META } from "../../reclaim-api/team/Team.consts";
import { EntitlementType } from "../../reclaim-api/team/Team.types";
import { OVERAGE_LIST_ENTITLEMENT_META, OVERAGE_LIST_ENTITLEMENT_ORDER } from "./OverageList.consts";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      listStyleType: "disc",
      margin: theme.spacing(0, 0, 0, 2),
    },
    listItem: {
      ...theme.typography.body2,
      display: "list-item",
      padding: theme.spacing(0.25, 0),

      "& em": {
        background: theme.palette.primary.main,
        color: theme.colors.white,
        fontWeight: "bold",
        fontSize: "0.8em",
      },
    },
    listItemHighlighted: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
  {
    classNamePrefix: "OverageList",
  }
);

export type OverageListJSSClassKey = keyof ReturnType<typeof useStyles>;

export type OverageListProps = {
  classes?: Partial<ClassNameMap<OverageListJSSClassKey>>;
  className?: string;
  highlightedEntitlement?: EntitlementType;
};

export const OverageList: VFC<OverageListProps> = ({ className, classes: extClasses, highlightedEntitlement }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  const { usageData } = useUsageData();
  const { userCurrentEdition } = useSubscriptionLogic();

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <List className={clsx(classes.root, className)}>
      {!usageData || !userCurrentEdition ? (
        <>
          <ListItem className={classes.listItem}>
            <Skeleton width={80} variant="text" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Skeleton width={90} variant="text" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Skeleton width={70} variant="text" />
          </ListItem>
        </>
      ) : (
        <>
          {OVERAGE_LIST_ENTITLEMENT_ORDER.map((entitlement) => {
            const entObj = usageData.actuals[entitlement];
            const { comparator } = ENTITLEMENT_META[entitlement];
            const meta = OVERAGE_LIST_ENTITLEMENT_META[entitlement];
            if (!meta) return;

            const render = OVERAGE_LIST_ENTITLEMENT_META[entitlement]?.render;

            if (
              !render ||
              entObj === undefined ||
              comparator(entObj.allowedValueForCurrentEdition as never, entObj.actualValue as never) >= 0
            )
              return;

            return (
              <ListItem
                className={clsx(classes.listItem, {
                  [classes.listItemHighlighted]: entitlement === highlightedEntitlement,
                })}
                key={entitlement}
              >
                {render(entObj as never, userCurrentEdition)}
              </ListItem>
            );
          })}
        </>
      )}
    </List>
  );
};
