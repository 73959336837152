import { ReactNode } from "react";
import {
  EntitlementType,
  EntitlementValueObject,
  EntitlementValueTypeMap,
  ReclaimEdition,
} from "../../reclaim-api/team/Team.types";
import { EntitlementActualObject } from "../../reclaim-api/Users.types";
import { pluralize, ucfirst } from "../../utils/strings";
import { satisfies } from "../../utils/typescript";
import { EntitlementTableEntitlementType } from "./EntitlementTable.types";

const numToStr = (num: number) => (num === Infinity ? "Unlimited" : `${num}`);
const renderNumValueWithNoun = (value: number, noun: string) => `${numToStr(value)} ${pluralize(value, noun)}`;
const renderNumValueWithMax = ({ value }: EntitlementValueObject<number>, noun?: string) =>
  `${noun ? renderNumValueWithNoun(value, noun) : numToStr(value)}`;

export const ENTITLEMENT_TABLE_SHORT_LIST_LENGTH = 7;

export const ENTITLEMENT_TABLE_ENTITLEMENT_ORDER = satisfies<readonly EntitlementType[]>()([
  "TEAM_SIZE",
  "TEAM_ANALYTICS",
  "MAX_SCHEDULING_LINKS",
  "MAX_HABITS",
  "MAX_SYNCS",
  "SCHEDULER_WEEKS",
  "MAX_CALENDARS",
  "MAX_TASKS",
  "MAX_CUSTOM_TIME_SCHEMES",
  "CUSTOM_BLOCKING",
  "MAX_1_ON_1_ORGANIZE",
  "MAX_1_ON_1_ATTEND",
  "INTEGRATIONS",
  "SUPPORT",
  "SSO",
]);

export const ENTITLEMENT_TABLE_ENTITLEMENT_META: {
  [ENTITLEMENT in EntitlementTableEntitlementType]: {
    microValueRenderer?(obj: EntitlementValueObject<EntitlementValueTypeMap[ENTITLEMENT], false>): ReactNode;
    valueRenderer(obj: EntitlementValueObject<EntitlementValueTypeMap[ENTITLEMENT], false>): ReactNode;
    actualRenderer?(value: EntitlementValueTypeMap[ENTITLEMENT]): ReactNode;
    entitlementCallout?(
      data: {
        currentEdition: ReclaimEdition;
      } & EntitlementActualObject<EntitlementValueTypeMap[ENTITLEMENT]>
    ): ReactNode;
  };
} = {
  TEAM_SIZE: {
    microValueRenderer: ({ value: { min, max } }) => {
      if (min === 1 && max === Infinity) return "Unlimited";
      if (min !== 1 && max === Infinity) return `${min}+`;
      if (min === 1 && max !== Infinity) return `Up to ${max}`;
      return `${min} - ${max}`;
    },
    valueRenderer: ({ value: { min, max } }) => {
      if (min === 1 && max === Infinity) return "Unlimited users";
      if (min !== 1 && max === Infinity) return `${min}+ ${pluralize(min, "user")}`;
      if (min === 1 && max !== Infinity) return `Up to ${max} ${pluralize(max, "user")}`;
      return `Between ${min} and ${max} ${pluralize(max, "user")}`;
    },
    actualRenderer: ({ max }) => max && <>You have {renderNumValueWithNoun(max, "Team Member")}</>,
  },
  SCHEDULER_WEEKS: {
    valueRenderer: ({ value }) => `${renderNumValueWithNoun(value, "week")} out`,
  },
  MAX_TASKS: {
    microValueRenderer: (obj) => renderNumValueWithMax(obj),
    valueRenderer: ({ value }) => renderNumValueWithNoun(value, "Task"),
    actualRenderer: (value) => value && <>You have {renderNumValueWithNoun(value, "Task")}</>,
  },
  MAX_CALENDARS: {
    microValueRenderer: (obj) => renderNumValueWithMax(obj),
    valueRenderer: ({ value }) => renderNumValueWithNoun(value, "Connected Calendar"),
    actualRenderer: (value) => value && <>You have {renderNumValueWithNoun(value, "Connected Calendar")}</>,
  },
  MAX_SYNCS: {
    microValueRenderer: (obj) => renderNumValueWithMax(obj),
    valueRenderer: ({ value }) => renderNumValueWithNoun(value, "Calendar Sync"),
    actualRenderer: (value) => value && <>You have {renderNumValueWithNoun(value, "Calendar Sync")}</>,
  },
  MAX_HABITS: {
    microValueRenderer: (obj) => renderNumValueWithMax(obj),
    valueRenderer: ({ value }) => renderNumValueWithNoun(value, "Habit"),
    actualRenderer: (value) => value && <>You have {renderNumValueWithNoun(value, "Habit")}</>,
  },
  MAX_CUSTOM_TIME_SCHEMES: {
    microValueRenderer: (obj) => renderNumValueWithMax(obj),
    valueRenderer: ({ value }) => renderNumValueWithNoun(value, "Custom hours"),
    actualRenderer: (value) => value && <>You have {renderNumValueWithNoun(value, "Custom hours")}</>,
  },
  CUSTOM_BLOCKING: {
    valueRenderer: ({ value }) => (value ? "Custom" : "Basic"),
    actualRenderer: (value) => value && <>You're using Custom Blocking</>,
  },
  MAX_SCHEDULING_LINKS: {
    microValueRenderer: (obj) => renderNumValueWithMax(obj),
    valueRenderer: ({ value }) => renderNumValueWithNoun(value, "Scheduling Link"),
    actualRenderer: (value) => value && <>You have {renderNumValueWithNoun(value, "Scheduling Link")}</>,
  },
  MAX_1_ON_1_ORGANIZE: {
    microValueRenderer: (obj) => renderNumValueWithMax(obj),
    valueRenderer: (obj) => renderNumValueWithMax(obj, "Smart 1:1"),
    actualRenderer: (value) => value && <>You have {renderNumValueWithNoun(value, "Smart 1:1")}</>,
  },
  MAX_1_ON_1_ATTEND: {
    microValueRenderer: (obj) => renderNumValueWithMax(obj),
    valueRenderer: (obj) => renderNumValueWithMax(obj, "Smart 1:1"),
    actualRenderer: (value) => value && <>You're attending {renderNumValueWithNoun(value, "Smart 1:1")}</>,
  },
  INTEGRATIONS: {
    valueRenderer: ({ value }) => ucfirst(value),
  },
  TEAM_ANALYTICS: {
    microValueRenderer: ({ value }) => (value ? "Included" : "–"),
    valueRenderer: ({ value }) =>
      value ? (
        <>
          Team&nbsp;Analytics <strong>included</strong>
        </>
      ) : (
        <>
          Team&nbsp;Analytics <strong>not&nbsp;included</strong>
        </>
      ),
  },
  SUPPORT: {
    valueRenderer: ({ value }) => ucfirst(value),
  },
  SSO: {
    valueRenderer: ({ value }) => (value ? "Included" : "–"),
    actualRenderer: (value) => value && <>You're using SSO</>,
  },
};
