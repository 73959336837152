import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enUS } from "date-fns/locale";
import { FC, useMemo } from "react";
import { useUserContext } from "src/context/UserContext";
import { BROWSER_LOCALE } from "src/consts/date.consts";
import { Locale } from "date-fns";
import { WeekdayIndex } from "../utils/dates";

export type ReclaimLocalizationProviderProps = {};

export const ReclaimLocalizationProvider: FC<ReclaimLocalizationProviderProps> = ({ children }) => {
  /********************/
  /*   custom hooks   */
  /********************/

  const [{ user }] = useUserContext();

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  const adapterLocale = useMemo<Locale>(() => {
    const locale = new Intl.Locale(BROWSER_LOCALE);
    let browserFirstDayOfWeek = 0;
    try {
      // @ts-ignore Locale.weekInfo is newish. Browser firstDay is 1 - 7 (Monday - Sunday)
      browserFirstDayOfWeek = (locale.weekInfo?.firstDay ?? 0) % 7;
    } catch (e) {
      console.warn("Browser doesn't support Intl.Locale.weekInfo");
    }
    const weekStartsOn = user?.settings?.weekStart ?? (browserFirstDayOfWeek as WeekdayIndex);

    return {
      ...enUS,
      options: {
        weekStartsOn,
      },
    };
  }, [user?.settings?.weekStart]);

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
      {children}
    </LocalizationProvider>
  );
};
