import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import { Alert, Button, Collapse, Skeleton, TextField, Typography } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { BORDER_RADIUS_MAXIMUS_ROUNDINUS } from "../../../AppTheme";
import { BROWSER_DEFAULT_CURRENCY_CODE } from "../../../consts/date.consts";
import { useUserContext } from "../../../context/UserContext";
import { useTeamState } from "../../../hooks/atoms/useTeam";
import { useCallbackSafeRef } from "../../../hooks/useCallbackSafeRef";
import { useDateTimeFormatter } from "../../../hooks/useDateTimeFormatter";
import { useElementRect } from "../../../hooks/useElementRect";
import { useSubscriptionLogic } from "../../../hooks/useSubscriptionLogic";
import WarningSvg from "../../../img/warning-icon.svg";
import { reclaim } from "../../../reclaim-api";
import { EDITION_META, SUPPORTED_CURRENCIES_LIST } from "../../../reclaim-api/team/Team.consts";
import {
  EntitlementType,
  ReclaimEdition,
  SubscriptionFrequencyStr,
  SupportedCurrency,
} from "../../../reclaim-api/team/Team.types";
import { formatDollarAmount, FormatDollarAmountOptions } from "../../../utils/i18n";
import { aOrAn, pluralize } from "../../../utils/strings";
import { userIsAdminOnTeam } from "../../../utils/team";
import { useConfirmDialog } from "../../ConfirmDialog";
import { ContactSupportButton } from "../../ContactSupportButton";
import { StrongSelect } from "../../forms/StrongSelect";
import { GenericInfoMessage } from "../../GenericInfoMessage";
import Helptip from "../../Helptip";
import { MiniRadioGroup } from "../../MiniRadioGroup";
import { QuestStepWrapper } from "../../quests/QuestStepWrapper";
import { ReasonTip } from "../../ReasonTip";
import { TextSkeleton } from "../../TextSkeleton";
import { EntitlementTable } from "../EntitlementTable";
import { PurchaseCheckout } from "./PurchaseCheckout";
import { DOWNGRADE_TO_NON_PAID_MESSAGE_IN_OVERAGE, FREQUENCY_LABEL_MAP } from "./PurchaseOMatic.consts";
import { usePurchaseOMaticData } from "./PurchaseOMatic.hooks";
import { PurchaseOMaticPlanData } from "./PurchaseOMatic.types";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2, 2, 0),
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
      overflowY: "auto",
    },
    content: {
      flex: "1 1 auto",

      padding: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
    },
    mobile: {
      padding: theme.spacing(4, 0, 0, 0),
      height: "100%",
    },
    header: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(4),
    },
    headerContent: {
      marginTop: theme.spacing(),
      maxWidth: 500,
      textAlign: "center",
    },
    scrollWrapper: {
      flex: "1 1 auto",
    },
    scrollWrapperMobile: {
      overflow: "auto",
      position: "relative",
      scrollSnapType: "x mandatory",
      scrollbarWidth: "none" /* Firefox */,
      display: "flex",
      margin: theme.spacing(0, -2),
      padding: theme.spacing(0, 2),

      "-ms-overflow-style": "none" /* IE 10+ */,

      "&::-webkit-scrollbar": {
        background: "transparent" /* Chrome/Safari/Webkit */,
        width: 0,
      },

      "&::after": {
        content: "''",
        width: theme.spacing(5),
      },
    },
    entitlementTable: {
      position: "relative",
      transition: theme.transitions.create("height"),
      flex: "0 0 auto",
    },
    tableMobile: {
      alignSelf: "start",
    },
    planControls: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      gap: theme.spacing(),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        position: "relative",
        width: "100%",
      },
    },
    currency: {
      maxWidth: "fit-content",
      [theme.breakpoints.up("sm")]: {
        bottom: theme.spacing(),
        position: "absolute",
        right: 0,
      },
    },
    currencySelect: {
      padding: theme.spacing(0.5),
    },
    selectorPill: {
      maxWidth: 316,
      display: "flex",
      background: "#EDEEF2",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(1, 2),
      gap: theme.spacing(1.5),
      borderRadius: BORDER_RADIUS_MAXIMUS_ROUNDINUS,
    },
    freqSpacer: {
      backgroundColor: "#181D25",
      height: 36,
      opacity: 0.15,
      width: 1,
    },
    freqSelector: {
      border: "none",
    },
    freqSelectorSelected: {
      backgroundColor: "#181D25",
      color: "white",

      "&:hover": {
        background: "#181D25",
        color: "white",
      },
      "&.Mui-disabled": {
        color: "white",
      },
    },
    showAllButton: {
      marginTop: theme.spacing(3),
      alignSelf: "center",
      flex: "0 0 auto",
    },
    showAllButtonMobile: {
      width: "100%",
      borderRadius: 0,
      margin: 0,
      background: theme.colors.white,
      zIndex: 1,
      height: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      boxSizing: "content-box",
      borderTop: `1px solid ${theme.colors.borderGrey}`,
    },
    seatsInputContainer: {
      alignItems: "center",
      display: "flex",
      gap: theme.spacing(),
    },
    seatsInput: {
      padding: theme.spacing(1, 0.5, 1, 1),
      textAlign: "center",
      width: 60,
      backgroundColor: "white",
    },
    entitlementTableColumnMobile: {
      scrollSnapAlign: "center",
    },
    columnHeaderContent: {
      flex: "1 1 auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    editionHeaderPrice: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: theme.spacing(),
    },
    editionHeaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    editionHeaderPriceCost: {
      fontSize: 24,
      fontWeight: theme.typography.fontWeightBold,
    },
    editionHeaderPricesCaption: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    editionHeaderPriceFreq: {
      transform: "translateY(2px)",
    },
    editionButton: {
      width: 130,
    },
    selectedEditionButton: {
      backgroundColor: "#181D25",
      border: "1px solid #181D25",
      color: "#FAFAEF",
      "&:hover, &:hover.Mui-disabled": {
        backgroundColor: "#181D25",
        border: "1px solid #181D25",
        color: "#FAFAEF",
      },
      "&.Mui-disabled": {
        color: "white",
      },
    },
    cancelButton: {
      [theme.breakpoints.up("sm")]: {
        margin: 0,
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        margin: 0,
        width: "100%",
      },
    },
    warningIcon: {
      color: "#FFD500",
      height: 16,
      width: 16,
    },
    editionHeaderPriceCostLoading: {
      opacity: 0.5,
    },
    skeletonContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(1),
      padding: theme.spacing(1, 1, 6, 1),
    },
    supportMessage: {
      margin: theme.spacing(4, 0),
    },
    seatWarningAlert: {
      margin: theme.spacing(1, 0),
    },
    unavailableText: {
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.2,
      margin: theme.spacing(0.25, 0, 1),
    },
    keepPlan: {
      padding: 0,
    },
  }),
  {
    classNamePrefix: "PurchaseOMatic",
  }
);

export type PurchaseOMaticJSSClassKey = keyof ReturnType<typeof useStyles>;

export type PurchaseOMaticProps = {
  classes?: Partial<ClassNameMap<PurchaseOMaticJSSClassKey>>;
  className?: string;
  highlightedEntitlement?: EntitlementType;
  mobileMode?: boolean;
  disabled?: boolean;
  onAvailableEditionsChange?: (editons: ReclaimEdition[]) => void;
  onClose?: () => void;
};

export const PurchaseOMatic = forwardRef<HTMLDivElement, PurchaseOMaticProps>(
  (
    {
      className,
      classes: extClasses,
      highlightedEntitlement,
      mobileMode,
      disabled,
      onAvailableEditionsChange,
      onClose,
    },
    ref
  ) => {
    const classes = useStyles({
      classes: extClasses,
    });

    // needed for usePurchaseOMaticData
    const { team } = useTeamState();
    const [currency, setCurrency] = useState<SupportedCurrency>(
      team.pricingSummary.currency || BROWSER_DEFAULT_CURRENCY_CODE
    );

    /********************/
    /*   custom hooks   */
    /********************/

    const [{ user }] = useUserContext();
    const { ref: tableRef, height: tableHeight } = useElementRect<HTMLTableElement>();
    const { format } = useDateTimeFormatter();

    const { userCurrentEdition, planCancelStatus, userIsOnTrial } = useSubscriptionLogic();

    // Initalize the plan state. Note: The team object will be defined
    // when this component is mounted. Do not re-sync the plan from the team
    // otherwise it will cause the user changed plan to reset.
    const [plan, setPlan] = useState<PurchaseOMaticPlanData>(() => ({
      seats:
        team.membershipSummary.activeCount < team.membershipSummary.paidCount
          ? team.membershipSummary.paidCount
          : team.membershipSummary.activeCount,
      frequency: team.pricingSummary.subscriptionFrequency || "YEAR",
    }));

    const {
      entitlementTable,
      usageData,
      subscriptionOptions,
      editions,
      recommendedEdition,
      initialized,
      loading,
      loadingError,
    } = usePurchaseOMaticData(plan, currency);

    const openKeepPlanModal = useConfirmDialog({
      title: "Keep your plan?",
      message: planCancelStatus.isCancelled
        ? `Your plan is currently set to cancel automatically on ${format(
            planCancelStatus.terminalDate,
            "SHORT_DATE_YEAR_DISPLAY_FORMAT"
          )}.`
        : undefined,
      declineLabel: "Never mind",
      confirmLabel: "Keep plan",
      onConfirm: async () => {
        await reclaim.team.reinstateSubscription();
        onClose?.();
      },
    });

    /********************/
    /*     useState     */
    /********************/

    const [showAllEntitlements, setShowAllEntitlements] = useState(false);
    const [showMinSeatWarning, setShowMinSeatWarning] = useState(false);
    const [displaySeats, setDisplaySeats] = useState(plan.seats.toString());

    /********************/
    /* useMemo & consts */
    /********************/

    const isTeamAdmin = useMemo(() => userIsAdminOnTeam(user || undefined, team || undefined), [team, user]);

    const edCount = editions.length;
    const showEntitlementHeaders = !mobileMode && showAllEntitlements;
    const { seats, edition: selectedEdition, frequency } = plan;

    /********************/
    /*    useCallback   */
    /********************/

    const reinstateSubscription = useCallbackSafeRef(() => openKeepPlanModal());

    const toggleShowAll = useCallbackSafeRef(() => setShowAllEntitlements(!showAllEntitlements));

    const updatePlan = useCallbackSafeRef((newPlan: Partial<PurchaseOMaticPlanData>) => {
      setPlan({
        ...plan,
        seats,
        frequency,
        edition: selectedEdition,
        ...newPlan,
      });
    });

    const setSelectedEdition = useCallbackSafeRef((edition: ReclaimEdition | undefined) =>
      updatePlan({
        ...plan,
        edition,
      })
    );

    const setSeats = useCallbackSafeRef((seats: number) =>
      updatePlan({
        ...plan,
        seats,
      })
    );

    const setFrequency = useCallbackSafeRef((frequency: SubscriptionFrequencyStr) =>
      updatePlan({
        ...plan,
        frequency,
      })
    );

    const handleCheckoutClose = useCallbackSafeRef(() => {
      setSelectedEdition(undefined);
    });

    const formatPennies = (pennies: number, options?: FormatDollarAmountOptions) =>
      pennies === undefined ? "--" : formatDollarAmount(pennies / 100, { currency, ...options });

    /********************/
    /*    useEffects    */
    /********************/

    useEffect(() => {
      if (team.pricingSummary.currency && team.pricingSummary.currency !== currency)
        setCurrency(team.pricingSummary.currency);
      // we don't want to update when `currency` updates
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team.pricingSummary.currency]);

    useEffect(() => {
      onAvailableEditionsChange?.(editions);
    }, [editions, onAvailableEditionsChange]);

    useEffect(() => {
      if (!subscriptionOptions) return;

      const newCount = Number(displaySeats);
      const activeCount = team.membershipSummary.activeCount;

      if (!newCount || newCount === plan.seats) return;

      if (newCount < activeCount && team.pricingSummary.homogeneous) {
        setShowMinSeatWarning(true);
        setSeats(activeCount);
      } else {
        setShowMinSeatWarning(false);
        setSeats(newCount);
      }
    }, [
      displaySeats,
      plan.seats,
      setSeats,
      subscriptionOptions,
      team.membershipSummary.activeCount,
      team.pricingSummary.homogeneous,
    ]);

    /**
     * This effect deselects the edition if the user is trying to sneak into selecting their current
     * plan or has hit a seat limit exception.
     */
    useEffect(() => {
      if (
        !loading &&
        subscriptionOptions &&
        plan.edition &&
        subscriptionOptions.options[plan.edition] &&
        plan.edition !== "LITE"
      ) {
        const option = subscriptionOptions.options[plan.edition];
        const isCurrentPlan = !!option.prices[plan.frequency]?.isCurrentPlan;
        const hasSeatException = option.minSeats > plan.seats || option.maxSeats < plan.seats;

        if (isCurrentPlan || hasSeatException) {
          setPlan({
            ...plan,
            edition: undefined,
          });
        }
      }
    }, [plan, plan.frequency, loading, subscriptionOptions]);

    /********************/
    /*       JSX        */
    /********************/

    return (
      <div className={clsx(classes.root, className)}>
        <div className={clsx(classes.content, className, { [classes.mobile]: mobileMode })} ref={ref}>
          {(() => {
            if (!initialized) {
              return (
                <div className={classes.skeletonContent}>
                  <Skeleton variant="rectangular" width={150} height={30} />
                  <Skeleton variant="rectangular" width={400} height={60} />
                  <Skeleton variant="rectangular" width={250} height={52} style={{ marginTop: 16 }} />
                  <Skeleton variant="rectangular" width="100%" height={300} style={{ marginTop: 8 }} />
                </div>
              );
            }

            if (loadingError || (subscriptionOptions && editions.length === 0)) {
              /**
               * If subscription options results in an error or is empty display a contact support message
               */
              return (
                <div className={classes.supportMessage}>
                  <GenericInfoMessage
                    icon={<PhoneIcon />}
                    align="center"
                    title="Custom Pricing"
                    message={
                      <>
                        It looks like you will need assistance in order to make any changes to your plan. Please{" "}
                        <ContactSupportButton variant="link" lead="I need help with my custom subscription...">
                          contact support
                        </ContactSupportButton>{" "}
                        for help.
                      </>
                    }
                  />
                </div>
              );
            }

            if (!loadingError && subscriptionOptions && !!editions.length) {
              return (
                <>
                  <div className={classes.header}>
                    <Typography variant="h3">{isTeamAdmin ? "Select a plan" : "Available plans"}</Typography>
                    <QuestStepWrapper
                      config={{ group: "INVITE_TEAM", quest: "SELECT_PLAN", step: "PURCHASE_INFO" }}
                      QuestOrbProps={{ offsetX: 90, offsetY: -15 }}
                      popperPlacement="bottom-start"
                    />
                    <div className={classes.headerContent}>
                      <Typography>
                        {(() => {
                          // is in a legacy plan
                          if (userCurrentEdition && EDITION_META[userCurrentEdition].isLegacy) {
                            return (
                              <>
                                Check out our new plans! Based on your usage, the plan{" "}
                                {
                                  <TextSkeleton
                                    text={
                                      recommendedEdition && <strong>{EDITION_META[recommendedEdition].label}</strong>
                                    }
                                    placeholderText="Starter"
                                  />
                                }{" "}
                                looks just right for you.
                              </>
                            );
                          }
                          if (isTeamAdmin) {
                            if (planCancelStatus.isCancelled) {
                              const dt = format(
                                new Date(planCancelStatus.terminalDate),
                                "DATE_CONTROL_FORMAT_DAY_MODE"
                              );
                              return (
                                <>
                                  Your old plan,{" "}
                                  <TextSkeleton
                                    text={
                                      recommendedEdition && <strong>{EDITION_META[recommendedEdition].label}</strong>
                                    }
                                    placeholderText="Starter"
                                  />
                                  , will end on {dt}. You will continue to have access to{" "}
                                  <TextSkeleton
                                    text={
                                      recommendedEdition && <strong>{EDITION_META[recommendedEdition].label}</strong>
                                    }
                                    placeholderText="Starter"
                                  />{" "}
                                  features & can choose to{" "}
                                  <Button
                                    className={classes.keepPlan}
                                    variant="text"
                                    color="primary"
                                    onClick={reinstateSubscription}
                                  >
                                    keep your plan
                                  </Button>{" "}
                                  anytime before then.
                                </>
                              );
                            }
                            return (
                              <>
                                Based on your usage, the{" "}
                                {
                                  <TextSkeleton
                                    text={
                                      recommendedEdition && <strong>{EDITION_META[recommendedEdition].label}</strong>
                                    }
                                    placeholderText="Starter"
                                  />
                                }{" "}
                                plan looks just right for you.
                              </>
                            );
                          }
                          return (
                            <>
                              Check out the features available under each plan. If you'd like to change plans, contact
                              your team admin.
                            </>
                          );
                        })()}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.planControls}>
                    {isTeamAdmin && (
                      <div className={classes.selectorPill}>
                        <MiniRadioGroup<SubscriptionFrequencyStr>
                          className={classes.freqSelector}
                          classes={{ selected: classes.freqSelectorSelected }}
                          tabs={[
                            {
                              key: "MONTH",
                              label: "Monthly",
                              disabled: !!selectedEdition && !subscriptionOptions.options[selectedEdition].prices.MONTH,
                            },
                            {
                              key: "YEAR",
                              label: "Yearly",
                              disabled: !!selectedEdition && !subscriptionOptions.options[selectedEdition].prices.YEAR,
                            },
                          ]}
                          selected={frequency}
                          disabled={disabled}
                          onChange={setFrequency}
                        />
                        <div className={classes.freqSpacer} />

                        <div className={classes.seatsInputContainer}>
                          <TextField
                            variant="outlined"
                            size="small"
                            onChange={(e) => setDisplaySeats(e.target.value)}
                            value={displaySeats}
                            type="number"
                            disabled={disabled}
                            InputProps={{
                              classes: { input: classes.seatsInput },
                            }}
                            inputProps={{
                              min: 1,
                            }}
                          />

                          <strong>{pluralize(seats, "user")}</strong>
                        </div>
                      </div>
                    )}
                    {!team.pricingSummary.currency && (
                      <StrongSelect<SupportedCurrency>
                        classes={{ select: classes.currencySelect }}
                        className={classes.currency}
                        value={currency}
                        items={SUPPORTED_CURRENCIES_LIST.map((code) => ({
                          label: code,
                          value: code,
                        }))}
                        variant="outlined"
                        onChange={(e) => {
                          setCurrency(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  {isTeamAdmin && (
                    <Collapse in={showMinSeatWarning}>
                      <Alert
                        severity="info"
                        className={classes.seatWarningAlert}
                        onClose={() => setShowMinSeatWarning(false)}
                      >
                        You currently have {team.membershipSummary.joinedCount} team{" "}
                        {pluralize(team.membershipSummary.joinedCount, "member")}. You'll need to remove some before
                        choosing a plan with fewer seats.
                      </Alert>
                    </Collapse>
                  )}
                  <div className={clsx(classes.scrollWrapper, { [classes.scrollWrapperMobile]: mobileMode })}>
                    <EntitlementTable
                      className={clsx(classes.entitlementTable, { [classes.tableMobile]: mobileMode })}
                      highlightedEntitlement={highlightedEntitlement}
                      editions={editions}
                      showEntitlementHeaders={showEntitlementHeaders}
                      showAll={showAllEntitlements}
                      recommendedEdition={recommendedEdition}
                      style={{ width: mobileMode ? `${100 * edCount}%` : undefined, height: tableHeight }}
                      tableRef={tableRef}
                      classes={{
                        editionHeaderCell: clsx({ [classes.entitlementTableColumnMobile]: mobileMode }),
                      }}
                      renderHeaderContent={({ edition, hasOverage }) => {
                        const { isNonPaid, label: editionLabel, isLegacy } = EDITION_META[edition];

                        if (!isTeamAdmin)
                          return (
                            <Helptip
                              title={<>Contact your admin to change to a {editionLabel} plan.</>}
                              variant="info"
                            />
                          );
                        if (!subscriptionOptions) return;
                        const { prices, maxSeats } = subscriptionOptions.options[edition];
                        const pricing = prices[frequency];

                        const { availability, isCurrentPlan, monthlyPrice } = pricing || {
                          availability: "CALL_US",
                          isCurrentPlan: false,
                          monthlyPrice: undefined,
                        };

                        const isSelected = selectedEdition === edition;
                        const isCancelling = edition === "LITE" && !isCurrentPlan;

                        const showPriceDisplayMonthlyBilled = !isNonPaid && (plan.seats !== 1 || frequency === "YEAR");

                        return (
                          <div className={classes.columnHeaderContent}>
                            {monthlyPrice === undefined ? (
                              <Typography variant="body2" className={classes.unavailableText}>
                                Unavailable on {FREQUENCY_LABEL_MAP[frequency]}&nbsp;plans
                              </Typography>
                            ) : (
                              <div className={clsx(classes.editionHeaderPrice)}>
                                <div className={classes.editionHeaderContainer}>
                                  <div
                                    className={clsx(classes.editionHeaderPriceCost, {
                                      [classes.editionHeaderPriceCostLoading]: !!loadingError,
                                    })}
                                  >
                                    {(() => {
                                      if (isNonPaid) return "Free";
                                      if (loading)
                                        return (
                                          <Skeleton variant="text" style={{ display: "inline", padding: 0 }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          </Skeleton>
                                        );
                                      else
                                        return formatPennies(monthlyPrice, {
                                          hidePenniesForEvenDollars: true,
                                        });
                                    })()}
                                  </div>
                                  {!isNonPaid && (
                                    <Typography className={classes.editionHeaderPriceFreq}>/seat</Typography>
                                  )}
                                </div>
                                {showPriceDisplayMonthlyBilled && (
                                  <div className={classes.editionHeaderPricesCaption}>
                                    {loading ? (
                                      <Skeleton variant="text" style={{ display: "inline", padding: 0 }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </Skeleton>
                                    ) : (
                                      <>&nbsp;</>
                                    )}
                                    /mo {frequency === "YEAR" && <>billed&nbsp;yearly</>}
                                  </div>
                                )}
                              </div>
                            )}

                            {(() => {
                              switch (availability) {
                                case "CALL_US":
                                  return (
                                    <Button
                                      className={classes.editionButton}
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      href="https://reclaim.ai/contact"
                                      target="_blank"
                                    >
                                      Contact us
                                    </Button>
                                  );
                                default:
                                  return (
                                    <ReasonTip
                                      // These are possible blocking issues that would cause the button to disable.
                                      reasons={[
                                        {
                                          test: availability === "TOO_MANY_USERS",
                                          message: `The ${editionLabel} plan allows only ${maxSeats} users.`,
                                        },
                                        {
                                          test:
                                            !isLegacy &&
                                            seats < team.membershipSummary.activeCount &&
                                            edition !== "LITE",
                                          message: (
                                            <>
                                              Your team has {team.membershipSummary.activeCount}{" "}
                                              {pluralize(team.membershipSummary.activeCount, "user")} and you must
                                              purchase at least that many seats to use this plan.
                                            </>
                                          ),
                                        },
                                        {
                                          test: seats > maxSeats && edition !== "LITE",
                                          message: (
                                            <>
                                              You're allowed {maxSeats} {pluralize(maxSeats, "user")} in{" "}
                                              {aOrAn(editionLabel)} plan
                                            </>
                                          ),
                                        },
                                        {
                                          test: !pricing,
                                          message: `Can't purchase ${aOrAn(editionLabel)} ${editionLabel} plan with ${
                                            FREQUENCY_LABEL_MAP[frequency]
                                          } pricing.`,
                                        },
                                      ]}
                                      interactive
                                    >
                                      {(hasIssue) => (
                                        <ReasonTip
                                          // Possible non-blocking issues that we warn the user about but do not disable the button.
                                          reasons={[
                                            {
                                              test: !!hasOverage,
                                              message: DOWNGRADE_TO_NON_PAID_MESSAGE_IN_OVERAGE,
                                            },
                                            {
                                              test: seats > maxSeats && edition === "LITE",
                                              message: (
                                                <>
                                                  Your team is too large for the {editionLabel} plan. If you continue
                                                  with this plan, you will lose the ability to add team members.
                                                </>
                                              ),
                                            },
                                          ]}
                                          disabled={hasIssue}
                                          interactive
                                          noSpan
                                        >
                                          {(hasNonIssue) => {
                                            return (
                                              <Button
                                                className={clsx(classes.editionButton, {
                                                  [classes.selectedEditionButton]:
                                                    isSelected && !isCurrentPlan && !loadingError,
                                                  [classes.cancelButton]: isCancelling && !userIsOnTrial,
                                                })}
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                disabled={
                                                  hasIssue || loading || !!loadingError || isCurrentPlan || isSelected
                                                }
                                                onClick={() => setSelectedEdition(edition)}
                                                startIcon={
                                                  (hasIssue || hasNonIssue) &&
                                                  !loadingError &&
                                                  !isCurrentPlan && <WarningSvg className={classes.warningIcon} />
                                                }
                                              >
                                                {(() => {
                                                  if (isCurrentPlan) return "Current plan";
                                                  if (isCancelling && !userIsOnTrial) return "Cancel plan";
                                                  if (isCancelling && userIsOnTrial) return "Cancel trial";
                                                  if (isSelected && !isCancelling) return "Selected";
                                                  return "Select";
                                                })()}
                                              </Button>
                                            );
                                          }}
                                        </ReasonTip>
                                      )}
                                    </ReasonTip>
                                  );
                              }
                            })()}
                          </div>
                        );
                      }}
                    />
                  </div>

                  <Button
                    className={clsx(classes.showAllButton, { [classes.showAllButtonMobile]: mobileMode })}
                    onClick={toggleShowAll}
                    variant="text"
                    color="primary"
                    disabled={disabled}
                    endIcon={showAllEntitlements ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                  >
                    {showAllEntitlements ? "See fewer features" : "See all features"}
                  </Button>
                </>
              );
            }
          })()}
        </div>

        {/* Purchase Preview Section */}
        <PurchaseCheckout
          plan={plan}
          currency={currency}
          entitlementTable={entitlementTable}
          usageData={usageData}
          loading={loading}
          onClose={handleCheckoutClose}
          disableContinueReasons={
            subscriptionOptions &&
            selectedEdition &&
            (() => {
              const dispSeatsNum = Number(displaySeats);
              const { minSeats, maxSeats } = subscriptionOptions.options[selectedEdition];
              const { label } = EDITION_META[selectedEdition];

              return [
                {
                  // This shouldn't ever trigger, but it's good to have just in case
                  test: !dispSeatsNum || isNaN(dispSeatsNum),
                  message: "Invalid seat count.  Please try again...",
                },
                {
                  test: dispSeatsNum < minSeats,
                  message: `${label} plans have to have at least ${minSeats} ${pluralize(minSeats, "seat")}.`,
                },
                {
                  test: dispSeatsNum > maxSeats,
                  message: `${label} plans can't have more than ${maxSeats} ${pluralize(maxSeats, "seat")}.`,
                },
                {
                  test: displaySeats !== plan.seats.toString(),
                  message: () =>
                    `You cannot continue with ${dispSeatsNum} ${pluralize(
                      dispSeatsNum,
                      "seat"
                    )}.  Please change the number of seats, or pick a different plan.`,
                },
              ];
            })()
          }
        />
      </div>
    );
  }
);
