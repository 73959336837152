import { Button, ButtonProps } from "@mui/material";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import { cloneElement, forwardRef } from "react";
import { UIAction, UIActionObject } from "../types/uiActions";
import { UIActionElementOnClickHandler, UIActionElementPostActionHandler, useRenderUIAction } from "../utils/uiActions";
import { Link } from "./Link";
import { QuestStepWrapper } from "./quests/QuestStepWrapper";
import { Tooltip } from "./Tooltip";
import moduleClasses from "./UIActionButton.module.scss";

export type UIActionButtonCSSClassKey = keyof typeof moduleClasses;

export type UIActionButtonProps = Omit<ButtonProps, "onClick" | "children" | "classes"> & {
  classes?: SCSSClasses<UIActionButtonCSSClassKey>;
  uiAction: UIAction;
  defaults?: Partial<UIActionObject>;
  iconPosition?: "left" | "right";
  onClick?: UIActionElementOnClickHandler;
  postAction?: UIActionElementPostActionHandler;
};

export const UIActionButton = forwardRef<HTMLButtonElement, UIActionButtonProps>(
  (
    {
      className,
      uiAction,
      classes: extClasses,
      defaults,
      onClick,
      postAction,
      disabled,
      iconPosition = "left",
      color,
      ...rest
    },
    ref
  ) => {
    const classes = mergeClassNames(moduleClasses, extClasses || {});

    return useRenderUIAction<HTMLAnchorElement & HTMLButtonElement>(
      "button",
      uiAction,
      ({
        actionObject,
        isLink,
        disabled,
        handleClick,
        promiseState,
        ref,
        entitlementChip,
        entitlementMessage,
        icon,
      }) => {
        const muiColor = (actionObject.color === "destructive" ? undefined : actionObject.color) || color;
        let localIconPosition = iconPosition;
        if (actionObject.iconPosition === "reverse") localIconPosition = iconPosition === "left" ? "right" : "left";

        icon = icon && cloneElement(icon, { className: classes.icon });

        let output = (
          <Tooltip title={entitlementMessage}>
            <Button
              className={clsx(className, classes.root, classes[`root--${promiseState}`], {
                [classes["root--destructive"]]: actionObject.color === "destructive",
                [classes["root--link"]]: isLink,
              })}
              ref={ref}
              startIcon={localIconPosition === "left" && icon}
              endIcon={localIconPosition === "right" && icon}
              disabled={disabled}
              {...rest}
              variant={actionObject.variant}
              color={muiColor}
              {...(isLink
                ? ({
                    component: Link,
                    href: actionObject.action,
                    target: actionObject.target,
                  } as Partial<ButtonProps>)
                : {
                    onClick: handleClick,
                  })}
            >
              {actionObject.label}
              {entitlementChip && <>&nbsp;{entitlementChip}</>}
            </Button>
          </Tooltip>
        );

        if (actionObject.QuestStepWrapperProps)
          output = <QuestStepWrapper {...actionObject.QuestStepWrapperProps}>{output}</QuestStepWrapper>;

        return output;
      },
      { defaults, onClick, postAction, ref, forceDisable: disabled }
    );
  }
);

UIActionButton.displayName = "UIActionButton";
