import { ReactElement, useMemo } from "react";
import { EditionBadgeBase, EditionBadgeBaseProps } from "../components/billing/EditionBadgeBase";
import { EDITION_META } from "../reclaim-api/team/Team.consts";
import { EditionMeta, EntitlementType } from "../reclaim-api/team/Team.types";
import { useEntitlementTable } from "./useEntitlementTable";
import { useUsageData } from "./useUsageData";

export type UseEntitlementBadgesReturnType = {
  currentEditionMeta?: EditionMeta;
  nextEditionMeta?: EditionMeta;
  loading: boolean;
  nextEditionBadge?: () => ReactElement<EditionBadgeBaseProps>;
  currentEditionBadge: () => ReactElement<EditionBadgeBaseProps>;
};

export const useEntitlementBadges = <ENTITLEMENT extends EntitlementType>(
  entitlement: ENTITLEMENT
): UseEntitlementBadgesReturnType => {
  const hookData = useUsageData();
  const { loading: usageDataLoading, usageData } = hookData;
  const { entitlementTable, loading: entitlementTableLoading } = useEntitlementTable();

  const userCurrentEdition = usageData?.currentEdition;

  const loading = entitlementTableLoading || usageDataLoading;

  const entitlementObj =
    userCurrentEdition && (entitlementTable ? entitlementTable[userCurrentEdition][entitlement] : undefined);

  const currentEditionMeta: EditionMeta | undefined = userCurrentEdition && EDITION_META[userCurrentEdition];
  const nextEditionMeta: EditionMeta | undefined =
    entitlementObj?.nextEdition && EDITION_META[entitlementObj.nextEdition];

  const currentEditionBadge = useMemo(
    () => () => (
      <EditionBadgeBase size="slim">
        {loading ? "--" : currentEditionMeta?.label || currentEditionMeta?.label}
      </EditionBadgeBase>
    ),
    [loading, currentEditionMeta]
  );

  const nextEditionBadge = useMemo(() => {
    return !nextEditionMeta
      ? undefined
      : () => (
          <EditionBadgeBase paid={entitlementObj?.nextEdition !== "LITE"} size="slim">
            {loading ? "--" : nextEditionMeta.label || nextEditionMeta.label}
          </EditionBadgeBase>
        );
  }, [loading, nextEditionMeta, entitlementObj?.nextEdition]);

  return {
    currentEditionMeta,
    nextEditionMeta,
    nextEditionBadge,
    currentEditionBadge,
    loading,
  };
};
