import { ButtonBase, ButtonBaseProps } from "@mui/material";
import { styled } from "@mui/styles";

export type LinkButtonProps = ButtonBaseProps & {
  underline?: "none";
};

export const LinkButton = styled(({ underline, ...props }: LinkButtonProps) => (
  <ButtonBase disableRipple style={{ textDecoration: underline }} {...props} />
))(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    padding: 0,
    margin: 0,
    verticalAlign: "baseline",
    fontFamily: "Poppins",
    fontWeight: theme.typography.fontWeightMedium,

    "&:active": {
      color: theme.palette.primary.dark,
    },
  }),
  {
    classNamePrefix: "LinkButton",
  }
);
