import { useRef } from "react";
import { useEntitlementTable } from "../../../hooks/useEntitlementTable";
import { useRecommendedEdition } from "../../../hooks/useRecommendedEdition";
import { useSubscriptionOptions } from "../../../hooks/useSubscriptionOptions";
import { useUsageData } from "../../../hooks/useUsageData";
import { SupportedCurrency } from "../../../reclaim-api/team/Team.types";
import { PurchaseOMaticData, PurchaseOMaticPlanData } from "./PurchaseOMatic.types";

export const usePurchaseOMaticData = (
  plan: PurchaseOMaticPlanData,
  currency: SupportedCurrency
): PurchaseOMaticData => {
  const { entitlementTable } = useEntitlementTable();
  const { usageData } = useUsageData();

  const {
    subscriptionOptions,
    editions,
    loading: loadingSubscriptionOptions,
    error: loadingSubscriptionOptionsError,
  } = useSubscriptionOptions(plan?.seats || 1, currency);

  const { recommendedEdition, loading: loadingRecommendedEdition } = useRecommendedEdition(
    plan.seats,
    editions,
    subscriptionOptions
  );

  const subscriptionOptionsInitialLoadedRef = useRef(!loadingSubscriptionOptions);
  subscriptionOptionsInitialLoadedRef.current =
    subscriptionOptionsInitialLoadedRef.current || !loadingSubscriptionOptions;

  const initialized = subscriptionOptionsInitialLoadedRef.current;

  const loading = !initialized || loadingSubscriptionOptions || loadingRecommendedEdition;

  return {
    entitlementTable,
    usageData,
    subscriptionOptions,
    editions,
    recommendedEdition,
    initialized,
    loading,
    loadingError: loadingSubscriptionOptionsError,
  };
};
