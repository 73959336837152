import {
  Box,
  FormControl,
  FormControlProps,
  InputLabel,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import { ChangeEvent, FC } from "react";
import { Override } from "../../types";
import { RadioBoxContext } from "./RadioBox";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
    },
    content: {
      display: "flex",
    },
    vertical: {
      flexDirection: "column",
      gap: 0,
    },
    horizontal: {
      flexDirection: "row",
      gap: theme.spacing(1),
      justifyContent: "center",
    },
  }),
  {
    classNamePrefix: "RadioBoxGroupControl",
  }
);

export type RadioBoxGroupControlJSSClassKey = keyof ReturnType<typeof useStyles>;

export type RadioBoxGroupControlProps = Override<
  FormControlProps,
  {
    classes?: Partial<ClassNameMap<RadioBoxGroupControlJSSClassKey>>;
    className?: string;
    label?: string;
    RadioGroupProps?: RadioGroupProps;
    orientation?: "vertical" | "horizontal";
    onChange: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
    value?: string;
  }
>;

export const RadioBoxGroupControl: FC<RadioBoxGroupControlProps> = ({
  className,
  classes: extClasses,
  label,
  RadioGroupProps,
  orientation = "vertical",
  onChange,
  children,
  value,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <FormControl variant="standard" className={clsx(classes.root, className)} {...rest}>
      {!!label && <InputLabel>{label}</InputLabel>}
      <RadioGroup value={value} {...RadioGroupProps} onChange={onChange}>
        <Box
          className={clsx(classes.content, {
            [classes.vertical]: orientation === "vertical",
            [classes.horizontal]: orientation === "horizontal",
          })}
        >
          <RadioBoxContext.Provider value={value}>{children}</RadioBoxContext.Provider>
        </Box>
      </RadioGroup>
    </FormControl>
  );
};
