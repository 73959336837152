import { extantMap, typedKeys } from "@utils/objects";
import { useEffect, useMemo, useRef } from "react";
import { useCallbackSafeRef } from "./useCallbackSafeRef";

export type PrimaryKey =
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F"
  | "G"
  | "H"
  | "I"
  | "J"
  | "K"
  | "L"
  | "M"
  | "N"
  | "O"
  | "P"
  | "Q"
  | "R"
  | "S"
  | "T"
  | "U"
  | "V"
  | "W"
  | "X"
  | "Y"
  | "Z"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "0"
  | "k"
  | "`"
  | "-"
  | "="
  | "["
  | "]"
  | "\\"
  | "/"
  | ","
  | "."
  | "Enter"
  | "Tab"
  | "Delete"
  | "Backspace"
  | "ArrowUp"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowDown"
  | "Space"
  | "Escape";

export type ModifierKey = "Meta" | "Shift" | "Alt" | "Control";
export type KeyboardKey = PrimaryKey | ModifierKey;
export type KeyboardShortcut = KeyboardKey | KeyboardKey[];
export type ActiveKeysSet = Partial<Record<KeyboardKey, boolean>>;
export type UseShortCutHandler = (event: KeyboardEvent, keysPressed: ActiveKeysSet, isInTextInput: boolean) => void;

export type UseShortcutOptions = {
  enabled?: boolean;
  target?: HTMLElement | Document;
};

export function useShortcut(
  keys: KeyboardShortcut | undefined,
  handler: UseShortCutHandler,
  options: UseShortcutOptions = {}
) {
  let { enabled = true, target } = options;

  const timers = useRef<Partial<{ [key in KeyboardKey]: NodeJS.Timeout }>>({});
  const pressedKeys = useRef<ActiveKeysSet>({});

  const handleShortcutTrigger = useCallbackSafeRef<UseShortCutHandler>(
    (...args) => {
      if (enabled) handler(...args);
    }
  );
  
  const targetKeys = useMemo<ActiveKeysSet>(() => {
    if (!keys) return {};

    const arrKeys = Array.isArray(keys) ? keys : [keys];
    return extantMap<KeyboardKey>(Array.isArray(arrKeys) ? arrKeys : [arrKeys]);
  }, [keys]);


  const onKeyDown = useCallbackSafeRef((event: KeyboardEvent) => {
    const key = event.key as KeyboardKey;
    if (!key) return;
    if (targetKeys[key]) {
      pressedKeys.current = { ...pressedKeys.current, [key]: true };
    };

    const timer = timers.current[key];
    if (timer) clearTimeout(timer);

    if (typedKeys(targetKeys).every((k) => pressedKeys.current[k])) {
      // in case only some keys are released
      Object.keys(timers.current).forEach((k) => {
        clearTimeout(timers.current[k]);
      });

      handleShortcutTrigger(
        event,
        pressedKeys.current,
        document.activeElement?.tagName === "INPUT" || document.activeElement?.tagName === "TEXTAREA"
      );
      
      // Clear the pressed keys are executing the shortcut
      pressedKeys.current = {};
    } else {
      timers.current[key] = setTimeout(() => {
        pressedKeys.current = {};
      }, 2500);
    }
  });

  const onKeyUp = useCallbackSafeRef((event: KeyboardEvent) => {
    if (event.type === "commandbar-shortcut-executed") return (pressedKeys.current = {});
    const key = event.key as KeyboardKey;
    if (!key) return;

    const timer = timers.current[key];
    if (timer) clearTimeout(timer);

    // Key-ups don't fire while meta is held down.  It's a
    // little strange but because of the general use-case
    // of the meta key we can just clear all down keys when
    // it comes up.
    if (key === "Meta") {
      pressedKeys.current = {};
    } else {
      pressedKeys.current = { ...pressedKeys.current, [key]: false };
    }
  });

  useEffect(() => {
    const resolvedTarget = target || document;
    resolvedTarget.addEventListener("keydown", onKeyDown);
    resolvedTarget.addEventListener("keyup", onKeyUp);
    resolvedTarget.addEventListener("commandbar-shortcut-executed", onKeyUp);

    return () => {
      resolvedTarget.removeEventListener("keydown", onKeyDown);
      resolvedTarget.removeEventListener("keyup", onKeyUp);
      resolvedTarget.removeEventListener("commandbar-shortcut-executed", onKeyUp);
    };
  }, [onKeyDown, onKeyUp, target]);
}

export default useShortcut;
