import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { useCallbackSafeRef } from "./useCallbackSafeRef";
import { useErrorIfChanged } from "./useErrorIfChanged";

export type UseResizeObserverCallback = (rect: DOMRectReadOnly) => void;

export const useResizeObserver = <E extends HTMLElement | SVGElement>(
  callback: UseResizeObserverCallback,
  ref?: MutableRefObject<E | null>
): MutableRefObject<E | null> => {
  useErrorIfChanged(ref, "'ref' argument may not change");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (!ref) ref = useRef<E>(null);

  const safeCallback = useCallbackSafeRef(callback);

  const observer = useMemo(() => {
    return typeof ResizeObserver !== "undefined"
      ? new ResizeObserver(() => ref?.current && safeCallback(ref.current.getBoundingClientRect().toJSON()))
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const el = ref.current;

  useEffect(() => {
    if (el) observer?.observe(el);
    return () => {
      if (el) observer?.unobserve(el);
    };
  }, [el, observer]);

  // ResizeObserver is not firing on first run
  useEffect(() => {
    if (ref?.current) safeCallback(ref.current.getBoundingClientRect().toJSON());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return ref;
};
