import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import type { ReactNode, VFC } from "react";
import moduleClasses from "./GradientBackedVideo.module.scss";

export type GradientBackedVideoCSSClassKey = keyof typeof moduleClasses;

export type GradientBackedVideoProps = {
  classes?: SCSSClasses<GradientBackedVideoCSSClassKey>;
  className?: string;
  children: ReactNode;
};

export const GradientBackedVideo: VFC<GradientBackedVideoProps> = ({ className, classes: extClasses, children }) => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  /********************/
  /*   custom hooks   */
  /********************/

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.graphicContainer}>
        <video autoPlay loop muted>
          {children}
        </video>
      </div>
    </div>
  );
};

GradientBackedVideo.displayName = "GradientBackedVideo;";
