// eslint-disable-next-line no-restricted-imports
import {
  DateCalendar as MuiDateCalendar,
  DateCalendarProps as MuiDateCalendarProps,
} from "@mui/x-date-pickers/DateCalendar";
import { Override } from "@typeDefs/index";
import { DateCalendarStylesWrapper, DateCalendarStylesWrapperProps } from "./DateCalendarStylesWrapper";

export type DateCalendarProps<T_DATE extends unknown> = Override<
  MuiDateCalendarProps<T_DATE>,
  { variant?: DateCalendarStylesWrapperProps["variant"] }
>;

export const DateCalendar = <T_DATE extends unknown>({
  views = ["day"],
  variant,
  ...rest
}: DateCalendarProps<T_DATE>) => (
  <DateCalendarStylesWrapper variant={variant}>
    <MuiDateCalendar views={views} {...rest} />
  </DateCalendarStylesWrapper>
);
