import { useEffect, useMemo, useState } from "react";
import { reclaim } from "../reclaim-api";
import { EDITION_META } from "../reclaim-api/team/Team.consts";
import { ReclaimEdition, SubscriptionOptions, SupportedCurrency } from "../reclaim-api/team/Team.types";
import { reclaimEditionComparator } from "../reclaim-api/team/Team.utils";
import { typedKeys } from "../utils/objects";
import { useTeamState } from "./atoms/useTeam";
import { useCallbackSafeRef } from "./useCallbackSafeRef";
import { useDebounce } from "./useDebounce";

const MILLISECONDS = 500;

export const useSubscriptionOptions = (quantity: number, currency?: SupportedCurrency) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [subscriptionOptions, setSubscriptionOptions] = useState<SubscriptionOptions | undefined>(undefined);
  const teamCurrency = useTeamState().team.pricingSummary.currency;

  const fetchSubscriptionOptions = useCallbackSafeRef(async (quantity: number, currency?: SupportedCurrency) => {
    currency = currency || teamCurrency;

    try {
      const options = await reclaim.team.getSubscriptionOptions(quantity, currency);
      setError(undefined);
      setSubscriptionOptions(options);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  });

  const debouncedFetch = useDebounce(fetchSubscriptionOptions, MILLISECONDS);

  const callFetch = useCallbackSafeRef<typeof fetchSubscriptionOptions>(async (...args) => {
    setLoading(true);
    await debouncedFetch(...args);
  });

  const editions = useMemo<ReclaimEdition[]>(() => {
    if (!subscriptionOptions) return [];
    const legacyEditions = typedKeys(subscriptionOptions.options).filter((e) => EDITION_META[e].isLegacy);
    const newEditions = typedKeys(subscriptionOptions.options).filter((e) => !EDITION_META[e].isLegacy);
    return [...legacyEditions.sort(reclaimEditionComparator), ...newEditions.sort(reclaimEditionComparator)];
  }, [subscriptionOptions]);

  useEffect(() => {
    void callFetch(quantity, currency);
  }, [quantity, callFetch, currency]);

  return {
    subscriptionOptions,
    editions,
    loading,
    error,
  };
};
