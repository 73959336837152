import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { ReactNode, VFC } from "react";
import { mapTimes } from "../utils/loop";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "inline",
    },
    loading: {
      color: "transparent",
    },
  }),
  {
    classNamePrefix: "TextSkeleton",
  }
);

export type TextSkeletonJSSClassKey = keyof ReturnType<typeof useStyles>;

export type TextSkeletonProps = {
  classes?: Partial<ClassNameMap<TextSkeletonJSSClassKey>>;
  className?: string;
  text: ReactNode | undefined;
  textLength?: number;
  placeholderText?: string;
};

export const TextSkeleton: VFC<TextSkeletonProps> = ({
  className,
  classes: extClasses,
  text,
  textLength,
  placeholderText,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */

  /********************/

  const loading = text === undefined;
  
  text = text === undefined ? placeholderText : text;
  if (text === undefined) text = mapTimes(typeof textLength === "number" ? textLength : 10, () => "a");

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return loading ? (
    <Skeleton className={clsx(classes.root, className, classes.loading)} variant="text">
      {text}
    </Skeleton>
  ) : (
    <span className={clsx(classes.root, className)}>{text}</span>
  );
};
