import { Typography, TypographyProps } from "@mui/material";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import React, { ReactElement, ReactNode, VFC } from "react";
import { UIAction } from "../types/uiActions";
import moduleClasses from "./GenericInfoMessage.module.scss";
import { UIActionButton } from "./UIActionButton";

export type GenericInfoMessageCSSClassKey = keyof typeof moduleClasses;

export type GenericInfoMessageProps = {
  classes?: SCSSClasses<GenericInfoMessageCSSClassKey>;
  className?: string;
  icon?: ReactElement<{ className: string }>;
  headerImage?: ReactElement;
  title?: ReactNode;
  message: ReactNode;
  type?: "default" | "error" | "corn";
  size?: "large" | "medium" | "small" | "extraSmall";
  align?: "top" | "center";
  headerVariant?: TypographyProps["variant"];
  messageVariant?: TypographyProps["variant"];
  titleWrapperIsDiv?: boolean;
  messageWrapperIsDiv?: boolean;
  uiActions?: UIAction[];
};

export const GenericInfoMessage: VFC<GenericInfoMessageProps> = ({
  className,
  classes: extClasses,
  icon,
  title,
  message,
  type = "default",
  size = "small",
  align = "top,",
  headerVariant,
  messageVariant = "subtitle1",
  messageWrapperIsDiv,
  titleWrapperIsDiv,
  headerImage,
  uiActions,
}) => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  return (
    <div className={clsx({ [classes.centered]: align === "center" })}>
      <div
        className={clsx(classes.root, className, {
          [classes["root--small"]]: size === "small",
          [classes["root--large"]]: size === "large",
          [classes["root--extraSmall"]]: size === "extraSmall",
          [classes["root--error"]]: type === "error",
          [classes["root--corn"]]: type === "corn",
        })}
      >
        {headerImage}
        {icon && (
          <div className={classes.iconWrap}>
            {React.cloneElement(icon, { className: clsx(icon.props.className, classes.iconWrap__icon) })}
          </div>
        )}
        {!!title && (
          <Typography
            className={classes.header}
            // `component` has weird typing and can't be passed `undefined`
            {...(titleWrapperIsDiv ? { component: "div" } : {})}
            variant={headerVariant || "h1"}
          >
            {title}
          </Typography>
        )}
        <Typography
          className={classes.subtitle}
          // `component` has weird typing and can't be passed `undefined`
          {...(messageWrapperIsDiv ? { component: "div" } : {})}
          variant={messageVariant}
        >
          {message}
        </Typography>
        {uiActions && (
          <div className={classes.actions}>
            {uiActions.map((uiAction, i) => (
              <UIActionButton
                key={i}
                uiAction={uiAction}
                color="primary"
                variant={i === 0 ? "contained" : "text"}
                size={size === "extraSmall" ? "small" : size}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
