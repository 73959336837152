/**
 * Auth
 */
export const LOCAL_STORAGE_AUTH_USER = "auth.user";
export const LOCAL_STORAGE_AUTH_REAUTH = "auth.reauth";
export const LOCAL_STORAGE_AUTH_ATTEMPTS = "auth.attempts";
export const LOCAL_STORAGE_AUTH_REDIRECT = "auth.redirect";
export const LOCAL_STORAGE_AUTH_REF = "auth.ref";
export const LOCAL_STORAGE_AUTH_UTM = "auth.utm";
export const LOCAL_STORAGE_AUTH_ADMIN = "auth.admin";
export const LOCAL_STORAGE_AUTH_IMPERSONATE = "auth.impersonate";
export const LOCAL_STORAGE_AUTH_IMPERSONATE_ERROR = "auth.impersonate.error";

/**
 * Quests
 */
export const LOCAL_STORAGE_ACTIVE_QUESTS = "Quests.active";

/**
 * Tasks
 */
export const LOCAL_STORAGE_TASKS_QUICK_ADD_SECTIONS = "Reclaim.Tasks.QuickAddSections";
export const LOCAL_STORAGE_TASKS_QUICK_ADD_DATA = "Reclaim.Tasks.QuickAddData";
export const LOCAL_STORAGE_TASKS_SPLIT_UP_CHUNKS = "Reclaim.Tasks.SplitUp";
export const LOCAL_STORAGE_TASKS_LIST_SORT = "Tasks.Sort";

/**
 * Priority
 */
export const LOCAL_STORAGE_PRIORITY_ACTIVE_ACCORDION = "Priority.AccordionState";
export const LOCAL_STORAGE_PRIORITY_COLUMN_WIDTH = "Priority.ColumnWidth";

/**
 * Planner
 */
export const LOCAL_STORAGE_PLANNER_SIDEBAR_TAB = "Planner.Sidebar.Tab";
export const LOCAL_STORAGE_PLANNER_RANGE = "Planner.Range";

/**
 * Scheduling Link Booking Page
 */
export const LOCAL_STORAGE_BOOKING_PAGE_SHOW_AVAILABILITY = "Booking.ShowAvailability";
