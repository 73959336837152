import { TextField, TextFieldProps } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ChangeEventHandler, VFC } from "react";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    textField: {
      flex: "1 1 auto",
      alignItems: "stretch",
      justifyContent: "stretch",
    },
    input: {
      flex: "1 1 auto",
      alignItems: "stretch",
    },
    count: {
      ...theme.typography.body2,
      flex: "0 0 auto",
      color: theme.colors.textGrey,
      textAlign: "right",
      paddingTop: theme.spacing(0.5),
    },
    countWarn: {
      color: theme.colors.warning.main,
    },
    countError: {
      color: theme.colors.error.main,
    },
  }),
  {
    classNamePrefix: "CappedTextArea",
  }
);

export type CappedTextAreaJSSClassKey = keyof ReturnType<typeof useStyles>;
export type CappedTextAreaChangeHandler = (value: string) => unknown;

export type CappedTextAreaProps = Omit<TextFieldProps, "classes" | "value" | "onChange" | "multiline"> & {
  classes?: Partial<ClassNameMap<CappedTextAreaJSSClassKey>>;
  className?: string;
  TextFieldProps?: TextFieldProps["classes"];
  value?: string;
  onChange: CappedTextAreaChangeHandler;
  maxLength: number;
  singleLine?: boolean;
};

export const CappedTextArea: VFC<CappedTextAreaProps> = ({
  className,
  classes: extClasses,
  style,
  TextFieldProps,
  value = "",
  onChange,
  maxLength,
  singleLine,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  const handleChange = useCallbackSafeRef<ChangeEventHandler<HTMLInputElement>>((e) => {
    if (e.target.value.length <= maxLength) onChange(e.target.value);
  });

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div className={clsx(classes.root, className)} style={style}>
      <TextField
        variant="standard"
        className={classes.textField}
        classes={TextFieldProps}
        multiline={!singleLine}
        onChange={handleChange}
        InputProps={{
          className: classes.input,
        }}
        value={value}
        {...rest}
      />
      {!!value && (
        <div
          className={clsx(classes.count, {
            [classes.countWarn]: value.length > 0.75 * maxLength && value.length < maxLength,
            [classes.countError]: value.length === maxLength,
          })}
        >
          {value.length}/{maxLength}
        </div>
      )}
    </div>
  );
};
