import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import { FC } from "react";
import moduleClasses from "./DateCalendarStylesWrapper.module.scss";

export type DateCalendarStylesWrapperCSSClassKey = keyof typeof moduleClasses;

export type DateCalendarStylesWrapperProps = {
  classes?: SCSSClasses<DateCalendarStylesWrapperCSSClassKey>;
  variant?: "default" | "micro";
};

export const DateCalendarStylesWrapper: FC<DateCalendarStylesWrapperProps> = ({
  classes: extClasses,
  variant = "default",
  children,
}) => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  return <div className={clsx(classes.root, { [classes["root--micro"]]: variant === "micro" })}>{children}</div>;
};
