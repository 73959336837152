export const LOCAL_LINK_PATTERNS: (RegExp | string)[] = [
  /^(\w+\.)?api\.reclaim(\-test)?\.com/,
  /^api\.app\.reclaim\.ai/,
];

export function urlWithQueryParams(url: string, queryParams: Record<string, string | undefined>): string {
  const urlWithProtocol = url.startsWith("https://") || url.startsWith("http://") ? url : `https://${url}`;
  const patchedUrl = new URL(urlWithProtocol);
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      patchedUrl.searchParams.append(key, value);
    }
  });
  return patchedUrl.toString();
}

export const queryObjectToStrParams = <T extends string | number>(
  params: Record<string, T | undefined> | undefined
): string => {
  if (params === undefined) return "";

  const strQuery = Object.keys(params).reduce(
    (values, k) => (!!params[k] ? [...values, `${k}=${params[k]}`] : values),
    []
  );

  return strQuery.length ? "?" + strQuery.join("&") : "";
};

export const isSameDomain = (pathname: string) => {
  if (typeof window === "undefined") return true;
  if (!pathname) return true;

  // These pathnames can be from external sources so verify they are valid
  // with a try/catch.
  try {
    const { hostname } = new URL(pathname, window.location.href);
    if (hostname === window.location.hostname) return true;
    return !LOCAL_LINK_PATTERNS.every((regex) =>
      typeof regex === "string" ? regex !== hostname : !hostname.match(regex)
    );
  } catch (error) {
    return true;
  }
};
