import { useModal } from "mui-modal-provider";
import ConfettiExplosion from "react-confetti-explosion";

export const useConfettiCannon = () => {
    const {showModal} = useModal()
      return { fireConfettiCannon: () => {
        const {hide, destroy} = showModal(ConfettiExplosion, {
            particleCount: 200,
            colors: [
                'rgb(82, 99, 243)',
                'rgb(238, 154, 178)',
                'rgb(255, 197, 80)',
                'white',
                'black'
                ],
            width: 2000,
        });

        setTimeout(() => {
            hide();
            destroy()
        }, 5000)
      } }
}