import { Button, SvgIcon, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import { ChangeEventHandler, useMemo, useState, VFC } from "react";
import { useTeamState } from "../../hooks/atoms/useTeam";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { useIntegrationsEnabledMap } from "../../hooks/useIntegrationsEnabledMap";
import { useRecommendedEdition } from "../../hooks/useRecommendedEdition";
import { useSubscriptionOptions } from "../../hooks/useSubscriptionOptions";
import { useUsageData } from "../../hooks/useUsageData";
import SendIcon from "../../img/send-icon.svg";
import { reclaim } from "../../reclaim-api";
import { MembershipRole } from "../../reclaim-api/team/Team";
import { EDITION_META } from "../../reclaim-api/team/Team.consts";
import { getMailtoUrl } from "../../utils/router";
import { AsyncButton } from "../forms/AsyncButton";
import { RadioBox } from "../forms/RadioBox";
import { RadioBoxGroupControl } from "../forms/RadioBoxGroupControl";
import { TextSkeleton } from "../TextSkeleton";
import { OverageList } from "./OverageList";
import { SeePlansButton } from "./SeePlansButton";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      textAlign: "center",
      "& > h1, & > h2, & > h3, & > h4, & > h5, & > h6": {
        marginBottom: theme.spacing(),
      },
      "& > p": {
        marginBottom: theme.spacing(3),
      },
    },
    radioGroup: { textAlign: "left" },
    actionButton: {
      margin: theme.spacing(1, 0, 2),
    },
  }),
  {
    classNamePrefix: "OverageOMatic",
  }
);

type OverageOMaticSelectionState = "request" | "disable" | "downgrade";
export type OverageOMaticJSSClassKey = keyof ReturnType<typeof useStyles>;

export type OverageOMaticProps = {
  classes?: Partial<ClassNameMap<OverageOMaticJSSClassKey>>;
  className?: string;
  onComplete?(): void;
};

export const OverageOMatic: VFC<OverageOMaticProps> = ({ className, classes: extClasses, onComplete }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/
  const { team, userMembership } = useTeamState();

  const { subscriptionOptions, editions } = useSubscriptionOptions(team.membershipSummary.paidCount);

  const { recommendedEdition } = useRecommendedEdition(team.membershipSummary.paidCount, editions, subscriptionOptions);
  const { reloadUsageData } = useUsageData();
  const { reloadIntegrationsEnabledMap } = useIntegrationsEnabledMap();

  /********************/
  /*     useState     */
  /********************/

  const [selection, setSelection] = useState<OverageOMaticSelectionState>("request");

  /********************/
  /* useMemo & consts */
  /********************/

  const admins = useMemo(
    () => team.members.filter(({ membershipRole }) => membershipRole === MembershipRole.Admin),
    [team.members]
  );

  const userIsAdmin = userMembership.membershipRole === MembershipRole.Admin;

  /********************/
  /*    useCallback   */
  /********************/

  const handleChange = useCallbackSafeRef<ChangeEventHandler<HTMLInputElement>>(({ target: { value } }) =>
    setSelection(value as OverageOMaticSelectionState)
  );

  const emailAdmins = useCallbackSafeRef(() => {
    if (!admins) return;
    const recipients = admins.map(({ email }) => email);
    window.open(getMailtoUrl(recipients));
  });

  const downgradeHardly = useCallbackSafeRef(async () => {
    await reclaim.users.downgradeHardly();
    await reloadUsageData();
    await reloadIntegrationsEnabledMap();
    onComplete?.();
    window.location.reload();
  });

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div className={clsx(classes.root, className)}>
      <Typography variant="h3">Fix your account</Typography>
      <Typography>
        Your account is limited because you still have some features enabled that are outside of your plan limit.{" "}
        <strong>Here are your options:</strong>
      </Typography>
      <RadioBoxGroupControl onChange={handleChange} value={selection} className={classes.radioGroup}>
        <RadioBox
          value="request"
          header="Upgrade your plan"
          checkedContent={
            userIsAdmin ? (
              <>
                <Typography variant="body2">
                  Move to the{" "}
                  <TextSkeleton
                    text={recommendedEdition && EDITION_META[recommendedEdition].label}
                    placeholderText="Starter"
                  />{" "}
                  plan to unlock more scheduling power.
                </Typography>
                <SeePlansButton className={classes.actionButton} onClick={onComplete}>
                  See plans
                </SeePlansButton>
              </>
            ) : (
              <>
                <Typography variant="body2">
                  Reclaim can send your team admin a note letting them know that you'd like to keep your paid features
                  by upgrading your account.
                </Typography>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  color="primary"
                  onClick={emailAdmins}
                  startIcon={
                    <SvgIcon>
                      <SendIcon />
                    </SvgIcon>
                  }
                  size="small"
                >
                  Send note
                </Button>
              </>
            )
          }
        />
        <RadioBox
          value="disable"
          header="Manually disable feature overage"
          checkedContent={
            <>
              <Typography variant="body2">Here's what you'll need to do to fix your account:</Typography>
              <OverageList />
            </>
          }
        />
        <RadioBox
          value="downgrade"
          header="Automatically disable feature overage"
          checkedContent={
            <>
              <Typography variant="body2">
                Reclaim can disable these features for you, though it may not choose exactly what you would want to
                disable.
              </Typography>
              <AsyncButton
                className={classes.actionButton}
                variant="contained"
                color="primary"
                onClick={downgradeHardly}
                size="small"
              >
                Disable for me
              </AsyncButton>
            </>
          }
        />
      </RadioBoxGroupControl>
    </div>
  );
};
