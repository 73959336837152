import { Button } from "@mui/material";
import { useOverageModal } from "./modals/OverageModal";
import classes from "./PlanLockedMessage.module.scss";

export const PlanLockedMessage = () => {
  const openOverageModal = useOverageModal();
  return (
    <>
      You're using more features than your plan allows.{" "}
      <Button onClick={() => openOverageModal()} className={classes.fixButton}>
        fix it
      </Button>
    </>
  );
};
