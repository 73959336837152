import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import React, { ChangeEvent, FocusEvent, MutableRefObject, useCallback, useMemo } from "react";
import { FieldError, FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form";
import { IRhfControl, RhfControlProps } from ".";

export type TextFieldControlProps = MuiTextFieldProps &
  RhfControlProps & {
    register?: UseFormRegister<FieldValues>;
    options?: RegisterOptions;
    errors?: FieldError;
  };

/**
 * Controlled field for text entry
 * @param props
 * @deprecated use `RhfTextField` instead
 */
export const TextFieldControl: IRhfControl<TextFieldControlProps> = ({
  name,
  value,
  errors,
  register,
  options = {},
  inputRef: extInputRef,
  ...rest
}) => {
  const opts = useMemo<typeof options>(() => {
    const merged = {
      ...options,
      required: !!rest?.required ? "Required" : options.required,
    };

    if (undefined !== value) merged.value = value;

    return merged;
  }, [options, rest?.required, value]);

  const helperText = useMemo(() => errors?.message || rest.helperText, [errors?.message, rest.helperText]);

  const { ref: inputRef, onChange, onBlur, ...inputProps } = register!(name, opts);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      void onChange(e);
      void rest.onChange?.(e);
    },
    [onChange, rest]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      void onBlur(e);
      void rest.onBlur?.(e);
    },
    [onBlur, rest]
  );

  return (
    <MuiTextField
      variant="standard"
      {...rest}
      {...inputProps}
      inputRef={(ref) => {
        inputRef(ref);

        if (!extInputRef) return;
        switch (typeof extInputRef) {
          case "object":
            (extInputRef as MutableRefObject<HTMLInputElement>).current = ref;
            break;
          case "function":
            extInputRef(ref);
            break;
        }
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!errors}
      helperText={helperText} />
  );
};

TextFieldControl.isControl = true;
