const OAUTH_BASE_URI = process.env.NEXT_PUBLIC_OAUTH_BASE_URI;

export function initiateOauth(integration: string): void;
export function initiateOauth(integration: string, authPath: string): void;
export function initiateOauth(integration: string, state: Record<string, unknown>): void;
export function initiateOauth(integration: string, authPath: string, state: Record<string, unknown>): void;
export function initiateOauth(
  integration: string,
  authPathOrState?: string | Record<string, unknown>,
  state?: Record<string, unknown>
): void {
  let authPath: string = "";

  switch (typeof authPathOrState) {
    case "string":
      authPath = authPathOrState.replace(/^\//, "");
      break;
    case "object":
      state = authPathOrState;
  }

  authPath = authPath || `${integration}/init`;
  state = state || {};

  const authUrl = new URL(`${OAUTH_BASE_URI}/${authPath}/`);
  const redirect = `/settings/integrations/${integration}`;

  authUrl.searchParams.append("state", JSON.stringify({ redirect, ...state }));
  window.location.href = authUrl.toString();
}
