import { AssignmentType } from "@typeDefs/index";
import { MILLISECONDS_PER_MINUTE } from "@utils/dates";
import { getAssignmentIdAndType } from "@utils/events";
import { addDays, addWeeks, endOfDay, startOfDay } from "date-fns";
import { atom, useAtom } from "jotai";
import { reclaim } from "src/reclaim-api";
import { Event } from "src/reclaim-api/Events";
import { pipe, subscribe } from "wonka";
import { subscriptionT } from "wonka/dist/types/src/Wonka_types.gen";

export type EventsSourceAssignmentMap = Record<AssignmentType, Record<number | string, Event[]>>;

type EventsSourcePayload = { events?: Event[]; loading: boolean };
export type UseEventsSourceReturnType = EventsSourcePayload & { assignmentMap?: EventsSourceAssignmentMap };

const toAssignmentMap = (events: Event[]): EventsSourceAssignmentMap => {
  const value: EventsSourceAssignmentMap = {
    task: {},
    habit: {},
    "one-on-one": {},
    "scheduling-link": {},
  };

  events.forEach((e) => {
    const { assignmentId, type } = getAssignmentIdAndType(e);
    if (value[type]) {
      value[type][assignmentId] = [...(value[type as AssignmentType][assignmentId] || []), e];
    }
  });

  return value;
};

const dataAtom = atom<Event[] | undefined>(undefined);
const assignmentAtom = atom<EventsSourceAssignmentMap | undefined>(undefined);
const loadingAtom = atom<boolean>(true);

const sourceAtom = atom<EventsSourcePayload, UseEventsSourceReturnType>(
  (get) => ({ events: get(dataAtom), loading: get(loadingAtom), assignmentMap: get(assignmentAtom) }),
  (_, set, payload) => {
    set(dataAtom, payload.events);
    set(assignmentAtom, payload.events ? toAssignmentMap(payload.events) : undefined);
    set(loadingAtom, payload.loading);
  }
);

sourceAtom.onMount = (set) => {
  const INTERVAL_MS = MILLISECONDS_PER_MINUTE * 10; // 10 minutes
  let lastSync: number | undefined;
  let subscription: subscriptionT | undefined;
  let intervalId: NodeJS.Timer | undefined;

  const updateSubscription = () => {
    lastSync = Date.now();

    if (subscription) {
      subscription.unsubscribe();
    }

    subscription = pipe(
      reclaim.events.listAndWatch$$({
        sourceDetails: true,
        start: startOfDay(addDays(new Date(), -30)),
        end: endOfDay(addWeeks(new Date(), 12)),
      }),
      subscribe((events) => set({ events, loading: false }))
    );
  };

  updateSubscription();
  intervalId = setInterval(updateSubscription, INTERVAL_MS);

  // pause interval syncs when tab is not visible. When the tab becomes visible re-sync
  // the subscription if the last sync was longer than the interval.
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      clearInterval(intervalId);
    } else {
      if (!lastSync || Date.now() - INTERVAL_MS > lastSync) {
        updateSubscription();
      }
      intervalId = setInterval(updateSubscription, INTERVAL_MS);
    }
  });

  return () => {
    clearInterval(intervalId);
    subscription?.unsubscribe();
  };
};

/**
 * This hook loads all user events 30 days in the past to 12 weeks into the future and watches for
 * changes in those ranges.
 */
export const useEventsSource = (): UseEventsSourceReturnType => {
  const [source] = useAtom(sourceAtom);
  return source;
};
