import { Typography } from "@mui/material";
import { reclaim } from ".";
import { Link } from "../components/Link";
import GoogleCalendarLogo from "../img/gcal-icon.svg";
import AsanaLogo from "../img/integrations/asana-logo.svg";
import ClickUpLogo from "../img/integrations/clickup-icon.svg";
import GoogleTasksLogo from "../img/integrations/google-tasks-icon.svg";
import JiraLogo from "../img/integrations/jira-software-icon.svg";
import LinearLogo from "../img/integrations/linear-icon.svg";
import MondayLogo from "../img/integrations/monday-com-logo.svg";
import Office365Logo from "../img/integrations/office-365.svg";
import RaycastLogo from "../img/integrations/raycast.svg";
import SlackLogo from "../img/integrations/slack-icon.svg";
import TodoistLogo from "../img/integrations/todoist-logo.svg";
import TrelloLogo from "../img/integrations/trello-icon.svg";
import ZoomLogo from "../img/integrations/zoom-icon.svg";
import { initiateOauth } from "../utils/oauth";
import { SortStateComparator } from "../utils/sort";
import { AuthMode } from "./Credentials";
import {
  IntegrationMeta,
  IntegrationType,
  TimeSchemeIntegrationRuleCreateRequest,
  TimeSchemeIntegrationRuleServiceType,
} from "./Integrations.types";
import { InterestTrait } from "./Users";
import { ReclaimEdition } from "./team/Team.types";

export const SLACK_APP_ID = process.env.NEXT_PUBLIC_SLACK_APP_ID;
export const OAUTH_BASE_URI = process.env.NEXT_PUBLIC_OAUTH_BASE_URI;
export const JIRA_MARKETPLACE_URL =
  "https://marketplace.atlassian.com/apps/1227309/task-planner-for-google-calendar-reclaim-ai?tab=overview&hosting=cloud";

export const INTEGRATIONS_META: Readonly<Record<IntegrationType, IntegrationMeta>> = {
  ADD_ON: {
    label: () => "Google Add-On",
    description: () =>
      "Install Reclaim's Google Calendar Add-on to manage your Tasks, Habits, & Smart 1:1s directly from Google Calendar.",
    icon: () => <GoogleCalendarLogo />,
    entitlement: "INTEGRATION_GOOGLE_TASKS",
    unconnectedActions: () => ({
      label: "Connect Google Add-On",
      action: "//workspace.google.com/marketplace/app/reclaimai/950518663892",
      changesConnectedStatus: true,
    }),
    connectedActions: () => ({
      label: "Add-on Help",
      action: "https://help.reclaim.ai/en/collections/3755450-google-calendar-add-on",
    }),
  },
  RAYCAST: {
    label: () => "Raycast",
    description: () =>
      "AI extension for Mac — quickly create Tasks & Scheduling Links, view your calendar, start Tasks & Habits, & join meetings.",
    icon: () => <RaycastLogo />,
    entitlement: "INTEGRATION_RAYCAST",
    unconnectedActions: () => ({
      label: "Connect Raycast",
      action: "/settings/integrations/raycast",
    }),
    connectedActions: () => [],
  },
  GOOGLE_TASKS: {
    label: () => "Google Tasks",
    description: () => "Sync tasks both ways between Google Tasks and Reclaim.",
    icon: () => <GoogleTasksLogo />,
    entitlement: "INTEGRATION_GOOGLE_TASKS",
    unconnectedActions: ({ router }) => ({
      label: "Connect Google Tasks",
      action: async () => {
        const res = await reclaim.credentials.getPrimary();

        if (!res?.id) return;

        return reclaim.credentials.expand(res.id, AuthMode.Tasks, {
          redirect: `${router.asPath}/google-tasks`,
        });
      },
      changesConnectedStatus: true,
    }),
    connectedActions: () => ({
      label: "Integration Help",
      action: "https://help.reclaim.ai/en/articles/4293077-google-tasks-integration-overview",
    }),
  },
  SLACK: {
    label: () => "Slack",
    description: () => "Receive notifications and sync your Slack status to your calendar automatically.",
    icon: () => <SlackLogo />,
    entitlement: "INTEGRATION_SLACK",
    unconnectedActions: () => ({
      label: "Add to Slack",
      action: `${reclaim.client.baseUrl}/slack/add`,
      changesConnectedStatus: true,
    }),
    connectedActions: () => [
      {
        label: "Open In Slack",
        action: `https://slack.com/app_redirect?app=${SLACK_APP_ID}`,
      },
      {
        label: "Uninstall",
        action: "https://help.reclaim.ai/en/articles/6935794-how-to-uninstall-the-slack-integration",
        changesConnectedStatus: true,
        undisableable: true,
      },
    ],
  },
  ZOOM: {
    label: () => "Zoom",
    description: () => "Automatically update events and Slack status when you join/leave Zoom meetings.",
    icon: () => <ZoomLogo />,
    entitlement: "INTEGRATION_ZOOM",
    unconnectedActions: () => ({
      label: "Connect Zoom",
      action: `${OAUTH_BASE_URI}/zoom/init`,
      changesConnectedStatus: true,
    }),
    connectedActions: () => ({
      label: "Integration Help",
      action: "https://help.reclaim.ai/en/articles/5413550-zoom-integration-overview",
    }),
  },
  OFFICE365: {
    label: () => "Office365",
    description: () => (
      <>
        <Link
          color="primary"
          href="https://help.reclaim.ai/en/articles/3640324-sharing-your-calendar-including-non-google-calendars-with-your-work-calendar-to-block-events"
          target="reclaim-help"
        >
          Read our guide
        </Link>{" "}
        on syncing an O365 calendar to your Reclaim calendar.
      </>
    ),
    icon: () => <Office365Logo />,
    entitlement: "INTEGRATION_OFFICE_365",
    isFuture: true,
    interestTrait: InterestTrait.Office365,
  },
  TODOIST: {
    label: () => "Todoist",
    description: () => "Sync your Todoist items to Reclaim and get them scheduled as tasks.",
    icon: () => <TodoistLogo />,
    entitlement: "INTEGRATION_TODOIST",
    unconnectedActions: () => ({
      label: "Connect Todoist",
      action: () => initiateOauth("todoist"),
      changesConnectedStatus: true,
    }),
    connectedActions: () => ({
      label: "Settings",
      action: "./integrations/todoist",
      undisableable: true,
    }),
  },
  TRELLO: {
    label: () => "Trello",
    description: () => "We're working on integrating Trello with Reclaim.",
    icon: () => <TrelloLogo />,
    entitlement: "INTEGRATION_TRELLO",
    isFuture: true,
    interestTrait: InterestTrait.Trello,
  },
  LINEAR: {
    label: () => "Linear",
    description: () => "Sync your Linear issues to Reclaim and get them scheduled as tasks.",
    icon: () => <LinearLogo />,
    entitlement: "INTEGRATION_LINEAR",
    unconnectedActions: () => ({
      label: "Connect Linear",
      action: () => initiateOauth("linear"),
      changesConnectedStatus: true,
    }),
    connectedActions: () => ({
      label: "Settings",
      action: "./integrations/linear",
      undisableable: true,
    }),
  },
  JIRA: {
    label: () => "Jira",
    description: () => "Sync your Jira issues to Reclaim and get them scheduled as tasks.",
    icon: () => <JiraLogo />,
    entitlement: "INTEGRATION_JIRA",
    unconnectedActions: () => ({
      label: "Connect Jira",
      action: JIRA_MARKETPLACE_URL,
      changesConnectedStatus: true,
    }),
    connectedActions: () => ({
      label: "Settings",
      action: "./integrations/jira",
      undisableable: true,
    }),
  },
  ASANA: {
    label: () => "Asana",
    description: () => "Sync your Asana work to Reclaim and get them scheduled as tasks.",
    icon: () => <AsanaLogo />,
    entitlement: "INTEGRATION_ASANA",
    unconnectedActions: () => ({
      label: "Connect Asana",
      action: () => initiateOauth("asana", "asana/install"),
      changesConnectedStatus: true,
    }),
    connectedActions: () => ({
      label: "Settings",
      action: "./integrations/asana",
      undisableable: true,
    }),
    helpText: () => (
      <>
        <Typography variant="body2">
          For a complete walk-through of the Asana integration, check out our{" "}
          <Link
            color="primary"
            underline="none"
            href="https://help.reclaim.ai/en/articles/6064146-asana-integration-overview"
            target="_blank"
            rel="noreferrer"
          >
            Overview
          </Link>.{" "}
          If you're unable to connect Asana, talk to your admin. You may need more permissions enabled in Asana.
        </Typography>
      </>
    ),
  },
  CLICKUP: {
    label: () => "ClickUp",
    description: () => "Sync your ClickUp items to Reclaim and get them scheduled as tasks.",
    icon: () => <ClickUpLogo />,
    entitlement: "INTEGRATION_CLICKUP",
    unconnectedActions: () => ({
      label: "Connect ClickUp",
      action: () => initiateOauth("clickup"),
      changesConnectedStatus: true,
    }),
    connectedActions: () => ({
      label: "Settings",
      action: "./integrations/clickup",
      undisableable: true,
    }),
  },
  MONDAY: {
    label: () => "Monday.com",
    description: () => "We're working on integrating Monday.com with Reclaim.",
    icon: () => <MondayLogo />,
    entitlement: "INTEGRATION_MONDAY",
    isFuture: true,
    interestTrait: InterestTrait.Monday,
  },
};

export const integrationMetaComparator: SortStateComparator<IntegrationType> = (a, b) => {
  if ((INTEGRATIONS_META[a] as { isBeta: boolean }).isBeta) return -1;
  if ((INTEGRATIONS_META[b] as { isBeta: boolean }).isBeta) return 1;
  if (INTEGRATIONS_META[a].isFuture) return 1;
  if (INTEGRATIONS_META[b].isFuture) return -1;
  return 0;
};

export const LEGACY_INTEGRATION_EDITIONS: readonly ReclaimEdition[] = ["ASSISTANT", "PRO", "TEAM"];
export const INTEGRATION_EDITIONS: readonly ReclaimEdition[] = ["LITE", "STARTER", "BUSINESS", "ENTERPRISE"];

export const DEFAULT_TODOIST_RULE: TimeSchemeIntegrationRuleCreateRequest = {
  labels: [],
  projectIds: ["ROOT"],
  action: "SYNC_TO",
  serviceType: "TODOIST",
  ruleType: "CASE",
  timeSchemeId: null,
};

export const DEFAULT_CLICKUP_RULE: TimeSchemeIntegrationRuleCreateRequest = {
  labels: [],
  projectIds: [],
  action: "SYNC_TO",
  serviceType: "CLICK_UP",
  ruleType: "CASE",
  timeSchemeId: null,
};

export const INTEGRATION_LABELS_META: Readonly<
  Record<
    TimeSchemeIntegrationRuleServiceType,
    Readonly<{ integrationName: string; labelOrTag: string; projectOrSpace: string }>
  >
> = {
  TODOIST: { integrationName: "Todoist", labelOrTag: "label", projectOrSpace: "project" },
  CLICK_UP: { integrationName: "ClickUp", labelOrTag: "tag", projectOrSpace: "space" },
};
