import { useQuestsState } from "@hooks/atoms/useQuests";
import { ClickAwayListener, Fade, Popper as MuiPopper, Paper, PopperProps } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { VFC, createElement, useEffect, useMemo, useRef, useState } from "react";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { Override } from "../../types";
import { QuestGenericPopperContent } from "./QuestGenericPopperContent";
import { GenericQuestStepConfig, QuestStepProps, QuestStepType } from "./quests.types";
import { isQuestV2 } from "./quests.util";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      boxShadow: theme.shadows[9],
      borderRadius: "12px",
      zIndex: 1300,
      '&[data-popper-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.71em",
        "&::before": {
          transformOrigin: "0 100%",
        },
      },
      '&[data-popper-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.71em",
        "&::before": {
          transformOrigin: "100% 0",
        },
      },
      '&[data-popper-placement*="right"] $arrow': {
        left: 0,
        marginLeft: "-0.71em",
        height: "1em",
        width: "0.71em",
        "&::before": {
          transformOrigin: "100% 100%",
        },
      },
      '&[data-popper-placement*="left"] $arrow': {
        right: 0,
        marginRight: "-0.71em",
        height: "1em",
        width: "0.71em",
        "&::before": {
          transformOrigin: "0 0",
        },
      },
    },
    paperV2: {
      backgroundColor: theme.colors.white,
      boxShadow: "0px 4px 72px 0px rgba(104, 109, 167, 0.50)",
      borderRadius: theme.shape.borderRadius * 2.5,
      overflow: "hidden",
      maxWidth: 422,
    },
    paper: {
      backgroundColor: theme.colors.white,
      boxShadow: "0px 4px 72px 0px rgba(104, 109, 167, 0.50)",
      borderRadius: theme.shape.borderRadius * 2.5,
      overflow: "hidden",
      maxWidth: 285,
    },
    arrow: {
      overflow: "hidden",
      position: "absolute",
      width: "1em",
      height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: "border-box",
      color: theme.colors.white,
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: "100%",
        height: "100%",
        boxShadow: theme.shadows[1],
        backgroundColor: "currentColor",
        transform: "rotate(45deg)",
      },
    },
  }),
  {
    classNamePrefix: "QuestStepPopper",
  }
);

export type QuestStepPopperJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestStepPopperProps = Override<
  Omit<PopperProps, "children">,
  {
    classes?: Partial<ClassNameMap<QuestStepPopperJSSClassKey>>;
    className?: string;
    stepConfig: GenericQuestStepConfig;
    type: QuestStepType;
    onClose: () => void;
    onBlur: () => void;
    onBack: () => void;
    arrow?: boolean;
  }
>;

export const QuestStepPopper: VFC<QuestStepPopperProps> = ({
  className,
  classes: extClasses,
  stepConfig,
  placement = "left",
  type,
  onClose,
  onBlur,
  onBack,
  arrow = true,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const popperRef = useRef(null);

  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);
  const { activeQuest } = useQuestsState();

  const component = useMemo(() => stepConfig?.component || QuestGenericPopperContent, [stepConfig]);

  const handleClose = useCallbackSafeRef(() => {
    onClose?.();
  });

  const handleClickAway = useCallbackSafeRef(() => {
    if (stepConfig.type !== "action") onBlur?.();
  });

  /**
   * Effect to reposition the popper every 750 ms when content changes
   */
  useEffect(() => {
    const timer = setInterval(() => {
      if (popperRef.current) {
        // The current ref here is a popper js instance but it is not available via mui
        (popperRef.current as { update: () => void }).update();
      }
    }, 750);
    return () => clearInterval(timer);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <MuiPopper
        popperRef={popperRef}
        className={clsx(classes.root, className)}
        placement={placement}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
            },
          },
          {
            name: "offset",
            enabled: true,
            options: {
              offset: type === "orb" ? [12, 12] : [24, 24],
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: false,
              rootBoundary: "viewport",
              padding: 12,
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
              padding: 12,
            },
          },
        ]}
        transition
        {...rest}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div>
              {arrow && <span className={classes.arrow} ref={setArrowRef} />}
              <Paper className={clsx(isQuestV2(activeQuest) ? classes.paperV2 : classes.paper)} elevation={10}>
                {createElement<QuestStepProps>(component, {
                  onComplete: handleClose,
                  onClose: handleClose,
                  onBack: onBack,
                  onBlur: onBlur,
                  stepConfig,
                })}
              </Paper>
            </div>
          </Fade>
        )}
      </MuiPopper>
    </ClickAwayListener>
  );
};
