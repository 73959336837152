import { useMemo } from "react";
import { EDITION_META } from "../reclaim-api/team/Team.consts";
import { ReclaimEdition, SubscriptionOptions } from "../reclaim-api/team/Team.types";
import { reclaimEditionComparator } from "../reclaim-api/team/Team.utils";
import { LoadingErrorData } from "../reclaim-api/types";
import { filterFalsy } from "../utils/arrays";
import { sortMax } from "../utils/sort";
import { useUsageData } from "./useUsageData";

export const useRecommendedEdition = (
  seats: number | undefined,
  editions: readonly ReclaimEdition[],
  subscriptionOptions: SubscriptionOptions | undefined
) => {
  const { usageData, loading: usageDataLoading, error: usageDataError } = useUsageData();

  const hasSeats = typeof seats === "number";
  let loading = usageDataLoading || !hasSeats;
  const error = usageDataError;
  const dataReady = !!usageData && !!subscriptionOptions;

  if (!!error) loading = false;

  const recommendedEdition = useMemo(() => {
    if (!dataReady || loading || !!error || !hasSeats) return;

    const isValidEdition = (edition: ReclaimEdition | undefined): boolean =>
      !!edition &&
      !!dataReady &&
      !!subscriptionOptions.options[edition] &&
      !EDITION_META[edition].isTrial &&
      !EDITION_META[edition].isNonPaid &&
      !EDITION_META[edition].isLegacy;

    const filteredEditions = editions.filter(isValidEdition);

    const minMembershipEdition = filteredEditions.find((edition) => {
      const { minSeats, maxSeats } = subscriptionOptions.options[edition];
      return !EDITION_META[edition].isLegacy && seats >= minSeats && seats <= maxSeats;
    });

    const edition = sortMax(
      reclaimEditionComparator,
      ...filterFalsy([usageData.recommendedEdition, minMembershipEdition].filter(isValidEdition))
    );

    if (!!usageData && !edition) {
      // See if there is a legacy plan to recommend if nothing else is recommended
      const legacy = editions.find((e) => EDITION_META[e].isLegacy);
      return legacy || edition;
    } else {
      return edition;
    }
  }, [dataReady, editions, error, hasSeats, loading, seats, subscriptionOptions?.options, usageData]);

  let state: LoadingErrorData<ReclaimEdition, "recommendedEdition">["state"] = "hasData";

  if (error) state = "hasError";
  else if (loading) state = "loading";

  return {
    recommendedEdition,
    loading,
    error,
    state,
  } as LoadingErrorData<ReclaimEdition, "recommendedEdition">;
};
