import { MutableRefObject, useState } from "react";
import { useResizeObserver, UseResizeObserverCallback } from "./useResizeObserver";

export type UseElementRectCallback = UseResizeObserverCallback;

export type UseElementRectOptions<E extends HTMLElement | SVGElement> = {
  callback?: UseElementRectCallback;
  ref?: MutableRefObject<E | null>;
};

export type UseElementRectData<E extends HTMLElement | SVGElement> = Partial<DOMRectReadOnly> & {
  ref: MutableRefObject<E | null>;
};

export const useElementRect = <E extends HTMLElement | SVGElement>(
  options: UseElementRectOptions<E> = {}
): UseElementRectData<E> => {
  let { callback, ref } = options;

  const [rect, setRect] = useState<DOMRectReadOnly>();
  ref = useResizeObserver((rect) => {
    setRect(rect);
    callback?.(rect);
  }, ref);
  return { ref, ...rect };
};
