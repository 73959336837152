import { DialogContent, DialogContentProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { VFC } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 4, 4),
  },
}));

export type ModalContentProps = DialogContentProps;

export const ModalContent: VFC<ModalContentProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <DialogContent className={clsx(classes.root, rest.classes?.root)} {...rest}>
      {children}
    </DialogContent>
  );
};
