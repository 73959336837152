import { useUserContext } from "../context/UserContext";
import { ENTITLEMENT_META } from "../reclaim-api/team/Team.consts";
import { EntitlementType } from "../reclaim-api/team/Team.types";
import { Override } from "../types";
import { useTeamState } from "./atoms/useTeam";
import { useUsageData, UseUsageDataReturnType } from "./useUsageData";

export type UseEntitlementLogicReturnType = Override<
  UseUsageDataReturnType,
  {
    hasUnlimitedUsage?: boolean;
    usageLimit?: number;
    disableAdd: boolean;
    disableSubtract: boolean;
    disableUsage: boolean;
    entitlementIsInOverage: boolean;
    entitlementAddWouldBeOverage: boolean;
  }
>;

export const useEntitlementLogic = (entitlement: EntitlementType): UseEntitlementLogicReturnType => {
  const hookData = useUsageData();
  const { team } = useTeamState(true);
  const [{ user }] = useUserContext();

  /**
   * This is a temporary hack to disable the derivative scheduling links entitlement
   * if the user is on the lite plan.
   *
   * TODO: Remove this once we have a better way to handle this. We depend on the BE
   * to solve this and add the entitlement to the `actuals` object.
   *
   * See: RAI-8650
   */

  const booleanEntitlements: EntitlementType[] = [
    "DERIVATIVE_SCHEDULING_LINKS",
    "SCHEDULING_LINK_SURVEY",
    "SCHEDULING_LINK_REDIRECT",
  ];

  const manuallyDisabled = booleanEntitlements.includes(entitlement)
    ? !user?.editionEntitlements?.[entitlement]?.value
    : false;

  const { comparator } = ENTITLEMENT_META[entitlement];

  const { usageData, loading } = hookData;

  const { actualValue, allowedValueForCurrentEdition } = usageData?.actuals[entitlement] || {};

  const hasUnlimitedUsage =
    (typeof allowedValueForCurrentEdition === "number" && !isFinite(allowedValueForCurrentEdition)) ||
    (typeof allowedValueForCurrentEdition === "boolean" && allowedValueForCurrentEdition);

  const usageLimit = typeof allowedValueForCurrentEdition === "number" ? allowedValueForCurrentEdition : undefined;

  // we only want to lock the whole account if we're in new pricing
  const inUsageOverage = !!usageData?.overage && !!team?.newPricing;
  const missingActuals = usageData?.actuals[entitlement] === undefined;

  const disableSubtract = false;
  const disableUsage = loading || inUsageOverage || manuallyDisabled;

  // If the actuals do not exist then default to the overall overage for add
  const disableAdd = missingActuals ? disableUsage : loading || !usageData?.actuals[entitlement]?.moreUsageAllowed;

  const entitlementIsInOverage =
    disableUsage ||
    (actualValue !== undefined ? comparator(actualValue as never, allowedValueForCurrentEdition as never) > 0 : false);
  const entitlementAddWouldBeOverage =
    disableAdd ||
    (typeof actualValue === "boolean" &&
      comparator(!actualValue as never, allowedValueForCurrentEdition as never) > 0) ||
    (typeof actualValue === "number" &&
      comparator((actualValue + 1) as never, allowedValueForCurrentEdition as never) > 0);

  return {
    hasUnlimitedUsage,
    usageLimit,
    disableAdd,
    disableSubtract,
    disableUsage,
    entitlementIsInOverage,
    entitlementAddWouldBeOverage,
    ...hookData,
  };
};
