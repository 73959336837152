import { BrowserTimeZone, H12H24 } from "../types";
import { Currency } from "@depay/local-currency";
import { SUPPORTED_CURRENCY_MAP } from "../reclaim-api/team/Team.consts";
import { SupportedCurrency } from "../reclaim-api/team/Team.types";

const { timeZone, hour12, locale } = Intl.DateTimeFormat().resolvedOptions();

export const BROWSER_LOCALE = locale;
export const BROWSER_DEFAULT_CURRENCY_CODE = (() => {
  if (typeof window !== "object") return "USD";

  const code = Currency.getCode(timeZone);
  if (SUPPORTED_CURRENCY_MAP[code]) return code as SupportedCurrency;

  return "USD";
})();

export const BROWSER_TZ = timeZone as BrowserTimeZone;
export const SUPPORTED_TZS = Intl.supportedValuesOf?.("timeZone") as BrowserTimeZone[] | undefined;

// hour12 has low support.  Must do a strict equality check on false.
export const BROWSER_TIME_TYPE: H12H24 = hour12 === false ? "24h" : "12h";
